import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import axios from 'axios';
import { getHeaders } from '../../../request';
import HttpStatus from 'http-status-codes';
import { IT } from '../../../common/Constants';
import { 
    SttLoading, 
    SttAlerta, 
    SttGrid, 
    SttButton, 
    SttModal, 
    SttInput,
    SttHeading,
    SttFormControl,
    SttFormGroup,
    SttRadioGroup,
    SttFormControlLabel,
    SttRadioButton,
    SttCheckbox,
    SttAutocomplete,
    SttNumberInput,
    SttTable,
    SttTableHead,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttEditButton,
    SttText,
    SttCircularProgress,
    SttTranslate
} from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    modalBody: {
        overflow: 'hidden'
    }
}));
const Avaliacao = (props) => {
    const { strings, idExame, atender, setAtender } = props;
    const classes = useStyles();
    const DERMATO_API_BASE_URL = global.gConfig.url_base_dermato;

    const [alerta, setAlerta] = useState(false);
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [tipoAlerta, setTipoAlerta] = useState('success');
    const [tituloAlerta, setTituloAlerta] = useState(strings.sucesso);
    const [opcoesAlerta, setOpcoesAlerta] = useState([]);
    const [handleCloseAlerta, setHandleCloseAlerta] = useState(e => console.log(e));
    const [enviando, setEnviando] = useState(false);
    const [formValido, setFormValido] = useState(false);
    const [buscandoMedicamento, setBuscandoMedicamento] = useState(false);
    const [alterarMedicamento, setAlterarMedicamento] = useState(false);
    const [posologia, setPosologia] = useState('');
    const [observacao, setObservacao] = useState('');
    const [apresentacao, setApresentacao] = useState(null);
    const [indiceAlteracao, setIndiceAlteracao] = useState(null);
    const [progresso, setProgresso] = useState(false);

    // Listas
    const [listaEducacaoSaude, setListaEducacaoSaude] = useState([]);
    const [listaSeguimento, setListaSeguimento] = useState([]);
    const [listaModeloReceita, setListaModeloReceita] = useState([]);
    const [listaMedicamentosUsoTopico, setListaMedicamentosUsoTopico] = useState([]);
    const [listaMedicamentosUsoSistemico, setListaMedicamentosUsoSistemico] = useState([]);
    const [listaApresentacoes, setListaApresentacoes] = useState([]);

    // Estado do formulário - respostas
    const [respostaP1, setRespostaP1] = useState('0');
    const [respostaP1Complemento, setRespostaP1Complemento] = useState('');
    const [respostaP2, setRespostaP2] = useState('1');
    const [respostaP2Complemento, setRespostaP2Complemento] = useState('');
    const [respostaP3, setRespostaP3] = useState([]);
    const [respostaP3Complemento, setRespostaP3Complemento] = useState({});
    const [respostaP4, setRespostaP4] = useState(null);
    const [respostaP4Dias, setRespostaP4Dias] = useState('');
    const [respostaP4Meses, setRespostaP4Meses] = useState('');
    const [respostaP4Anos, setRespostaP4Anos] = useState('');
    const [respostaP5, setRespostaP5] = useState('1');
    const [respostaP5Modelo, setRespostaP5Modelo] = useState(null);
    const [respostaP5med, setRespostaP5Med] = useState([]);
    const [observacoesGerais, setObservacoesGerais] = useState('');
    const [respostaDocs, setRespostaDocs] = useState([]);
    const [medicamentosAdicionados, setMedicamentosAdicionados] = useState([]);

    const fecharAtendimento = () => {
        setAlerta(false);
        setAtender(false);
    }

    const enviarAtendimento = () => {
        let dados = {
            exame: idExame,
            resultadoExame: parseInt(respostaP1),
            diagnosticoCid: parseInt(respostaP2)
        };
        if (respostaP1Complemento.trim()) {
            dados.resultadoExameComplemento = respostaP1Complemento.trim();
        }
        if (respostaP2Complemento.trim()) {
            dados.justificativaNaoCid = respostaP2Complemento.trim();
        }
        if (respostaP3.length) {
            dados.educacaoSaude = respostaP3;
        }
        if (respostaP3Complemento) {
            dados.educacaoSaudeComplemento = respostaP3Complemento;
        }
        if (respostaP4) {
            dados.seguimentoPaciente = respostaP4;
            if (respostaP4Dias) {
                dados.retornoDias = respostaP4Dias;
            }
            if (respostaP4Meses) {
                dados.retornoMeses = respostaP4Meses;
            }
            if (respostaP4Anos) {
                dados.retornoAnos = respostaP4Anos;
            }
        }
        if (medicamentosAdicionados.length) {
            dados.medicamentos = medicamentosAdicionados;
        }
        if (observacoesGerais) {
            dados.observacoes = observacoesGerais;
        }
        
        setEnviando(true);
        let tab = window.open();
        tab.document.write('<p>Aguarde...</p>');
        axios
            .post(`${DERMATO_API_BASE_URL}/atender-paciente-it`, dados, { headers: getHeaders() })
            .then(resposta => {
                const { exame, dataHora } = resposta.data;

                // Imprime os documentos
                respostaDocs.forEach(tipo => {
                    axios
                        .get(`${DERMATO_API_BASE_URL}/imprimir-documento-it/${tipo}/1`, { params: { exame, dataHora }, headers: getHeaders(), responseType: 'blob' })
                        .then(res => {
                            // Abre o PDF
                            const fileURL = URL.createObjectURL(res.data);
                            tab.location = fileURL;
                        })
                        .catch(err => console.log(err));
                });

                if (!respostaDocs.includes(IT.DOCUMENTOS.RECEITA)) {
                    axios
                        .get(`${DERMATO_API_BASE_URL}/imprimir-documento-it/${IT.DOCUMENTOS.RECEITA}`, { params: { exame, dataHora }, headers: getHeaders() })
                        .catch(err => console.log(err));
                }

                setHandleCloseAlerta(() => fecharAtendimento);
                setTituloAlerta(strings.sucesso);
                setTipoAlerta('success');
                setMensagemAlerta(strings.AtendimentoPaciente.atendimentoRegistrado);
                setOpcoesAlerta([{
                    title: strings.ok,
                    onClick: fecharAtendimento
                }]);
                setAlerta(true);
            })
            .catch(err => {
                console.log(err);
                const { response } = err;
                let msg = strings.erroDesconhecido;
                if (response) {
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        const { data } = response;
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        msg = arrMensagem.join('\n');
                        setTituloAlerta(data.message);
                    } else {
                        setTituloAlerta(strings.erro);
                    }
                } else {
                    setTituloAlerta(strings.erro);
                }
                setHandleCloseAlerta(() => setAlerta(false));
                setMensagemAlerta(msg);
                setTipoAlerta('error');
                setOpcoesAlerta([{
                    title: strings.ok,
                    onClick: () => setAlerta(false)
                }]);
                setAlerta(true);
            })
            .finally(() => setEnviando(false));
    }

    useEffect(() => {
        // Carrega lista de educação em saúde
        axios
            .get(`${DERMATO_API_BASE_URL}/educacao-saude-it`, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    setListaEducacaoSaude(response.data);
                }
            })
            .catch(err => console.log(err));
        
        // Carrega lista de seguimento
        axios
            .get(`${DERMATO_API_BASE_URL}/seguimento-it`, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    setListaSeguimento(response.data);
                }
            })
            .catch(err => console.log(err));

        // Carrega lista de modelos de receita
        axios
            .get(`${DERMATO_API_BASE_URL}/modelo-receita-it`, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    setListaModeloReceita(response.data);
                }
            })
            .catch(err => console.log(err));

        // Carrega lista de medicamentos
        axios
            .get(`${DERMATO_API_BASE_URL}/medicamento-it`, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    const topico = response.data.filter(medicamento => medicamento.tipo_uso === IT.MEDICAMENTO_USO_TOPICO);
                    setListaMedicamentosUsoTopico(topico);

                    const sistemico = response.data.filter(medicamento => medicamento.tipo_uso === IT.MEDICAMENTO_USO_SISTEMICO);
                    setListaMedicamentosUsoSistemico(sistemico);                   
                }
            })
            .catch(err => console.log(err));
    }, []);

    useEffect(() => {
        if (respostaP1 === '0') {
            setRespostaP1Complemento('');
        }
    }, [respostaP1]);

    useEffect(() => {
        if (respostaP2 === '1') {
            setRespostaP2Complemento('');
        } else {
            setRespostaP3([]);
            setRespostaP3Complemento({});
            setRespostaP4(null);
            setRespostaP4Dias('');
            setRespostaP4Meses('');
            setRespostaP4Anos('');
            setRespostaP5('1');
            setRespostaP5Modelo(null);
            setRespostaP5Med([]);
            setObservacoesGerais('');
            setRespostaDocs([]);
            setMedicamentosAdicionados([]);
        }
    }, [respostaP2]);

    useEffect(() => {
        if (respostaP5 === '0') {
            setRespostaP5Modelo(null);
        }
        setMedicamentosAdicionados([]);
    }, [respostaP5]);

    const handleChangePergunta1 = (event) => {
        setRespostaP1(event.target.value);
    }

    const handleChangePergunta1Complemento = (event) => {
        setRespostaP1Complemento(event.target.value);
    }

    const handleChangePergunta2 = (event) => {
        setRespostaP2(event.target.value);
    }

    const handleChangePergunta2Complemento = (event) => {
        setRespostaP2Complemento(event.target.value);
    }
    
    const handleChangePergunta3 = (event, checked) => {
        const id = parseInt(event.target.value);
        if (checked) {
            setRespostaP3([...respostaP3, id]);
        } else {
            setRespostaP3(respostaP3.filter(i => i !== id));
            handleChangePergunta3Complemento(null, id);
        }
    }

    const handleChangePergunta3Complemento = (event, relatedTo) => {
        if (!event || !event.target.value.trim()) {
            let complemento3 = { ...respostaP3Complemento };
            delete complemento3[relatedTo];
            setRespostaP3Complemento(complemento3);
        } else {
            setRespostaP3Complemento({ ...respostaP3Complemento, [relatedTo]: event.target.value });
        }
    }

    const handleChangePergunta4 = (event, item) => {
        setRespostaP4(item);
    }

    const handleChangePergunta4Dias = (event) => {
        setRespostaP4Dias(event.target.value);
    }

    const handleChangePergunta4Meses = (event) => {
        setRespostaP4Meses(event.target.value);
    }

    const handleChangePergunta4Anos = (event) => {
        setRespostaP4Anos(event.target.value);
    }

    const handleChangePergunta5 = (event) => {
        setRespostaP5(event.target.value);
    }

    const handleChangePergunta5Modelo = (event, item) => {
        setRespostaP5Modelo(item);
    }

    useEffect(() => {
        if (respostaP5Modelo) {
            setBuscandoMedicamento(true);
            axios
                .get(`${DERMATO_API_BASE_URL}/medicamento-it/${respostaP5Modelo.id}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        setMedicamentosAdicionados(response.data);
                    }
                })
                .catch(err => console.log(err))
                .finally(() => {
                    setBuscandoMedicamento(false);
                });
        }
    }, [respostaP5Modelo]);

    useEffect(() => {
        if (indiceAlteracao !== null) {
            handleChangeMedicamento(indiceAlteracao);
        }
    }, [indiceAlteracao]);

    const handleChangeMedicamento = (indice) => {
        const medicamento = medicamentosAdicionados[indice];
        listarApresentacoes(medicamento.id_medicamento);
        if (medicamento.apresentacao !== null && typeof apresentacao === 'object') {
            setApresentacao(medicamento.apresentacao);
        }
        setPosologia(medicamento.posologia || '');
        setObservacao(medicamento.observacao || '');
        setAlterarMedicamento(true);
    }

    const formatObs = (obs) => {
        return obs.replace(/\n/g, '<br>');
    }

    const formatPosologia = (posolog) => {
        return (posolog || '').replace(/\n/g, '<br>');
    }

    const formatApresentacao = (apresent) => {
        if (typeof apresent === 'object') {
            return apresent.apresentacao;
        }
        return apresent;
    }

    const fecharAlteracaoMedicamento = () => {
        setIndiceAlteracao(null);
        setAlterarMedicamento(false);
        setListaApresentacoes([]);
    }

    const handleChangeObservacao = (event) => {
        setObservacao(event.target.value);
    }

    const handleChangePosologia = (event) => {
        setPosologia(event.target.value);
    }

    const handleChangeApresentacao = (event, item) => {
        setApresentacao(item);
    }

    const alterarDadosMedicamento = () => {
        let adicionados = [ ...medicamentosAdicionados ];
        adicionados[indiceAlteracao] = {
            ...medicamentosAdicionados[indiceAlteracao],
            apresentacao,
            posologia,
            observacao
        };
        setMedicamentosAdicionados(adicionados);
        fecharAlteracaoMedicamento();
    }

    const handleChangeRespostaP5Med = (event, checked) => {
        const id = parseInt(event.target.value);
        if (checked) {
            setRespostaP5Med([...respostaP5med, id]);
            
            // Os medicamentos estão divididos em dois grupos.
            // Não é possível saber a qual grupo o medicamento selecionado pertence,
            // logo buscamos nos dois grupos. O resultado será sempre um array vazio 
            // e outro com 1 entrada.
            const topico = listaMedicamentosUsoTopico.filter(med => med.id_medicamento === id);
            console.log(id);
            console.log(listaMedicamentosUsoTopico);
            console.log(topico);
            const sistemico = listaMedicamentosUsoSistemico.filter(med => med.id_medicamento === id);

            setMedicamentosAdicionados([
                ...medicamentosAdicionados,
                ...topico,
                ...sistemico
            ]);

            if (sistemico.length) {
                // Busca lista de apresentações para o medicamento
                listarApresentacoes(
                    id,
                    () => setIndiceAlteracao(medicamentosAdicionados.length)
                );
            } else {
                setIndiceAlteracao(medicamentosAdicionados.length);
            }

        } else {
            setRespostaP5Med(respostaP5med.filter(i => i !== id));
            const medicamentos = medicamentosAdicionados.filter(med => med.id_medicamento !== id);
            setMedicamentosAdicionados(medicamentos);
        }
    }

    const listarApresentacoes = (medicamento, callback) => {
        setProgresso(true);
        axios
            .get(`${DERMATO_API_BASE_URL}/apresentacao-it/${medicamento}`, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    setListaApresentacoes(response.data);
                    if (callback) {
                        callback();
                    }
                }
            })
            .catch(err => console.log(err))
            .finally(() => {
                setProgresso(false);
            });
    }

    const handleChangeObservacoesGerais = (event) => {
        setObservacoesGerais(event.target.value);
    }

    const handleChangeRespostaDocs = (event, checked) => {
        const id = event.target.value;
        if (checked) {
            setRespostaDocs([...respostaDocs, id]);
        } else {
            setRespostaDocs(respostaDocs.filter(i => i !== id));
        }
    }

    useEffect(() => {
        let valido = true;
        if (!respostaP1 || !respostaP2) {
            valido = false;
        }
        if (respostaP1 === '1' && !respostaP1Complemento.trim()) {
            valido = false;
        }
        if (respostaP2 === '0' && !respostaP2Complemento.trim()) {
            valido = false;
        }
        if (respostaP2 === '1' && (!respostaP3.length || !respostaP4)) {
            valido = false;
        }
        setFormValido(valido);

    }, [respostaP1, respostaP1Complemento, respostaP2, respostaP2Complemento, respostaP3, respostaP4]);

    return (
        <>
            <SttModal
                title={strings.AtendimentoPaciente.titulo}
                open={atender}
                outModalClose={fecharAtendimento}
                iconClose={fecharAtendimento}
                maxWidth="md"
                fullWidth={true}
                children={
                    <div className={classes.modalBody}>
                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12}>
                                <SttHeading required variant="h4" color="primary">{strings.AtendimentoPaciente.p1}</SttHeading>
                                <SttFormControl variant="outlined">
                                    <SttRadioGroup>
                                        <SttFormControlLabel 
                                            control={
                                                <SttRadioButton  
                                                    type="radio"
                                                    value="0"
                                                    color="primary"
                                                    checked={respostaP1 === '0'}    
                                                />
                                            }
                                            onChange={handleChangePergunta1}
                                            label={strings.nao}
                                        />
                                        <SttFormControlLabel 
                                            control={
                                                <SttRadioButton  
                                                    type="radio"
                                                    value="1"
                                                    color="primary"
                                                    checked={respostaP1 === '1'}    
                                                />
                                            }
                                            onChange={handleChangePergunta1}
                                            label={strings.sim}
                                        />
                                    </SttRadioGroup>
                                </SttFormControl>
                            </SttGrid>
                        </SttGrid>
                        {
                            respostaP1 === '1' && 
                            <SttGrid container spacing={3}>
                                <SttGrid item xs={12}>
                                    <SttInput 
                                        label={strings.AtendimentoPaciente.resultadoExames}
                                        multiline 
                                        required
                                        rows={3} 
                                        value={respostaP1Complemento} 
                                        onChange={handleChangePergunta1Complemento} 
                                    />
                                </SttGrid>
                            </SttGrid>
                        }
                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12}>
                                <SttHeading required variant="h4" color="primary">{strings.AtendimentoPaciente.p2}</SttHeading>
                                <SttFormControl variant="outlined">
                                    <SttRadioGroup>
                                        <SttFormControlLabel 
                                            control={
                                                <SttRadioButton  
                                                    type="radio"
                                                    value="0"
                                                    color="primary"
                                                    checked={respostaP2 === '0'}    
                                                />
                                            }
                                            onChange={handleChangePergunta2}
                                            label={strings.nao}
                                        />
                                        <SttFormControlLabel 
                                            control={
                                                <SttRadioButton  
                                                    type="radio"
                                                    value="1"
                                                    color="primary"
                                                    checked={respostaP2 === '1'}    
                                                />
                                            }
                                            onChange={handleChangePergunta2}
                                            label={strings.sim}
                                        />
                                    </SttRadioGroup>
                                </SttFormControl>
                            </SttGrid>
                        </SttGrid>
                        {
                            respostaP2 === '0' && 
                            <SttGrid container spacing={3}>
                                <SttGrid item xs={12}>
                                    <SttInput 
                                        label={strings.AtendimentoPaciente.justificativa}
                                        multiline 
                                        required 
                                        rows={3} 
                                        value={respostaP2Complemento} 
                                        onChange={handleChangePergunta2Complemento} 
                                    />
                                </SttGrid>
                            </SttGrid>
                        }
                        {
                            respostaP2 === '1' && 
                            <div>
                                <SttGrid container spacing={3}>
                                    <SttGrid item xs={12}>
                                        <SttHeading required variant="h4" color="primary">{strings.AtendimentoPaciente.p3}</SttHeading>
                                        <SttFormControl variant="outlined">
                                            <SttFormGroup>
                                                {
                                                    listaEducacaoSaude.map(item => (
                                                        <div>
                                                            <SttFormControlLabel
                                                                control={
                                                                        <SttCheckbox
                                                                            value={item.id}
                                                                            color="primary"
                                                                            checked={respostaP3.includes(item.id)}
                                                                            onChange={handleChangePergunta3}
                                                                        />
                                                                }
                                                                key={item.id}
                                                                label={item.descricao}
                                                            />
                                                            {
                                                                item.exige_complemento && respostaP3.includes(item.id) &&
                                                                <SttInput value={respostaP3Complemento[item.id]} onChange={(e) => handleChangePergunta3Complemento(e, item.id)} />
                                                            }
                                                        </div>
                                                    ))
                                                }
                                            </SttFormGroup>
                                        </SttFormControl>
                                    </SttGrid>
                                </SttGrid>
        
                                <SttGrid container spacing={3}>
                                    <SttGrid item xs={12}>
                                        <SttHeading required variant="h4" color="primary">{strings.AtendimentoPaciente.p4}</SttHeading>
                                        <SttAutocomplete 
                                            inputprops={{
                                                label: ''
                                            }}
                                            getOptionLabel={option => option && option.descricao || ''}
                                            getOptionSelected={(option, value) => option && value && option.id === value.id}
                                            options={listaSeguimento}
                                            value={respostaP4}
                                            onChange={handleChangePergunta4}
                                        />
                                        {
                                            respostaP4 && respostaP4.exige_complemento &&
                                            <SttGrid container spacing={3}>
                                                <SttGrid item xs={4}>
                                                    <SttNumberInput 
                                                        label={strings.AtendimentoPaciente.diasRetorno}
                                                        inputProps={{
                                                            maxLength: 2
                                                        }} 
                                                        value={respostaP4Dias} 
                                                        onChange={handleChangePergunta4Dias} 
                                                    />
                                                </SttGrid>
                                                <SttGrid item xs={4}>
                                                    <SttNumberInput 
                                                        label={strings.AtendimentoPaciente.mesesRetorno}
                                                        inputProps={{
                                                            maxLength: 2
                                                        }} 
                                                        value={respostaP4Meses} 
                                                        onChange={handleChangePergunta4Meses} 
                                                    />
                                                </SttGrid>
                                                <SttGrid item xs={4}>
                                                    <SttNumberInput 
                                                        label={strings.AtendimentoPaciente.anosRetorno}
                                                        inputProps={{
                                                            maxLength: 2
                                                        }} 
                                                        value={respostaP4Anos} 
                                                        onChange={handleChangePergunta4Anos} 
                                                    />
                                                </SttGrid>
                                            </SttGrid>
                                        }
                                    </SttGrid>
                                </SttGrid>
        
                                <SttGrid container spacing={3}>
                                    <SttGrid item xs={12}>
                                        <SttHeading variant="h4" color="primary">{strings.AtendimentoPaciente.p5}</SttHeading>
                                        <SttFormControl variant="outlined">
                                            <SttRadioGroup>
                                                <SttFormControlLabel 
                                                    control={
                                                        <SttRadioButton  
                                                            type="radio"
                                                            value="0"
                                                            color="primary"
                                                            checked={respostaP5 === '0'}    
                                                        />
                                                    }
                                                    onChange={handleChangePergunta5}
                                                    label={strings.nao}
                                                />
                                                <SttFormControlLabel 
                                                    control={
                                                        <SttRadioButton  
                                                            type="radio"
                                                            value="1"
                                                            color="primary"
                                                            checked={respostaP5 === '1'}    
                                                        />
                                                    }
                                                    onChange={handleChangePergunta5}
                                                    label={strings.sim}
                                                />
                                            </SttRadioGroup>
                                        </SttFormControl>
                                        {
                                            respostaP5 === '1' && 
                                            <SttAutocomplete 
                                                inputprops={{
                                                    label: strings.AtendimentoPaciente.modeloReceita
                                                }}
                                                getOptionLabel={option => option && option.descricao || ''}
                                                getOptionSelected={(option, value) => option && value && option.id === value.id}
                                                options={listaModeloReceita}
                                                value={respostaP5Modelo}
                                                onChange={handleChangePergunta5Modelo}
                                            />
                                        }
        
                                        {
                                            respostaP5 === '0' && 
                                            <div>
                                                <SttHeading variant="h5" color="secondary">{strings.AtendimentoPaciente.medTopico}</SttHeading>
                                                <SttFormControl variant="outlined">
                                                    <SttFormGroup>
                                                        {
                                                            listaMedicamentosUsoTopico.map(item => (
                                                                <div>
                                                                    <SttFormControlLabel
                                                                        control={
                                                                                <SttCheckbox
                                                                                    value={item.id_medicamento}
                                                                                    color="primary"
                                                                                    checked={respostaP5med.includes(item.id_medicamento)}
                                                                                    onChange={handleChangeRespostaP5Med}
                                                                                />
                                                                        }
                                                                        key={item.id_medicamento}
                                                                        label={item.nome_medicamento}
                                                                    />
                                                                </div>
                                                            ))
                                                        }
                                                    </SttFormGroup>
                                                </SttFormControl>
        
                                                <SttHeading variant="h5" color="secondary">{strings.AtendimentoPaciente.medSistemico}</SttHeading>
                                                <SttFormControl variant="outlined">
                                                    <SttFormGroup>
                                                        {
                                                            listaMedicamentosUsoSistemico.map(item => (
                                                                <div>
                                                                    <SttFormControlLabel
                                                                        control={
                                                                                <SttCheckbox
                                                                                    value={item.id_medicamento}
                                                                                    color="primary"
                                                                                    checked={respostaP5med.includes(item.id_medicamento)}
                                                                                    onChange={handleChangeRespostaP5Med}
                                                                                />
                                                                        }
                                                                        key={item.id_medicamento}
                                                                        label={item.nome_medicamento}
                                                                    />
                                                                </div>
                                                            ))
                                                        }
                                                    </SttFormGroup>
                                                </SttFormControl>
                                            </div>   
                                        }
        
                                    </SttGrid>
                                </SttGrid>
                                {
                                    medicamentosAdicionados.length > 0 && 
                                    <SttGrid container spacing={3}>
                                        <SttGrid item xs={12}>
                                            <SttTable>
                                                <SttTableHead>
                                                    <SttTableRow>
                                                        <SttTableCell width="100%" colSpan="2">{strings.AtendimentoPaciente.medicamentosAdicionados}</SttTableCell>
                                                    </SttTableRow>
                                                </SttTableHead>
                                                <SttTableBody>
                                                    { 
                                                        buscandoMedicamento ? 
                                                            <SttTableRow key={-1}>
                                                                <SttTableCell colSpan={2} 
                                                                    align="center">
                                                                    <SttCircularProgress />
                                                                </SttTableCell>
                                                            </SttTableRow>
                                                        : 
                                                        (
                                                            medicamentosAdicionados.map((row, index) => (
                                                                <SttTableRow key={index}>
                                                                    <SttTableCell width="96%">
                                                                        <SttText size="small">
                                                                            <b>{strings.AtendimentoPaciente.nome}:</b> {row.nome_medicamento}
                                                                        </SttText>
                                                                        {
                                                                            row.apresentacao &&
                                                                            <SttText size="small">
                                                                                <b>{strings.AtendimentoPaciente.apresentacao}:</b> {formatApresentacao(row.apresentacao)}
                                                                            </SttText>
                                                                        }
                                                                        {
                                                                            row.posologia &&
                                                                            <SttText size="small">{}
                                                                                <b>{strings.AtendimentoPaciente.posologia}:</b>&nbsp;
                                                                                <span dangerouslySetInnerHTML={{ __html: formatPosologia(row.posologia) }}></span>
                                                                            </SttText>
                                                                        }
                                                                        {
                                                                            row.observacao &&
                                                                            <SttText size="small">
                                                                                <b>{strings.AtendimentoPaciente.obs}:</b> &nbsp;
                                                                                <span dangerouslySetInnerHTML={{ __html: formatObs(row.observacao) }}></span>
                                                                            </SttText>
                                                                        }
                                                                    </SttTableCell>
                                                                    <SttTableCell align="center">
                                                                        <SttEditButton
                                                                            id={`btn-excluir-cid10-${index}`} 
                                                                            onClick={() => setIndiceAlteracao(index)}
                                                                        />
                                                                    </SttTableCell>
                                                                </SttTableRow>
                                                            ))
                                                        )
                                                    }
                                                </SttTableBody>
                                            </SttTable>
                                        </SttGrid>
                                    </SttGrid>
                                }

                                <SttGrid container spacing={3}>
                                    <SttGrid item xs={12}>
                                        <SttInput 
                                            multiline 
                                            label={strings.AtendimentoPaciente.observacoesGerais} 
                                            rows={3} 
                                            value={observacoesGerais} 
                                            onChange={handleChangeObservacoesGerais} 
                                        />
                                    </SttGrid>
                                </SttGrid>

                                <SttGrid container spacing={3}>
                                    <SttGrid item xs={12}>
                                        <SttHeading variant="h5" color="primary">{strings.AtendimentoPaciente.documentosImpressos}</SttHeading>
                                        <SttFormControl variant="outlined">
                                            <SttFormGroup>
                                                <SttFormControlLabel
                                                    control={
                                                        <SttCheckbox
                                                            value={IT.DOCUMENTOS.RECEITA}
                                                            color="primary"
                                                            checked={respostaDocs.includes(IT.DOCUMENTOS.RECEITA)}
                                                            onChange={handleChangeRespostaDocs}
                                                        />
                                                    }
                                                    label={strings.AtendimentoPaciente.documentosImpressosR}
                                                />

                                                <SttFormControlLabel
                                                    control={
                                                        <SttCheckbox
                                                            value={IT.DOCUMENTOS.TERMO}
                                                            color="primary"
                                                            checked={respostaDocs.includes(IT.DOCUMENTOS.TERMO)}
                                                            onChange={handleChangeRespostaDocs}
                                                        />
                                                    }
                                                    label={strings.AtendimentoPaciente.documentosImpressosT}
                                                />
                                                
                                                <SttFormControlLabel
                                                    control={
                                                        <SttCheckbox
                                                            value={IT.DOCUMENTOS.LAUDO}
                                                            color="primary"
                                                            checked={respostaDocs.includes(IT.DOCUMENTOS.LAUDO)}
                                                            onChange={handleChangeRespostaDocs}
                                                        />
                                                    }
                                                    label={strings.AtendimentoPaciente.documentosImpressosL}
                                                />

                                                <SttFormControlLabel
                                                    control={
                                                        <SttCheckbox
                                                            value={IT.DOCUMENTOS.FORMULARIO}
                                                            color="primary"
                                                            checked={respostaDocs.includes(IT.DOCUMENTOS.FORMULARIO)}
                                                            onChange={handleChangeRespostaDocs}
                                                        />
                                                    }
                                                    label={strings.AtendimentoPaciente.documentosImpressosF}
                                                />
                                            </SttFormGroup>
                                        </SttFormControl>
                                    </SttGrid>
                                </SttGrid>
                            </div>
                        }

                    </div>
                }
                footer={
                    <div>
                        <SttButton variant="contained" color="primary"onClick={enviarAtendimento} disabled={!formValido}>
                            {strings.salvar}
                        </SttButton>
                        <SttButton variant="outlined" color="primary" onClick={fecharAtendimento}>
                            {strings.cancelar}
                        </SttButton>
                    </div>
                }
            />
            <SttAlerta
                open={alerta}
                title={tituloAlerta}
                message={mensagemAlerta}
                type={tipoAlerta}
                options={opcoesAlerta}
                onClose={handleCloseAlerta}
            />

            <div className={classes.carregando}>
                <SttLoading open={enviando} />
            </div>

            {
                progresso && 
                <div className={classes.carregando}>
                    <SttCircularProgress color="primary" />
                </div>
            }

            <SttModal
                title={strings.AtendimentoPaciente.medicamento}
                open={alterarMedicamento}
                outModalClose={fecharAlteracaoMedicamento}
                iconClose={fecharAlteracaoMedicamento}
                maxWidth="sm"
                fullWidth={true}
                children={
                    <div className={classes.modalBody}>

                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12}>
                                {
                                    listaApresentacoes.length > 0 && 
                                    <SttAutocomplete 
                                        inputprops={{
                                            label: strings.AtendimentoPaciente.apresentacao
                                        }}
                                        getOptionLabel={option => option && option.apresentacao || ''}
                                        getOptionSelected={(option, value) => option && value && option.id === value.id}
                                        options={listaApresentacoes}
                                        value={apresentacao}
                                        onChange={handleChangeApresentacao}
                                    />
                                }
                            </SttGrid>
                        </SttGrid>
                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12}>
                                <SttInput label={strings.AtendimentoPaciente.posologia} multiline rows={5} value={posologia} onChange={handleChangePosologia} />
                            </SttGrid>
                        </SttGrid>
                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12}>
                                <SttInput label={strings.AtendimentoPaciente.obs} multiline rows={5} value={observacao} onChange={handleChangeObservacao} />
                            </SttGrid>
                        </SttGrid>
                    </div>
                }
                footer={
                    <div>
                        <SttButton variant="contained" color="primary"onClick={alterarDadosMedicamento}>
                            {strings.adicionar}
                        </SttButton>
                        <SttButton variant="outlined" color="primary" onClick={fecharAlteracaoMedicamento}>
                            {strings.cancelar}
                        </SttButton>
                    </div>
                }
            />
        </>
    );

}

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps, null)(SttTranslate('ItinerarioTerapeutico')(Avaliacao));