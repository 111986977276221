import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { getHeaders } from '../../request';
import { connect } from 'react-redux';
import { setAtualizarBusca as setAtualizarBuscaAction } from '../../reducers/actions/exame';
import axios from 'axios';
import { SttLoading, SttAlerta, SttGrid, SttButton, SttModal, SttAutocomplete, SttTranslate } from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    modalBody: {
        overflow: 'hidden'
    }
}));
const TrocarRede = (props) => {
    const { strings, redes, trocarRede, setTrocarRede, exame, redeExameSelecionado, callback } = props;
    const classes = useStyles();
    
    const [rede, setRede] = useState(null);
    const [confirmarTrocarRede, setConfirmarTrocarRede] = useState(false);
    const [trocandoRede, setTrocandoRede] = useState(false);
    const [alerta, setAlerta] = useState(false);
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [tipoAlerta, setTipoAlerta] = useState('success');
    const [tituloAlerta, setTituloAlerta] = useState(strings.sucesso);
    const [opcoesAlerta, setOpcoesAlerta] = useState([]);
    const EXAME_API_BASE_URL = global.gConfig.url_base_exames;

    const fecharTrocarRede = () => {
        setRede(null);
        setTrocarRede(false);
    }

    const selecionarRede = (event, novaRede) => {
        setRede(novaRede);
    }

    const priorizarExame = () => {
        setConfirmarTrocarRede(false);
        setTrocandoRede(true);

        const dados = { exame, rede: rede.id };

        axios
            .put(`${EXAME_API_BASE_URL}/trocar-rede`, dados, { headers: getHeaders() })
            .then((resposta) => {
                setTrocandoRede(false);
                setTipoAlerta('success');
                setMensagemAlerta(strings.redeAlterada);
                setAlerta(true);
                setOpcoesAlerta([
                    { 
                        title: strings.ok, 
                        onClick: () => {
                            setAlerta(false);
                            fecharTrocarRede();
                            callback(true);
                        } 
                    }
                ]);
            })
            .catch(err => {
                setTrocandoRede(false);
                console.log(err);
                setTipoAlerta('error');
                setTituloAlerta(strings.erro)
                setMensagemAlerta(strings.erroTrocarRedeExame);
                setOpcoesAlerta([{ title: strings.ok, onClick: () => setAlerta(false) }]);
                setAlerta(true);
            });
    }

    return (
        <>
            <SttModal
                title={strings.trocarRede}
                open={trocarRede}
                outModalClose={fecharTrocarRede}
                iconClose={fecharTrocarRede}
                maxWidth="sm"
                fullWidth={true}
                children={
                    <div className={classes.modalBody}>
                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12}>
                                <SttAutocomplete
                                    inputprops={{
                                        label: strings.novaRede,
                                        required: true
                                    }}
                                    getOptionLabel={option => option.descricao}
                                    options={redes.filter(r => r.id !== redeExameSelecionado)}
                                    value={rede}
                                    onChange={selecionarRede}
                                />
                            </SttGrid>
                        </SttGrid>
                    </div>
                }
                footer={
                    <div>
                        <SttButton variant="contained" color="primary" disabled={!rede} onClick={() => setConfirmarTrocarRede(true)}>
                            {strings.confirmar}
                        </SttButton>
                        <SttButton variant="outlined" color="primary" onClick={fecharTrocarRede}>
                            {strings.cancelar}
                        </SttButton>
                    </div>
                }
            />
            <SttAlerta
                open={confirmarTrocarRede}
                title={strings.atencao}
                message={strings.confirmarTrocarRedeExame}
                type={'alert'}
                onClose={() => {
                    setConfirmarTrocarRede(false);
                }}
                options={
                    [
                        {
                            title: strings.sim,
                            onClick: priorizarExame
                        },
            
                        {
                            title: strings.nao,
                            onClick: () => {
                                setConfirmarTrocarRede(false);
                            }
                        }
                    ]
                }
            />
            <SttAlerta
                open={alerta}
                title={tituloAlerta}
                message={mensagemAlerta}
                type={tipoAlerta}
                options={opcoesAlerta}
                onClose={() => setAlerta(false)}
            />

            <div className={classes.carregando}>
                <SttLoading
                    open={trocandoRede}
                    text={strings.trocandoRede}
                />
            </div>
        </>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        setAtualizarBusca: atualizar => dispatch(setAtualizarBuscaAction(atualizar))
    }
}

export default connect(null, mapDispatchToProps)(SttTranslate('Exame')(TrocarRede));
