import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Interno from './interno';
import Externo from './externo';
import Logout from './logout';
import Erro from './erro';

const Home = (props) => {

    return (
        <BrowserRouter basename={`/${global.gConfig.basename}`}>
            <Switch>
                <Route path="/externo" exact component={Externo} />
                <Route path="/logout" exact component={Logout} />
                <Route path="/erro" exact component={Erro} />
                <Route path="/" component={Interno} />
            </Switch>
        </BrowserRouter>
    );
};

export default Home;
