import { 
    DESCRICAO_ESTUDO,
    LESAO_ELEMENTAR,
    LESAO_SECUNDARIA,
    COLORACAO,
    MORFOLOGIA,
    DISTRIBUICAO,
    TAMANHO,
    LOCALIZACAO,
    LESAO_COMPATIVEL_COM,
    CLASSIFICACAO_RISCO,
    GRUPO_DERMATOSE,
    DERMATOSE,
    GERA_TELECONSULTORIA,
    LESAO_,
    DECS,
    ID_EXAME,
    ID_LAUDO_EMISSAO,
    TIMESTAMP_INICIO,
    NUMERO_LESAO,
    OUTRAS_INFORMACOES,
    CONCLUSAO,
    ID_PACIENTE,
    ID_MEDICO_SOLICITANTE,
    NOME_MEDICO_SOLICITANTE
} from './fieldNames';
import { CID_10 } from '@stt-componentes/cid10/dist/lib/fieldNames';

export const values = (initialValues, idExame, idLaudoEmissao, idPaciente, idSolicitante, nomeSolicitante, lesoes, classificacaoRisco, compatibilidades) => {
    let val = {
        [ID_EXAME]: idExame,
        [ID_LAUDO_EMISSAO]: idLaudoEmissao,
        [ID_PACIENTE]: idPaciente,
        [ID_MEDICO_SOLICITANTE]: idSolicitante,
        [NOME_MEDICO_SOLICITANTE]: nomeSolicitante,
        [DESCRICAO_ESTUDO]: 'Exame de Teledermatologia',
        [TIMESTAMP_INICIO]: new Date().getTime(),
        [CID_10]: [],
        [DECS]: []
    };

    let classificacaoRiscoPadrao = null;
    if (classificacaoRisco.length > 0) {
        classificacaoRiscoPadrao = classificacaoRisco[0].sigla;
    }

    let compatibilidade = null;
    const compatibilidadePadrao = compatibilidades.filter(l => l.padrao);
    if (compatibilidadePadrao.length) {
        compatibilidade = compatibilidadePadrao[0];
    }

    lesoes.forEach((lesao, indice) => {
        const numero = lesao.numero;
        if (initialValues && initialValues.hasOwnProperty(`${LESAO_}${numero}`)) {
            val[`${LESAO_}${numero}`] = {
                ...initialValues[`${LESAO_}${numero}`]
            }
        } else {
            val[`${LESAO_}${numero}`] = {
                [NUMERO_LESAO]: numero, 
                [LESAO_ELEMENTAR]: [],
                [LESAO_SECUNDARIA]: [],
                [COLORACAO]: [],
                [MORFOLOGIA]: [],
                [DISTRIBUICAO]: [],
                [TAMANHO]: lesao.id_tamanho ? {
                    id: lesao.id_tamanho,
                    descricao: lesao.descricao_tamanho
                } : null,
                [LOCALIZACAO]: lesao.id_local_lesao ? {
                    id: lesao.id_local_lesao,
                    descricao: lesao.descricao_local_lesao
                } : null,
                [OUTRAS_INFORMACOES]: '',
                [CONCLUSAO]: '',
                [LESAO_COMPATIVEL_COM]: compatibilidade,
                [CLASSIFICACAO_RISCO]: classificacaoRiscoPadrao,
                [GRUPO_DERMATOSE]: null,
                [DERMATOSE]: null,
                [GERA_TELECONSULTORIA]: false
            }
        }
    });

    if (initialValues) {
        return {
            ...val,
            [TIMESTAMP_INICIO]: initialValues[TIMESTAMP_INICIO],
            [CID_10]: initialValues[CID_10],
            [DECS]: initialValues[DECS]
        }
    }

    return val;
}