import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import axios from 'axios';
import { getHeaders } from '../../request';
import Pesquisa from './pesquisa';
import Visualizacao from './visualizacao';
import Utils from '../../utils';
import {
    SttLoading,
    SttTranslate
} from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
}));

const Aba = (props) => {
    const { user, strings, ...other } = props;
    const classes = useStyles();

    const [gerandoPdf, setGerandoPdf] = useState(false);

    const DERMATO_API_BASE_URL = global.gConfig.url_base_dermato;

    const imprimir = (idExame, dataHoraAcesso) => {
        setGerandoPdf(true);

        Utils.imprimirFn({ id: idExame }, () => {
            setGerandoPdf(false);
            // Atualiza registro de acesso ao exame - IT
            axios
                .put(`${DERMATO_API_BASE_URL}/registrar-impressao-exame-it`, { idExame, dataHoraAcesso }, { headers: getHeaders() })
                .catch(err => console.log(err));
        });
    }

    const imprimirProtocolo = (exame) => {
        setGerandoPdf(true);
        Utils.imprimirProtocoloFn(exame, () => setGerandoPdf(false));
    }

    const imprimirTermo = (exame) => {
        setGerandoPdf(true);
        Utils.imprimirTcleFn({ id: exame.id_paciente }, () => setGerandoPdf(false));
    }

    return (
        <>
            <Switch>
                <Route path="/exames/visualizar/:id?" children={<Visualizacao imprimirFn={imprimir} imprimirProtocoloFn={imprimirProtocolo} imprimirTermoFn={imprimirTermo} />} />
                <Route path="/" children={<Pesquisa {...other} imprimirFn={imprimir} imprimirProtocoloFn={imprimirProtocolo} imprimirTermoFn={imprimirTermo} />} />
            </Switch>
            <div className={classes.carregando}>
                <SttLoading
                    open={gerandoPdf}
                    text={strings.gerandoDocumento}
                />
            </div>
        </>
    );

}

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps, null)(SttTranslate('Exame')(Aba));