export default {
    SET_AUTHENTICATED: 'SET_AUTHENTICATED',
    SET_USER: 'SET_USER',
    LOGOUT: 'LOGOUT',
    SET_MESSAGE: 'SET_MESSAGE',
    SET_CPF_SOLICITANTE: 'SET_CPF_SOLICITANTE',
    SET_CPF_PACIENTE: 'SET_CPF_PACIENTE',
    SET_ID_SOLICITACAO_ORIGEM: 'SET_ID_SOLICITACAO_ORIGEM',
    SET_ID_SISTEMA_ORIGEM: 'SET_ID_SISTEMA_ORIGEM',
    SET_EXAMES_SEM_LAUDO: 'SET_EXAMES_SEM_LAUDO'
};