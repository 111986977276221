import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter, useLocation } from "react-router-dom";
import { SttHeader, SttMainNavigationBar, SttSplashScreen, SttTranslate, MateriaisSuporte, SttNotificationScreen, SttHelpdeskShortcut } from '@stt-componentes/core';
import { temPerfilRBAC, temPermissaoRBAC } from '../secutity/acl';
import { TIPO_USUARIO, PERMISSOES, PERFIL } from '../common/Constants';
import { getHeaders } from '../request';

const Menu = ({ strings, user }) => {
    const { origem } = user;
    const location = useLocation();
    const history = useHistory();
    const [tabAtual, setTabAtual] = useState(0);
    const [submenus, setSubmenus] = useState([]);
    const [openSS, setOpenSS] = useState(false);
    const [openMS, setOpenMS] = useState(false);
    const [exibirNotificacao, setExibirNotificacao] = useState(false);

    useEffect(() => {
        let menuOptions = [];
        if (user.credenciais) {
            if (temPermissaoRBAC(user, PERMISSOES.TELEDERMATO) && origem !== TIPO_USUARIO.EXTERNO) {
                setExibirNotificacao(true);
                menuOptions = [...menuOptions, {
                    id: '/',
                    text: strings.inicio,
                    button: true,
                    onClick: () => {
                        history.push("/");
                    }
                }];
            }
            if (temPerfilRBAC(user, PERFIL.ADMINISTRADOR) || temPerfilRBAC(user, PERFIL.ADMINISTRADOR_ESTADUAL) && origem !== TIPO_USUARIO.EXTERNO) {
                menuOptions = [...menuOptions, {
                    id: '/laudos-graves',
                    text: strings.laudosGraves,
                    button: true,
                    onClick: () => {
                        history.push("/laudos-graves");
                    }
                }];
            }
            if (temPermissaoRBAC(user, PERMISSOES.SOLICITAR_EXAME)) {
                menuOptions = [...menuOptions, {
                    id: '/solicitacao',
                    text: strings.solicitacao,
                    button: true,
                    onClick: () => {
                        history.push("/solicitacao");
                    }
                }];
            }
            if ((temPermissaoRBAC(user, PERMISSOES.VISUALIZAR_SOLICITACAO) || temPermissaoRBAC(user, PERMISSOES.CRIAR_EXAME)) &&
                origem !== TIPO_USUARIO.EXTERNO) {
                menuOptions = [...menuOptions, {
                    id: '/imagens',
                    text: strings.envioImagens,
                    button: true,
                    onClick: () => {
                        history.push("/imagens");
                    }
                }];
            }
            if (temPermissaoRBAC(user, PERMISSOES.VISUALIZAR_EXAME) && origem !== TIPO_USUARIO.EXTERNO) {
                menuOptions = [...menuOptions, {
                    id: '/exames',
                    text: strings.exames,
                    button: true,
                    onClick: () => {
                        history.push("/exames");
                    }
                }];
            }
            if (temPermissaoRBAC(user, PERMISSOES.LAUDAR_EXAME_FABRICA) && origem !== TIPO_USUARIO.EXTERNO) {
                menuOptions = [...menuOptions, {
                    id: '/laudo',
                    text: strings.laudo,
                    button: true,
                    onClick: () => {
                        history.push("/laudo");
                    }
                }];
            }
            setSubmenus(menuOptions);
        }
    }, [user.credenciais]);

    useEffect(() => {
        submenus.forEach((menu, i) => {
            if (location.pathname.includes(menu.id)) {
                setTabAtual(i);
            }
        });
    }, [submenus, location]);

    return (
        <div>
            <SttHeader
                titulo={global.gConfig.instancia_nome}
                config={global.gConfig}
                opcoesDuvida={{
                    onClickNotasAtualizacao: () => setOpenSS(!openSS),
                    onClickMateriaisSuporte: () => setOpenMS(!openMS),
                    strings
                }}
            />
            <SttMainNavigationBar
                titulo={strings.modulo}
                config={global.gConfig}
                submenus={submenus}
                selectedTab={tabAtual}
                callbackNavigationBar={(a, b) => { }}
            />
            <SttSplashScreen
                modulo={global.gConfig.modulo_nome}
                versao={global.gConfig.modulo_versao}
                novidades={global.gConfig.modulo_novidades}
                textoAjuda={global.gConfig.texto_ajuda}
                perfisRBAC={user.perfisRBAC}
                open={openSS}
                setOpen={setOpenSS}
            />
            <MateriaisSuporte
                strings={strings}
                config={global.gConfig}
                headers={getHeaders()}
                open={openMS}
                setOpen={setOpenMS}
            />
            {
                exibirNotificacao &&
                <SttNotificationScreen
                    config={global.gConfig}
                    strings={strings}
                    headers={getHeaders()}
                    perfisRBAC={user.perfisRBAC}
                />
            }
            <SttHelpdeskShortcut
                config={global.gConfig}
                usuario={user}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
    };
};

export default connect(mapStateToProps, null)(SttTranslate('Cabecalho')(withRouter(Menu)));
