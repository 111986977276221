export const FIELDS = {
    TABAGISMO: 'tabagismo',
    TABAGISMO_CIGARROS_DIA: 'tabagismoCigarrosDia',
    TABAGISMO_ANOS_FUMO: 'tabagismoAnosFumo',
    ETILISMO: 'etilismo',
    FOTOTIPO: 'fototipo',
    COMORBIDADES: 'comorbidades',
    COMORBIDADES_OUTRAS: 'comorbidadesOutras',
    INFECCOES: 'infeccoes',
    INFECCOES_OUTRAS: 'infeccoesOutras',
    EXPOSICAO_SOL_HORAS_DIA: 'exposicaoSolHorasDia',
    EXPOSICAO_SOL_MESES_ANOS: 'exposicaoSolMesesAno',
    FOTOPROTETOR_FREQUENCIA: 'fotoprotetorFrequencia',
    FOTOPROTETOR_PERIODO: 'fotoprotetorPeriodo',
    CID_10: 'cid10',
    RESULTADO_BIOPSIA: 'resultadoBiopsia',
    OBSERVACOES: 'observacoes'
};