import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import { makeStyles } from "@material-ui/core/styles";
import StickyBox from "react-sticky-box";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setLaudar as setLaudarAction } from '../../reducers/actions/laudo';
import axios from 'axios';
import { getHeaders } from '../../request';
import { SttGrid, SttAlerta, SttButton, SttHidden, SttLoading, SttTranslate } from '@stt-componentes/core';
import Laudo from './laudo';
import Imagens from './imagens';
import Invalidar from '../exame/invalidar';
import { useMoment } from '../../hooks';
import { Client } from '@hapi/nes/lib/client';

const useStyles = makeStyles(theme => ({
    wrapperBotao: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    modalBody: {
        overflow: 'hidden'
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    }
}));

const ConteinerLaudo = (props) => {
    const { user, strings, idExame, idRede, setLaudar, callbackProximoExame, callbackExameCarregado, notificar } = props;

    const DERMATO_API_BASE_URL = global.gConfig.url_base_dermato;
    const REDES_OCULTAR_IGNORAR_EXAME = global.gConfig.redes_ocultar_ignorar_exame;

    const location = useLocation();
    const moment = useMoment();
    const classes = useStyles();
    const [exame, setExame] = useState(null);
    const [erro, setErro] = useState(false);
    const [mensagemErro, setMensagemErro] = useState('');
    const [contextoFabricalaudo] = useState(location.pathname === '/laudo');
    const [atencao, setAtencao] = useState(false);

    // Invalidação do exame
    const [invalidar, setInvalidar] = useState(false);

    const handleCloseAlerta = () => {
        if (contextoFabricalaudo) {
            callbackProximoExame();
        } else {
            setLaudar(false);
        }
    }

    const [opcoesAlerta] = useState([{
        title: strings.ok,
        onClick: handleCloseAlerta
    }]);

    useEffect(() => {
        if (idExame) {
            axios
                .get(`${DERMATO_API_BASE_URL}/laudo/${idExame}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        let { data } = response.data;
                        let dataMoment = moment(data.exame.data_nascimento_paciente);
                        if (dataMoment.isValid()) {
                            var now = moment();
                            var dataMomentFormatada = moment(dataMoment, 'DD-MM-YYYY');
                            var idade = moment.duration(now.diff(dataMomentFormatada));
                            var anos = idade.years();
                            var meses = idade.months();
                            var dias = idade.days();
                            data.exame.data_nascimento_paciente_formatada += ` (${anos} ano(s), ${meses} mes(es) e ${dias} dia(s))`
                        }
                        setExame(data);
                        if (contextoFabricalaudo) {
                            callbackExameCarregado();                   
                        }
                    }
                })
                .catch(err => {
                    console.log(err);
                    const { response } = err;
                    let msg = strings.erroDesconhecido;
                    let arrMensagem = [];
                    if (response) {
                        const { data } = response;
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        msg = arrMensagem.join('\n');
                    }
                    setMensagemErro(msg);
                    setErro(true);
                });
        }

        return () => {
            setLaudar(false);
            cancelarEmissao();
        }
    }, []);

    const voltar = () => {
        // Controle de componentes. Volta para tela anterior
        setLaudar(false);

        // Cancela emissão do laudo
        cancelarEmissao();
    }

    const cancelarEmissao = () => {
        axios
            .post(`${DERMATO_API_BASE_URL}/cancelar-laudo`, { idExame }, { headers: getHeaders() })
            .catch(err => console.log(err));
    }

    const ignorarExame = () => {
        setExame(null);
        notificar(strings.ignorandoExame);
        // Insere exame ignorado
        axios
            .post(`${DERMATO_API_BASE_URL}/ignorar-exame`, { idExame }, { headers: getHeaders(), timeout: 15000 })
            .then((resposta) => {
                // Carrega o próximo exame
                callbackProximoExame();
            })
            .catch(err => {
                console.log(err);
                setMensagemErro(strings.erroIgnorarExame);
                setErro(true);
            });
    }

    useEffect(() => {
        if (idExame) {
            const DERMATO_API_WS_BASE_URL = global.gConfig.ws_url_base_dermato;
            const path = `/laudo-emissao/${idExame}`;
            const client = new Client(DERMATO_API_WS_BASE_URL);
            client.connect()
                .then(() => {
                    const handler = (update, flags) => {
                        if (update.funcionario !== user.idFuncionario) {
                            setAtencao(true);
                        }
                    };
                
                    console.debug(`Subscribing to ${path}`);
                    client.subscribe(path, handler);
                })
                .catch(error => {
                    console.log(error);
                });
            return () => {
                console.debug(`Unsubscribing from ${path}`);
                client.unsubscribe(path, null);
            }
        }
    }, [idExame]);

    return (
        <>
            <div className={classes.carregando}>
                <SttLoading
                    open={!exame && !contextoFabricalaudo}
                    text={strings.carregandoExame}
                />
            </div>
            {
                exame && 
                <>
                    <SttGrid container spacing={3}>
                        <SttGrid item xs={12} className={classes.wrapperBotao}>
                            {
                                <SttButton
                                    variant="contained"
                                    danger={+true}
                                    onClick={() => setInvalidar(true)}
                                >
                                    {strings.invalidar}
                                </SttButton>
                            }

                            {
                                !contextoFabricalaudo && 
                                <SttButton
                                    type="button"
                                    variant="outlined"
                                    color="primary"
                                    onClick={voltar}
                                >
                                    {strings.voltar}
                                </SttButton>
                            }

                            {
                                contextoFabricalaudo && !REDES_OCULTAR_IGNORAR_EXAME.includes(idRede) &&
                                <SttButton
                                    type="button"
                                    variant="outlined"
                                    color="primary"
                                    onClick={ignorarExame}
                                >
                                    {strings.proximoExame}
                                </SttButton>
                            }

                        </SttGrid>
                    </SttGrid>
                    <SttGrid container spacing={3}>
                        <SttHidden mdUp>
                            <SttGrid item xs={12}>
                                <Imagens idExame={idExame} />
                            </SttGrid>
                            <SttGrid item xs={12}>
                                <Laudo dados={exame} callbackProximoExame={callbackProximoExame} /> 
                            </SttGrid>
                        </SttHidden>
                        <SttHidden only={['xs', 'sm']}>
                            <SttGrid item xs={6}>
                                <StickyBox>
                                    <Imagens idExame={idExame} />
                                </StickyBox>
                            </SttGrid>
                            <SttGrid item xs={6}>
                                <Laudo dados={exame} callbackProximoExame={callbackProximoExame} /> 
                            </SttGrid>
                        </SttHidden>
                    </SttGrid>
                </>
            }
            {
                invalidar && 
                <Invalidar 
                    invalidar={invalidar} 
                    setInvalidar={setInvalidar} 
                    idExame={idExame} 
                    idRede={idRede} 
                    callback={callbackProximoExame} 
                />
            }
            <SttAlerta
                open={erro}
                title={strings.erro}
                message={mensagemErro}
                type="error"
                options={opcoesAlerta}
                onClose={handleCloseAlerta}
            />
            <SttAlerta
                open={atencao}
                title={strings.atencao}
                message={strings.laudoEmEmissao}
                type="alert"
                options={opcoesAlerta}
                onClose={handleCloseAlerta}
            />
        </>
    );
};

ConteinerLaudo.propTypes = {
    strings: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    idExame: PropTypes.number.isRequired,
    callbackProximoExame: PropTypes.func,
    callbackExameCarregado: PropTypes.func
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setLaudar: laudar => dispatch(setLaudarAction(laudar))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SttTranslate('Laudo')(ConteinerLaudo));