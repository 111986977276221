import React, { useEffect, useState, lazy, Suspense } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getHeaders } from '../../../request';
import { TIPO_DERMATOSE, TABAGISMO, FREQUENCIA_USO_FOTOPROTETOR, PERMISSOES } from '../../../common/Constants';
import {
    SttButton,
    SttExpansionPanel,
    SttTextItem,
    SttHeading,
    SttContainer,
    SttCircularProgress,
    SttGrid
} from '@stt-componentes/core';

import EnvioImagens from '../index';
import { temPermissaoRBAC } from '../../../secutity/acl';
const Boneco = lazy(() => import('../../solicitacao/lesoes/boneco'));

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    conteinerLesao: {
        marginBottom: theme.spacing(1)
    }
}));

const Detalhes = (props) => {
    const { strings, user, id, voltar } = props;
    const classes = useStyles();
    const [idComponenteEnvioImagens, setIdComponenteEnvioImagens] = useState(Math.random());
    const [solicitacao, setSolicitacao] = useState(null);
    const [dadosEnvioImagens, setDadosEnvioImagens] = useState([]);
    const [mostrarModalEnvioImagens, setMostrarModalEnvioImagens] = useState(false);
    const [tipo, setTipo] = useState(null);
    const [tipoSolicitacao, setTipoSolicitacao] = useState('');
    const [comorbidades, setComorbidades] = useState([]);
    const [infeccoes, setInfeccoes] = useState([]);
    const [frequenciaFotoprotetor, setFrequenciaFotoprotetor] = useState('');

    useEffect(() => {
        
        const DERMATO_API_BASE_URL = global.gConfig.url_base_dermato;

        if (id) {
            axios
                .get(`${DERMATO_API_BASE_URL}/solicitacao/${id}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        const { data } = response.data;
                        setSolicitacao(data);

                        // Estrutura dados de entrada do componente de envio de imagens
                        let lesoes = [];
                        let tipoDe;
                        data.lesoes.forEach(l => {
                            setTipo(l.tipo);
                            tipoDe = l.tipo;
                            lesoes.push({
                                idParteCorpo: l.id_local_lesao,
                                descricaoParteCorpo: l.descricao_local_lesao,
                                generalGroup: l.grupo_geral,
                                specificGroup: l.grupo_especifico,
                                coordenadaX: l.x,
                                coordenadaY: l.y,
                                numero: l.numero
                            });
                        });
                        setDadosEnvioImagens([{
                            id: data.id_solicitacao,
                            tipo: tipoDe,
                            lesoes: lesoes,
                            instituicao: data.instituicao
                        }]);
                        setTipoSolicitacao(strings[tipoDe]);
                        let comorbidadesDes = [];
                        data.comorbidades.forEach(c => comorbidadesDes.push(c.descricao));
                        setComorbidades(comorbidadesDes);

                        let infeccoesDes = [];
                        data.infeccoes.forEach(i => infeccoesDes.push(i.descricao_infeccao));
                        setInfeccoes(infeccoesDes);

                        for (const f in FREQUENCIA_USO_FOTOPROTETOR) {
                            if (FREQUENCIA_USO_FOTOPROTETOR.hasOwnProperty(f)) {
                                const frequencia = FREQUENCIA_USO_FOTOPROTETOR[f];
                                if (frequencia.value === data.uso_fotoprotetor_frequencia_dia) {
                                    setFrequenciaFotoprotetor(frequencia.label);
                                    break;
                                }
                            }
                        }
                    }
                })
                .catch(err => console.log(err));
        }
    }, [id]);

    const handleAbrirModalEnvioImagens = () => {
        setIdComponenteEnvioImagens(Math.random());
        setMostrarModalEnvioImagens(true);
    }

    const callbackEnvioImagens = () => {
        voltar(true);
    }

    return (
        <SttContainer>
            <SttButton
                type="button"
                variant="outlined"
                color="primary"
                onClick={voltar}
                nomarginleft="true"
            >
                {strings.voltar}
            </SttButton>
            {
                !solicitacao 
                ?
                <div className={classes.carregando}>
                    <SttCircularProgress color="primary" />
                </div>
                :
                <>
                    <SttHeading variant="h1" color="primary" align="center">{tipoSolicitacao}</SttHeading>

                    <SttExpansionPanel
                        title={strings.dadosExame}
                        children={
                            <div>
                                <SttTextItem key="1" title={strings.modalidade} content={solicitacao.modalidade} />
                                <SttTextItem key="2" title={strings.dataSolicitacao} content={`${solicitacao.data_solicitacao_formatada} ${global.gConfig.sufixo_data_hora}`} />
                                <SttTextItem key="3" title={strings.solicitante} content={solicitacao.nome_solicitante} />
                                <SttTextItem key="4" title={strings.cidadeProcedencia} content={solicitacao.cidade_procedencia} />
                            </div>
                        }
                    />

                    <SttExpansionPanel
                        title={strings.dadosPaciente}
                        children={
                            <div>
                                <SttTextItem key="5" title={strings.nome} content={solicitacao.nome_paciente} />
                                <SttTextItem key="6" title={strings.dataNascimento} content={solicitacao.data_nascimento_paciente} />
                                <SttTextItem key="7" title={strings.idade} content={`${solicitacao.idade_paciente} anos`} />
                                <SttTextItem key="8" title={strings.genero} content={solicitacao.sexo_paciente} />
                                <SttTextItem key="9" title={strings.cns} content={solicitacao.cartao_sus} />
                                <SttTextItem key="10" title={strings.peso} content={solicitacao.peso_paciente} />
                                <SttTextItem key="11" title={strings.altura} content={solicitacao.altura_paciente} />
                                <SttTextItem key="12" title={strings.ocupacao} content={solicitacao.profissao_paciente} />
                            </div>
                        }
                    />
                    
                    <SttExpansionPanel
                        title={strings.indicacaoClinica}
                        children={
                            <div>
                                <SttTextItem key="13" title={strings.tabagismo} content={solicitacao.descricaco_tabagismo} />
                                {
                                    solicitacao.tabagismo === TABAGISMO.FUMANTE && 
                                    (
                                        <>
                                            <SttTextItem key="13.1" title={strings.cigarrosDia} content={solicitacao.cigarros_dia} />
                                            <SttTextItem key="12.2" title={strings.anosFuma} content={solicitacao.anos_tabagismo} />
                                        </>
                                    )
                                }
                                <SttTextItem key="14" title={strings.etilismo} content={solicitacao.etilismo} />
                                <SttTextItem key="15" title={strings.fototipo} content={solicitacao.fototipo} />
                                <SttTextItem key="16" title={strings.exposicaoSolHorasDia} content={solicitacao.exposicao_sol_horas_dia} />
                                <SttTextItem key="17" title={strings.exposicaoSolMesesAno} content={solicitacao.exposicao_sol_meses_ano} />
                                <SttTextItem key="18" title={strings.fotoprotetorFrequenciaDiaria} content={frequenciaFotoprotetor} />
                                <SttTextItem key="19" title={strings.fotoprotetorTempoUso} content={solicitacao.tempo_uso_fotoprotetor} />
                                { comorbidades.length > 0 && <SttTextItem key="20" title={strings.comorbidades} content={comorbidades.join(', ')} /> }
                                { infeccoes.length > 0 && <SttTextItem key="21" title={strings.infeccoes} content={infeccoes.join(', ')} /> }
                                {
                                    solicitacao.observacao && 
                                    <SttTextItem key="22" title={strings.observacoes} content={solicitacao.observacao} />
                                }
                                {
                                    solicitacao.resultado_biopsia && 
                                    <SttTextItem key="23" title={strings.resultadoBiopsia} content={solicitacao.resultado_biopsia} />
                                }
                                
                            </div>
                        }
                    />
                    {
                        tipo === TIPO_DERMATOSE.CANCER_PELE &&
                        <SttExpansionPanel
                            title={strings.C}
                            children={
                                <div>
                                    <SttTextItem key="24" title={strings.hmpc} content={solicitacao.hmp_pele ? strings.sim : strings.nao} />
                                    <SttTextItem key="25" title={strings.hmpo} content={solicitacao.hmp_outros ? strings.sim : strings.nao} />
                                </div>
                            }
                        />
                    }

                    <SttExpansionPanel
                        title={strings.lesoes}
                        children={
                            <>
                                {
                                    solicitacao.lesoes.map((lesao, index) => (
                                        <div key={index} className={classes.conteinerLesao}>
                                            <SttHeading variant="h4">{`${strings.lesao} ${index+1} - ${lesao.descricao_local_lesao}`}</SttHeading>

                                            {
                                                tipo === TIPO_DERMATOSE.CANCER_PELE &&
                                                <SttTextItem key={`23_${index}`} title={strings.motivoEncaminhamento} content={lesao.motivo} />
                                            }
                                            <SttTextItem key={`26_${index}`} title={strings.prurido} content={lesao.prurido ? strings.sim : strings.nao} />
                                            <SttTextItem key={`27_${index}`} title={strings.tempoDoenca} content={`${lesao.tempo_doenca} ${lesao.descricao_tempo_doenca}`} />
                                            <SttTextItem key={`28_${index}`} title={strings.hmf} content={lesao.hmf ? strings.sim : strings.nao} />
                                            <SttTextItem key={`29_${index}`} title={strings.hmp} content={lesao.hmp ? strings.sim : strings.nao} />
                                            {
                                                tipo === TIPO_DERMATOSE.OUTRAS_DERMATOSES &&
                                                <SttTextItem key={`30_${index}`} title={strings.sensibilidadeHanseniase} content={lesao.sensibilidade_hanseniase} />
                                            }
                                        </div>
                                    ))
                                }

                                <SttGrid container spacing={3} justifyContent="center" className={classes.container}>
                                    <SttGrid item xs={12}>
                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                            <Boneco
                                                lesoes={solicitacao.lesoes}
                                                readOnly={true}
                                                disableInteraction={true}
                                            />
                                        </Suspense>
                                    </SttGrid>
                                </SttGrid>
                            </>
                        }

                    />
                    {
                        temPermissaoRBAC(user, PERMISSOES.CRIAR_EXAME) &&     
                        <SttButton
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={handleAbrirModalEnvioImagens}
                            nomarginleft="true"
                        >
                            {strings.enviarImagens}
                        </SttButton>
                    }
                </>
            }

            {
                temPermissaoRBAC(user, PERMISSOES.CRIAR_EXAME) &&
                <EnvioImagens 
                    open={mostrarModalEnvioImagens} 
                    resetFormulario={() => {
                        setMostrarModalEnvioImagens(false);
                    }}
                    solicitacoes={dadosEnvioImagens}
                    callbackFinalizarEnvio={callbackEnvioImagens}
                    key={idComponenteEnvioImagens}
                />
            }
        </SttContainer>
    )

}

Detalhes.propTypes = {
    strings: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    id: PropTypes.number.isRequired,
    voltar: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps, null)(Detalhes);