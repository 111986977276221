import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import axios from 'axios';
import { getHeaders } from '../../../request';
import HttpStatus from 'http-status-codes';
import { 
    SttLoading, 
    SttAlerta, 
    SttGrid, 
    SttButton, 
    SttModal, 
    SttText,
    SttHeading,
    SttFormControl,
    SttRadioGroup,
    SttFormControlLabel,
    SttRadioButton,
    SttTranslate
} from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    modalBody: {
        overflow: 'hidden'
    }
}));
const Avaliacao = (props) => {
    const { strings, idExame, dataHoraAcesso, avaliar, setAvaliar, callback } = props;
    const classes = useStyles();

    const [alerta, setAlerta] = useState(false);
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [tipoAlerta, setTipoAlerta] = useState('success');
    const [tituloAlerta, setTituloAlerta] = useState(strings.sucesso);
    const [opcoesAlerta, setOpcoesAlerta] = useState([]);
    const [handleCloseAlerta, setHandleCloseAlerta] = useState(e => console.log(e));
    const [avaliando, setAvaliando] = useState(false);

    // Estado do formulário - respostas
    const [resposta1, setResposta1] = useState('');
    const [resposta2, setResposta2] = useState('');
    const [resposta3, setResposta3] = useState('');
    const [resposta4, setResposta4] = useState('');

    const [mostrarPerguntas234, setMosrarPerguntas234] = useState(false);

    const fecharAvaliacao = () => {
        setAlerta(false);
        setAvaliar(false);
        if (callback) {
            callback();
        }
    }

    const enviarAvaliacao = () => {
        const DERMATO_API_BASE_URL = global.gConfig.url_base_dermato;
        let dados = { idExame, dataHoraAcesso, realizacaoEncaminhamento: parseInt(resposta1) };
        if (resposta2) {
            dados.satisfacaoResultado = parseInt(resposta2);
        }
        if (resposta3) {
            dados.entendimentoResultado = parseInt(resposta3);
        }
        if (resposta4) {
            dados.intencaoEncaminhamento = parseInt(resposta4);
        }
        
        setAvaliando(true);
        axios
            .post(`${DERMATO_API_BASE_URL}/avaliar-exame-it`, dados, { headers: getHeaders() })
            .then(resposta => {
                setHandleCloseAlerta(() => fecharAvaliacao);
                setTituloAlerta(strings.sucesso);
                setTipoAlerta('success');
                setMensagemAlerta(strings.avaliacaoRegistrada);
                setOpcoesAlerta([{
                    title: strings.ok,
                    onClick: fecharAvaliacao
                }]);
                setAlerta(true);
            })
            .catch(err => {
                console.log(err);
                const { response } = err;
                let msg = strings.erroDesconhecido;
                if (response) {
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        const { data } = response;
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        msg = arrMensagem.join('\n');
                        setTituloAlerta(data.message);
                    } else {
                        setTituloAlerta(strings.erro);
                    }
                } else {
                    setTituloAlerta(strings.erro);
                }
                setHandleCloseAlerta(() => setAlerta(false));
                setMensagemAlerta(msg);
                setTipoAlerta('error');
                setOpcoesAlerta([{
                    title: strings.ok,
                    onClick: () => setAlerta(false)
                }]);
                setAlerta(true);
            })
            .finally(() => setAvaliando(false));
    }

    useEffect(() => {
        if (resposta1 && resposta1 !== '5') {
            setMosrarPerguntas234(true);
        } else {
            setResposta2('');
            setResposta3('');
            setResposta4('');
            setMosrarPerguntas234(false);
        }
    }, [resposta1]);

    const handleChangePergunta1 = (event) => {
        setResposta1(event.target.value);
    }

    const handleChangePergunta2 = (event) => {
        setResposta2(event.target.value);
    }

    const handleChangePergunta3 = (event) => {
        setResposta3(event.target.value);
    }

    const handleChangePergunta4 = (event) => {
        setResposta4(event.target.value);
    }

    return (
        <>
            <SttModal
                title={strings.Avaliacao.avaliacao}
                open={avaliar}
                outModalClose={fecharAvaliacao}
                iconClose={fecharAvaliacao}
                maxWidth="md"
                fullWidth={true}
                children={
                    <div className={classes.modalBody}>
                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12}>
                                <SttText variant="body1">{strings.Avaliacao.titulo}</SttText>
                            </SttGrid>
                        </SttGrid>

                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12}>
                                <SttHeading required variant="h4" color="primary">{strings.Avaliacao.p1Label}</SttHeading>
                                <SttFormControl variant="outlined">
                                    <SttRadioGroup>
                                        <SttFormControlLabel 
                                            control={
                                                <SttRadioButton  
                                                    type="radio"
                                                    value="1"
                                                    color="primary"
                                                    checked={resposta1 === '1'}    
                                                />
                                            }
                                            onChange={handleChangePergunta1}
                                            label={strings.Avaliacao.p1Opcao1}
                                        />
                                        <SttFormControlLabel 
                                            control={
                                                <SttRadioButton  
                                                    type="radio"
                                                    value="2"
                                                    color="primary"
                                                    checked={resposta1 === '2'}    
                                                />
                                            }
                                            onChange={handleChangePergunta1}
                                            label={strings.Avaliacao.p1Opcao2}
                                        />
                                        <SttFormControlLabel 
                                            control={
                                                <SttRadioButton  
                                                    type="radio"
                                                    value="3"
                                                    color="primary"
                                                    checked={resposta1 === '3'}    
                                                />
                                            }
                                            onChange={handleChangePergunta1}
                                            label={strings.Avaliacao.p1Opcao3}
                                        />
                                        <SttFormControlLabel 
                                            control={
                                                <SttRadioButton  
                                                    type="radio"
                                                    value="4"
                                                    color="primary"
                                                    checked={resposta1 === '4'}    
                                                />
                                            }
                                            onChange={handleChangePergunta1}
                                            label={strings.Avaliacao.p1Opcao4}
                                        />
                                        <SttFormControlLabel 
                                            control={
                                                <SttRadioButton  
                                                    type="radio"
                                                    value="5"
                                                    color="primary"
                                                    checked={resposta1 === '5'}    
                                                />
                                            }
                                            onChange={handleChangePergunta1}
                                            label={strings.Avaliacao.p1Opcao5}
                                        />
                                    </SttRadioGroup>
                                </SttFormControl>
                            </SttGrid>
                        </SttGrid>
                         {
                            mostrarPerguntas234 && 
                            <div>
                                <SttGrid container spacing={3}>
                                    <SttGrid item xs={12}>
                                        <SttHeading variant="h4" color="primary">{strings.Avaliacao.p2Label}</SttHeading>
                                        <SttFormControl variant="outlined">
                                            <SttRadioGroup>
                                                <SttFormControlLabel 
                                                    control={
                                                        <SttRadioButton  
                                                            type="radio"
                                                            value="1"
                                                            color="primary"
                                                            checked={resposta2 === '1'}    
                                                        />
                                                    }
                                                    onChange={handleChangePergunta2}
                                                    label={strings.Avaliacao.p2Opcao1}
                                                />
                                                <SttFormControlLabel 
                                                    control={
                                                        <SttRadioButton  
                                                            type="radio"
                                                            value="2"
                                                            color="primary"
                                                            checked={resposta2 === '2'}    
                                                        />
                                                    }
                                                    onChange={handleChangePergunta2}
                                                    label={strings.Avaliacao.p2Opcao2}
                                                />
                                                <SttFormControlLabel 
                                                    control={
                                                        <SttRadioButton  
                                                            type="radio"
                                                            value="3"
                                                            color="primary"
                                                            checked={resposta2 === '3'}    
                                                        />
                                                    }
                                                    onChange={handleChangePergunta2}
                                                    label={strings.Avaliacao.p2Opcao3}
                                                />
                                                <SttFormControlLabel 
                                                    control={
                                                        <SttRadioButton  
                                                            type="radio"
                                                            value="4"
                                                            color="primary"
                                                            checked={resposta2 === '4'}    
                                                        />
                                                    }
                                                    onChange={handleChangePergunta2}
                                                    label={strings.Avaliacao.p2Opcao4}
                                                />
                                                <SttFormControlLabel 
                                                    control={
                                                        <SttRadioButton  
                                                            type="radio"
                                                            value="5"
                                                            color="primary"
                                                            checked={resposta2 === '5'}    
                                                        />
                                                    }
                                                    onChange={handleChangePergunta2}
                                                    label={strings.Avaliacao.p2Opcao5}
                                                />
                                            </SttRadioGroup>
                                        </SttFormControl>
                                    </SttGrid>
                                </SttGrid>
                                
                                <SttGrid container spacing={3}>
                                    <SttGrid item xs={12}>
                                        <SttHeading variant="h4" color="primary">{strings.Avaliacao.p3Label}</SttHeading>
                                        <SttFormControl variant="outlined">
                                            <SttRadioGroup>
                                                <SttFormControlLabel 
                                                    control={
                                                        <SttRadioButton  
                                                            type="radio"
                                                            value="1"
                                                            color="primary"
                                                            checked={resposta3 === '1'}    
                                                        />
                                                    }
                                                    onChange={handleChangePergunta3}
                                                    label={strings.Avaliacao.p3Opcao1}
                                                />
                                                <SttFormControlLabel 
                                                    control={
                                                        <SttRadioButton  
                                                            type="radio"
                                                            value="2"
                                                            color="primary"
                                                            checked={resposta3 === '2'}    
                                                        />
                                                    }
                                                    onChange={handleChangePergunta3}
                                                    label={strings.Avaliacao.p3Opcao2}
                                                />
                                                <SttFormControlLabel 
                                                    control={
                                                        <SttRadioButton  
                                                            type="radio"
                                                            value="3"
                                                            color="primary"
                                                            checked={resposta3 === '3'}    
                                                        />
                                                    }
                                                    onChange={handleChangePergunta3}
                                                    label={strings.Avaliacao.p3Opcao3}
                                                />
                                            </SttRadioGroup>
                                        </SttFormControl>
                                    </SttGrid>
                                </SttGrid>
                                
                                <SttGrid container spacing={3}>
                                    <SttGrid item xs={12}>
                                        <SttHeading variant="h4" color="primary">{strings.Avaliacao.p4Label}</SttHeading>
                                        <SttFormControl variant="outlined">
                                            <SttRadioGroup>
                                                <SttFormControlLabel 
                                                    control={
                                                        <SttRadioButton  
                                                            type="radio"
                                                            value="1"
                                                            color="primary"
                                                            checked={resposta4 === '1'}    
                                                        />
                                                    }
                                                    onChange={handleChangePergunta4}
                                                    label={strings.Avaliacao.p4Opcao1}
                                                />
                                                <SttFormControlLabel 
                                                    control={
                                                        <SttRadioButton  
                                                            type="radio"
                                                            value="2"
                                                            color="primary"
                                                            checked={resposta4 === '2'}    
                                                        />
                                                    }
                                                    onChange={handleChangePergunta4}
                                                    label={strings.Avaliacao.p4Opcao2}
                                                />
                                                <SttFormControlLabel 
                                                    control={
                                                        <SttRadioButton  
                                                            type="radio"
                                                            value="3"
                                                            color="primary"
                                                            checked={resposta4 === '3'}    
                                                        />
                                                    }
                                                    onChange={handleChangePergunta4}
                                                    label={strings.Avaliacao.p4Opcao3}
                                                />
                                                <SttFormControlLabel 
                                                    control={
                                                        <SttRadioButton  
                                                            type="radio"
                                                            value="4"
                                                            color="primary"
                                                            checked={resposta4 === '4'}    
                                                        />
                                                    }
                                                    onChange={handleChangePergunta4}
                                                    label={strings.Avaliacao.p4Opcao4}
                                                />
                                                <SttFormControlLabel 
                                                    control={
                                                        <SttRadioButton  
                                                            type="radio"
                                                            value="5"
                                                            color="primary"
                                                            checked={resposta4 === '5'}    
                                                        />
                                                    }
                                                    onChange={handleChangePergunta4}
                                                    label={strings.Avaliacao.p4Opcao5}
                                                />
                                            </SttRadioGroup>
                                        </SttFormControl>
                                    </SttGrid>
                                </SttGrid>
                            </div>
                         }                   
                    </div>
                }
                footer={
                    <div>
                        <SttButton variant="contained" color="primary"onClick={enviarAvaliacao} disabled={!resposta1}>
                            {strings.Avaliacao.avaliar}
                        </SttButton>
                        <SttButton variant="outlined" color="primary" onClick={fecharAvaliacao}>
                            {strings.cancelar}
                        </SttButton>
                    </div>
                }
            />
            <SttAlerta
                open={alerta}
                title={tituloAlerta}
                message={mensagemAlerta}
                type={tipoAlerta}
                options={opcoesAlerta}
                onClose={handleCloseAlerta}
            />

            <div className={classes.carregando}>
                <SttLoading open={avaliando} />
            </div>
        </>
    );

}

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps, null)(SttTranslate('ItinerarioTerapeutico')(Avaliacao));