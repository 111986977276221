import * as yup from 'yup';
import { PERMISSOES } from '../../../common/Constants';
import { temPermissaoRBAC } from '../../../secutity/acl';

import { EQUIPAMENTO } from './fieldNames';

const ValidationDadosGerais = (strings, user) => {
    if (temPermissaoRBAC(user, PERMISSOES.CRIAR_EXAME)) {
        return yup.object().shape({
            [EQUIPAMENTO]: yup.object().shape({
                id: yup.number(),
                id_humano: yup.string(),
                id_modalidade: yup.number(),
                tipo: yup.string(),
                id_instituicao: yup.number()
            }).nullable().required(strings.equipamentoObrigatorio)
        });
    }
    return yup.object().shape({});
}

export { ValidationDadosGerais };