import * as yup from 'yup'
import { LESOES } from '../lesoes/fieldNames';
import { HMP_CANCER_PELE, HMP_CANCER_OUTRO } from './fieldNames';
import { TIPO_DERMATOSE } from '../../../common/Constants';

export default (strings) => {
   return yup.object().shape({
        [HMP_CANCER_PELE]: yup.boolean().when(LESOES, {
            is: (val) => val.some(lesao => lesao.tipo === TIPO_DERMATOSE.CANCER_PELE),
            then: yup.boolean().required(strings.hmpCancerPeleObrigatoria)
        }),
        [HMP_CANCER_OUTRO]: yup.boolean().when(LESOES, {
            is: (val) => val.some(lesao => lesao.tipo === TIPO_DERMATOSE.CANCER_PELE),
            then: yup.boolean().required(strings.hmpOutroCancerObrigatoria)
        })
    });
};