import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { SttTabs } from '@stt-componentes/core';
import { TIPO_ABA_EXAME, VINCULO_PERFIL } from '../../componentes/exame/constantes';
import { MODALIDADE, PERMISSOES } from '../../common/Constants';
import { temPermissaoRede } from '../../secutity/acl';

import Aba from '../../componentes/laudo/fabrica';

const Laudo = (props) => {
    const { user } = props;
    const [abas, setAbas] = useState([]);

    useEffect(() => {
        if (user.perfisRBAC) {
            let dadosAbas = [];
            user.perfisRBAC.forEach(perfil => {
                if (perfil.vinculo === VINCULO_PERFIL.REDE) {
                    perfil.redes.forEach(rede => {
                        if (temPermissaoRede(rede, PERMISSOES.LAUDAR_EXAME_FABRICA)) {
                            const redeJaAdd = dadosAbas.some(d => (d.id === rede.id && d.tipo === TIPO_ABA_EXAME.REDE));
                            if (!redeJaAdd && user.modalidadesRede?.some(modalidade => modalidade.sigla_modalidade === MODALIDADE.SIGLA && modalidade.id_rede_telemedicina === rede.id)) {
                                dadosAbas.push({
                                    id: rede.id,
                                    tipo: TIPO_ABA_EXAME.REDE,
                                    titulo: rede.descricao,
                                    conteudo: Aba,
                                });
                            }
                        }
                    });
                }

            });

    
            setAbas(dadosAbas);
        }
    }, [user])

    return (
        <SttTabs abas={abas} />
    );

}

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps, null)(Laudo);