import React, {useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import axios from 'axios';
import { Link } from "react-router-dom";
import { temPerfilRBAC } from '../../secutity/acl';
import { PERFIL } from '../../common/Constants';
import { VINCULO_PERFIL } from '../exame/constantes';
import { 
    SttContainer, 
    SttCircularProgress, 
    SttGrid, 
    SttCard, 
    SttCardHeader, 
    SttCardContent,
    SttTable,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttTableHead, 
    SttNotification,
    SttButton,
    SttTranslate
} from '@stt-componentes/core';
import { getHeaders } from '../../request';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    container: {
        paddingTop: theme.spacing(3)
    },
    card: {
        padding: theme.spacing(2)
    },
    table: {
        marginBottom: theme.spacing(3)
    },
    botao: {
        width: '100%',
        padding: theme.spacing(2),
        textAlign: 'center'
    },
    link: {
        textDecoration: 'none',
    }
}));

const LaudosGraves = (props) => {
    const { user, strings } = props;
    const classes = useStyles();

    const [dadosInstituicao, setDadosInstituicao] = useState([]);
    const [dadosSolicitante, setDadosSolicitante] = useState([]);
    const [carregado, setCarregado] = useState(false);

    useEffect(() => {
        const DERMATO_API_BASE_URL = global.gConfig.url_base_dermato;
        
        if (user.perfisRBAC && !carregado) {
            const perfisInteresse = user.perfisRBAC.filter(p => [
                PERFIL.MEDICO_SOLICITANTE,
                PERFIL.TECNICO
            ].includes(p.identificador));

            perfisInteresse.forEach(async perfil => {
                let exames = [];
                // INSTITUIÇÃO
                if (perfil.vinculo === VINCULO_PERFIL.INSTITUICAO) {
                    for (let indice = 0; indice < perfil.instituicoes.length; indice++) {
                        const instituicao = perfil.instituicoes[indice];
                        await axios
                            .get(`${DERMATO_API_BASE_URL}/laudos-graves?instituicao=${instituicao.id}&funcionario=${user.idFuncionario}`, { headers: getHeaders(), timeout: 45000 })
                            .then((response) => {
                                exames.push({ instituicao: instituicao.nome, id: indice,  exames: response.data });
                            })
                            .catch(err => console.log(err));
                        
                    }
                    setDadosInstituicao(exames);
                    setCarregado(true);
                } else {
                    if (temPerfilRBAC(user, PERFIL.MEDICO_SOLICITANTE)) {
                        await axios
                            .get(`${DERMATO_API_BASE_URL}/laudos-graves?solicitante=${user.idFuncionario}`, { headers: getHeaders(), timeout: 45000 })
                            .then((response) => {
                                setDadosSolicitante(response.data);
                            })
                            .catch(err => console.log(err))
                            .finally(() => {
                                setCarregado(true);
                            });
                    }
                }
            });
        }
    }, [user, carregado]);
    
    return (
        <SttContainer className={classes.container}>
            {
                !carregado ? 
                    <div className={classes.carregando}>
                        <SttCircularProgress color="primary" />
                    </div>
                :
                ((dadosInstituicao.length || dadosSolicitante.length) && <SttGrid container spacing={3}>
                    <SttGrid item xs={12}>
                        {
                            <SttCard className={classes.card} variant="outlined">
                                <SttCardHeader title={strings.laudosGraves} component="h3" />
                                <SttCardContent>
                                    {
                                        temPerfilRBAC(user, PERFIL.TECNICO) && dadosInstituicao.map(dado => (
                                            <div className={classes.table} key={dado.instituicao}>
                                                <SttTable>
                                                    <SttTableHead>
                                                        <SttTableRow hover={false}>
                                                            <SttTableCell colSpan={2}>{dado.instituicao}</SttTableCell>
                                                        </SttTableRow>
                                                    </SttTableHead>
                                                    <SttTableBody>
                                                        {
                                                            dado.exames && dado.exames.length > 0 && dado.exames.map((exame, i) => (
                                                                <SttTableRow key={exame.id} hover={true}>
                                                                    <SttTableCell>
                                                                        <Link to={{ pathname: '/exames/visualizar', state: { exame: exame.id, aba: dado.id } }} className={classes.link} style={exame.situacao === 'Visualizado' ? { color: 'green' } : { color: 'red' }}>
                                                                            {`${i + 1}. ${strings.exameNumero} ${exame.id}`}
                                                                        </Link>
                                                                    </SttTableCell>
                                                                </SttTableRow>
                                                            )) || 
                                                            <SttTableRow hover={false}>
                                                                <SttTableCell>{strings.nenhumRegistroEncontrado}</SttTableCell>
                                                            </SttTableRow>
                                                        }
                                                    </SttTableBody>
                                                </SttTable>
                                            </div>
                                        ))
                                    }

                                    {
                                        temPerfilRBAC(user, PERFIL.MEDICO_SOLICITANTE) && 
                                        <div className={classes.table}>
                                            <SttTable>
                                                <SttTableBody>
                                                    {
                                                        dadosSolicitante.length > 0 && dadosSolicitante.map((exame, i) => (
                                                            <SttTableRow key={exame.id} hover={true}>
                                                                <SttTableCell>
                                                                    <Link to={{ pathname: '/exames/visualizar', state: { exame: exame.id } }} className={classes.link} style={exame.visualizado ? { color: 'green' } : { color: 'red' }}>
                                                                        {`${i + 1}. ${strings.exameNumero} ${exame.id}`}
                                                                    </Link>
                                                                </SttTableCell>
                                                            </SttTableRow>
                                                        )) || 
                                                        <SttTableRow hover={false}>
                                                            <SttTableCell>{strings.nenhumRegistroEncontrado}</SttTableCell>
                                                        </SttTableRow>
                                                    }
                                                </SttTableBody>
                                            </SttTable>
                                        </div>
                                    }
                                </SttCardContent>
                            </SttCard>

                        }
                    </SttGrid>
                </SttGrid>)
                ||
                <SttGrid container spacing={3}>
                    <SttGrid item xs={12}>
                        <SttNotification severity="warning">
                            {strings.naoFoiPossivelLaudosGraves}
                        </SttNotification>
                        <div className={classes.botao}>
                            <SttButton 
                                variant="contained" 
                                color="primary"
                                onClick={() => setCarregado(false)}>
                                {strings.tentarNovamente}
                            </SttButton>
                        </div>
                    </SttGrid>
                </SttGrid>
            }
        </SttContainer>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps, null)(SttTranslate('LaudosGraves')(LaudosGraves));