import * as yup from 'yup'
import { FIELDS } from './fieldNames';
import { TABAGISMO } from '../../../../common/Constants';

export default (strings) => {
   return yup.object().shape({
        [FIELDS.TABAGISMO]: yup.number().min(1).max(3).required(strings.tabagismoObrigatorio),
        [FIELDS.TABAGISMO_CIGARROS_DIA]: yup.number().when(FIELDS.TABAGISMO, {
            is: (val) => val === TABAGISMO.FUMANTE,
            then: yup.number().typeError(strings.tabagismoCigarrosDiaInvalido).min(1).required(strings.tabagismoCigarrosDiaObrigatorio)
        }),
        [FIELDS.TABAGISMO_ANOS_FUMO]: yup.number().when(FIELDS.TABAGISMO, {
            is: (val) => val === TABAGISMO.FUMANTE,
            then: yup.number().typeError(strings.tabagismoCigarrosDiaInvalido).min(1).max(99).required(strings.tabagismoAnosFumoObrigatorio)
        }),
        [FIELDS.ETILISMO]: yup.number().min(0).max(4).required(strings.etilismoObrigatorio),
        [FIELDS.FOTOTIPO]: yup.string().matches(/(I|II|III|IV|V|VI)/).required(strings.fototipoObrigatorio),
        [FIELDS.COMORBIDADES]: yup.array().of(yup.number()),
        [FIELDS.COMORBIDADES_OUTRAS]: yup.string().max(100),
        [FIELDS.INFECCOES]: yup.array().of(yup.number()),
        [FIELDS.INFECCOES_OUTRAS]: yup.string().max(100),
        [FIELDS.EXPOSICAO_SOL_HORAS_DIA]: yup.object()
            .shape({
                identificador: yup.number(),
                descricao: yup.string(),
                ordenacao: yup.number()
            })
            .nullable()
            .required(strings.esposicaoSolHorasDiaObrigatorio),
        [FIELDS.EXPOSICAO_SOL_MESES_ANOS]: yup.object()
            .shape({
                identificador: yup.number(),
                descricao: yup.string(),
                ordenacao: yup.number()
            })
            .nullable()
            .required(strings.exposicaoSolMesesAnoObrigatorio),
        [FIELDS.FOTOPROTETOR_FREQUENCIA]: yup.object()
            .shape({
                value: yup.number(),
                label: yup.string(),
                sigla: yup.string()
            })
            .nullable()
            .required(strings.fotoprotetorFrequenciaObrigatorio),
        [FIELDS.FOTOPROTETOR_PERIODO]: yup.object()
            .shape({
                identificador: yup.number(),
                descricao: yup.string(),
                ordenacao: yup.number()
            })
            .nullable()
            .required(strings.fotoprotetorEspecificacaoObrigatorio),
        [FIELDS.CID_10]: yup.array().of(yup.string()),
        [FIELDS.RESULTADO_BIOPSIA]: yup.string().trim(),
        [FIELDS.OBSERVACOES]: yup.string().trim()
    });
};