import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import { SttTranslate } from '@stt-componentes/core';
import SolicitacoesEmAberto from '../../componentes/envio-imagens/grid-solicitacoes';

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(3)
    }
}));

const Solicitacoes = (props) => {
    const { strings } = props;
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <SolicitacoesEmAberto strings={strings} />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps, null)(SttTranslate('SolicitacoesEmAberto')(Solicitacoes));