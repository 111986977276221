import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useQuery } from '../hooks';
import axios from 'axios';
import { connect } from 'react-redux';
import { 
    setMessage as setMessageAction, 
    setCpfSolicitante as setCpfSolicitanteAction, 
    setCpfPaciente as setCpfPacienteAction, 
    setIdSolicitacaoOrigem as setIdSolicitacaoOrigemAction,
    setIdSistemaOrigem as setIdSistemaOrigemAction,
    setUser as setUserAction,
    setAuthenticated as setAuthenticatedAction
} from '../reducers/actions';
import { useHistory } from 'react-router-dom';
import HttpStatus from 'http-status-codes';

import { SttCircularProgress } from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    wrapper: {
        paddingBottom: theme.spacing(4)
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '300px'
    }
}));

const Externo = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { setMessage, setCpfSolicitante, setCpfPaciente, setIdSolicitacaoOrigem, setIdSistemaOrigem, setUser, setAuthenticated } = props;

    /** get token */
    const query = useQuery();
    const token = query.get('token');
    
    useEffect(() => {
        if (token) {
            const OAUTH_BASE_URL = global.gConfig.url_base_api_oauth2;
            axios
                .post(`${OAUTH_BASE_URL}/auth/external-login`, null, { headers: { Authorization: `Bearer ${token}` } })
                .then((response) => {
                    const { data } = response;
                    const accessToken = data.access_token;

                    // Armazena o token no localStorage
                    localStorage.setItem('stt-authorization', accessToken);

                    setCpfSolicitante(data.cpf_solicitante);
                    setCpfPaciente(data.cpf_paciente);
                    setIdSolicitacaoOrigem(data.identificador_solicitacao_sistema_origem);
                    setIdSistemaOrigem(data.identificador_sistema_origem);

                    axios
                        .get(`${OAUTH_BASE_URL}/me`, { headers: { Authorization: `Bearer ${accessToken}` } })
                        .then((res) => {
                            setAuthenticated(true);
                            setUser(res.data);
                            history.replace('/solicitacao');
                        })
                        .catch(() => {
                            setAuthenticated(false);
                            history.push('/erro');
                        });
                })
                .catch((err) => {
                    setAuthenticated(false);
                    const { response } = err;
                    if (response && response.status === HttpStatus.UNAUTHORIZED) {
                        setMessage(response.data.message);
                        history.push('/nao-autorizado');
                    } else {
                        setMessage(response.data.message);
                        history.push('/erro');
                    }
                });
        } else {
            history.push('/nao-autorizado');
        }
    }, []);


    return (
        <div className={classes.loading}>
            <SttCircularProgress />
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        setMessage: (message) => dispatch(setMessageAction(message)),
        setCpfSolicitante: (cpf) => dispatch(setCpfSolicitanteAction(cpf)),
        setCpfPaciente: (cpf) => dispatch(setCpfPacienteAction(cpf)),
        setIdSolicitacaoOrigem: (id) => dispatch(setIdSolicitacaoOrigemAction(id)),
        setIdSistemaOrigem: (id) => dispatch(setIdSistemaOrigemAction(id)),
        setUser: (user) => dispatch(setUserAction(user)),
        setAuthenticated: (authenticated) => dispatch(setAuthenticatedAction(authenticated))
    };
};

export default connect(null, mapDispatchToProps)(Externo);
