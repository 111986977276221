import React, { useState } from 'react';
import HttpStatus from 'http-status-codes';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { getHeaders } from '../../request';
import axios from 'axios';

import { 
    SttModal,
    SttButton,
    SttLoading,
    SttAlerta,
    SttTranslate
} from '@stt-componentes/core';

const ConfirmarLaudo = (props) => {
    const { strings, user, html, callbackConfirmar, callbackCancelar, open, idExame } = props;

    const { values, resetForm } = useFormikContext();

    const [confirmando, setConfirmando ] = useState(false);
    // Alerta de erro geral
    const [assinando, setAssinando] = useState(false);
    const [erro, setErro] = useState(false);
    const [mensagemErro, setMensagemErro] = useState('');
    const [tituloAlerta, setTituloAlerta] = useState('');

    // Alerta de sucesso/erro na assinatura digital do laudo
    const [alertaAssinatura, setAlertaAssinatura] = useState(false);
    const [tituloAlertaAssinatura, setTituloAlertaAssinatura] = useState('');
    const [mensagemAlertaAssinatura, setMensagemAlertaAssinatura] = useState('');
    const [tipoAlertaAssinatura, setTipoAlertaAssinatura] = useState('');

    const handleCloseAlerta = () => {
        setErro(false);
    }

    const handleClosAlertaAssinatura = () => {
        callbackConfirmar();
    }

    const confirmar = () => {
        setConfirmando(true);
        const DERMATO_API_BASE_URL = global.gConfig.url_base_dermato;
        const EXAME_API_BASE_URL = global.gConfig.url_base_exames;
        axios
            .post(`${DERMATO_API_BASE_URL}/confirmar-laudo`, values, { headers: getHeaders() })
            .then((response) => {
                resetForm();

                if (user.cpf && user.idufsc) {
                    const urlAuthAssinaturaDigital = `${global.gConfig.url_base_assinatura_digital_ufsc}?cpfAssinador=${user.cpf}`;
                    var win = window.open(urlAuthAssinaturaDigital, "_blank", "height=800, width=1000;");
                    var timer = setInterval(() => {
                        if(win.closed) {
                            clearInterval(timer);
                            setAssinando(true);
                            axios
                                .post(`${EXAME_API_BASE_URL}/assinatura-laudo`, { exame: idExame }, { headers: getHeaders() })
                                .then((res) => {
                                    setTituloAlertaAssinatura(strings.sucesso);
                                    setMensagemAlertaAssinatura(strings.laudoAssinado);
                                    setTipoAlertaAssinatura('success');
                                    setAlertaAssinatura(true);
                                })
                                .catch(err => {
                                    setTituloAlertaAssinatura(strings.erro);
                                    setMensagemAlertaAssinatura(strings.erroAssinatura);
                                    setTipoAlertaAssinatura('error');
                                    setAlertaAssinatura(true);
                                })
                                .finally(() => {
                                    setAssinando(false);
                                });
                        }
                    }, 1500);
                } else {
                    callbackConfirmar();
                }
            })
            .catch(err => {
                console.log(err);
                const { response } = err;
                let msg = strings.erroDesconhecido;
                if (response) {
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        const { data } = response;
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        msg = arrMensagem.join('\n');
                        setTituloAlerta(data.message);
                    } else {
                        setTituloAlerta(strings.erro);
                    }
                } else {
                    setTituloAlerta(strings.erro);
                }
                setMensagemErro(msg);
                setErro(true);
            })
            .finally(() => {
                setConfirmando(false);
            });
    }
    
    return (
        <>
            <SttModal
                title={strings.resumoLaudo}
                open={open}
                outModalClose={callbackCancelar}
                iconClose={callbackCancelar}
                maxWidth="lg"
                fullWidth={true}
                children={
                    <div dangerouslySetInnerHTML={{ __html: html }}></div>
                }
                footer={
                    <div>
                        <SttButton variant="contained" color="primary" disabled={confirmando} onClick={confirmar}>
                            {strings.confirmar}
                        </SttButton>
                        <SttButton variant="outlined" color="primary" onClick={callbackCancelar}>
                            {strings.cancelar}
                        </SttButton>
                    </div>
                }
            />
            <SttLoading
                open={confirmando}
                text={strings.confirmando}
            />
            <SttLoading
                open={assinando}
                text={strings.assinando}
            />

            <SttAlerta
                open={erro}
                title={tituloAlerta}
                message={mensagemErro}
                type="error"
                options={[{
                    title: strings.ok,
                    onClick: handleCloseAlerta
                }]}
                onClose={handleCloseAlerta}
            />

            <SttAlerta
                open={alertaAssinatura}
                title={tituloAlertaAssinatura}
                message={mensagemAlertaAssinatura}
                type={tipoAlertaAssinatura}
                options={[
                    {
                        title: strings.ok,
                        onClick: handleClosAlertaAssinatura
                    }
                ]}
                onClose={handleClosAlertaAssinatura}
            />
        </>
    );
}

ConfirmarLaudo.propTypes = {
    strings: PropTypes.object.isRequired,
    callbackConfirmar: PropTypes.func.isRequired, 
    callbackCancelar: PropTypes.func.isRequired, 
    open: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        atualizarBusca: state.exame.atualizarBusca,
        laudar: state.laudo.laudar
    };
};

export default connect(mapStateToProps, null)(SttTranslate('Laudo')(ConfirmarLaudo));