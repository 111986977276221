import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom'
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { getHeaders } from '../../request';
import axios from 'axios';
import HttpStatus from 'http-status-codes';
import schema from './validationSchema';
import { values } from './initialValues';
import LaudoTextualLesao from './abaTextualLesao';
import ModalConfirmacao from './modalConfirmacao';
import SttCid10 from '@stt-componentes/cid10';
import SttDecs from '@stt-componentes/decs';
import { setLaudar as setLaudarAction } from '../../reducers/actions/laudo';
import { setAtualizarBusca as setAtualizarBuscaAction } from '../../reducers/actions/exame';
import {
    SttExpansionPanel,
    SttGrid,
    SttTabs,
    SttButton,
    SttLoading,
    SttAlerta,
    SttTranslate
} from '@stt-componentes/core';
import { CLASSIFICACAO_RISCO, COLORACAO, DERMATOSE, DISTRIBUICAO, GRUPO_DERMATOSE, LESAO_COMPATIVEL_COM, LESAO_ELEMENTAR, LOCALIZACAO, MORFOLOGIA, TAMANHO } from './fieldNames';
import { LESAO_COMPATIVEL } from '../../common/Constants';

const useStyles = makeStyles(theme => ({
    conteinerDecs: {
        paddingTop: theme.spacing(2)   
    },
    tabs: {
        padding: '0'
    }
}));

const FormLaudo = (props) => {
    const DERMATO_API_BASE_URL = global.gConfig.url_base_dermato;
    const { 
        strings,
        idExame, 
        idLaudoEmissao,
        idPaciente,
        idSolicitante, 
        nomeSolicitante, 
        listaLesoes, 
        tipoDermatose, 
        setAtualizarBusca, 
        setLaudar,
        callbackProximoExame
    } = props;

    const location = useLocation();
    const classes = useStyles();
    // Abas para cada lesão
    const [abasLaudoTextual, setAbasLaudoTextual] = useState([]);
    const [abaTextualAtiva, setAbaTextualAtiva] = useState('0');
    const [abasComErro, setAbasComErro] = useState({});

    // Listas de dados para formulário
    const [lesoesElementares, setLesoesElementares] = useState([]);
    const [lesoesSecundarias, setLesoesSecundarias] = useState([]);
    const [coloracoes, setColoracoes] = useState([]);
    const [morfologias, setMorfologias] = useState([]);
    const [distribuicoes, setDistribuicoes] = useState([]);
    const [tamanhos, setTamanhos] = useState([]);
    const [localizacoes, setLocalizacoes] = useState([]);
    const [classificacoesRisco, setClassificacoesRisco] = useState([]);

    // Controles do modal de confirmação
    const [modalAberto, setModalAberto] = useState(false);
    const [laudo, setLaudo] = useState(null);

    // Controles do formulário
    const [lesoes, setLesoes] = useState(listaLesoes);
    const [validationSchema, setValidationSchema] = useState({});
    const [initialValues, setInitialValues] = useState(null);
    const [erro, setErro] = useState(false);
    const [mensagemErro, setMensagemErro] = useState('');
    const [tituloAlerta, setTituloAlerta] = useState('');
    const [contextoFabricalaudo] = useState(location.pathname === '/laudo');

    const handleCloseAlerta = () => {
        setErro(false);
    }

    const opcoesAlerta = [{
        title: strings.ok,
        onClick: handleCloseAlerta
    }];

    const inicializarFormulario = () => {
        setInitialValues(values(
            initialValues,
            idExame, 
            idLaudoEmissao,
            idPaciente,
            idSolicitante, 
            nomeSolicitante,
            lesoes, 
            classificacoesRisco.filter(e => e.classificacao_padrao),
            LESAO_COMPATIVEL()
        ));
    }

    // Refs para controle da tela em caso de erros de formulário
    const secaoLesaoElementar = useRef();
    const secaoColoracao = useRef();
    const secaoMorfologia = useRef();
    const secaoTamanho = useRef();
    const secaoLocalizacao = useRef();
    const secaoDistribuicao = useRef();
    const secaoCompatibilidade = useRef();
    const secaoClassRisco = useRef();
    const secaoGrupoDermatose = useRef();
    const secaoDermatose = useRef();

    useEffect(() => {

        if (lesoesElementares.length &&
            lesoesSecundarias.length &&
            coloracoes.length &&
            morfologias.length &&
            distribuicoes.length &&
            tamanhos.length &&
            localizacoes.length &&
            classificacoesRisco.length) {

                setValidationSchema(schema(strings, lesoes));
                inicializarFormulario();

                let abasTextual = [];
                lesoes.forEach((lesao) => {
                    const numeroLesao = lesao.numero;
                    const titulo = `${strings.lesao} ${numeroLesao} - ${lesao.descricao_local_lesao}`;
        
                    // Aba do laudo textual
                    abasTextual.push({
                        lesoes,
                        titulo,
                        numeroLesao,
                        conteudo: LaudoTextualLesao,
                        lesoesElementares,
                        lesoesSecundarias,
                        coloracoes,
                        morfologias,
                        distribuicoes,
                        tamanhos,
                        localizacoes,
                        classificacoesRisco,
                        tipoDermatose,
                        refs: {
                            lesaoElementar: secaoLesaoElementar,
                            coloracao: secaoColoracao,
                            morfologia: secaoMorfologia,
                            tamanho: secaoTamanho,
                            localizacao: secaoLocalizacao,
                            distribuicao: secaoDistribuicao,
                            compatibilidade: secaoCompatibilidade,
                            classRisco: secaoClassRisco,
                            grupoDermatose: secaoGrupoDermatose,
                            dermatose: secaoDermatose
                        }
                    });
                });
                
                setAbasLaudoTextual(abasTextual);
            }
    }, [
        lesoesElementares,
        lesoesSecundarias,
        coloracoes,
        morfologias,
        distribuicoes,
        tamanhos,
        localizacoes,
        classificacoesRisco,
        lesoes
    ]);

    useEffect(() => {
        // Carrega lista de lesões elementares
        axios
            .get(`${DERMATO_API_BASE_URL}/lesao-elementar`, { headers: getHeaders() })
            .then((response) => {
                const { data } = response;
                setLesoesElementares(data);
            })
            .catch(err => console.log(err));

        // Carrega lista de lesões secundarias
        axios
            .get(`${DERMATO_API_BASE_URL}/lesao-secundaria`, { headers: getHeaders() })
            .then((response) => {
                const { data } = response;
                setLesoesSecundarias(data);
            })
            .catch(err => console.log(err));

        // Carrega lista de colorações
        axios
            .get(`${DERMATO_API_BASE_URL}/coloracao`, { headers: getHeaders() })
            .then((response) => {
                const { data } = response;
                setColoracoes(data);
            })
            .catch(err => console.log(err));

        // Carrega lista de morfologias
        axios
            .get(`${DERMATO_API_BASE_URL}/morfologia`, { headers: getHeaders() })
            .then((response) => {
                const { data } = response;
                setMorfologias(data);
            })
            .catch(err => console.log(err));

        // Carrega lista de distribuições
        axios
            .get(`${DERMATO_API_BASE_URL}/distribuicao`, { headers: getHeaders() })
            .then((response) => {
                const { data } = response;
                setDistribuicoes(data);
            })
            .catch(err => console.log(err));

        // Carrega lista de tamanhos
        axios
            .get(`${DERMATO_API_BASE_URL}/tamanho-lesao`, { headers: getHeaders() })
            .then((response) => {
                const { data } = response;
                setTamanhos(data);
            })
            .catch(err => console.log(err));

        // Carrega lista de locais de lesão
        axios
            .get(`${DERMATO_API_BASE_URL}/local-lesao`, { headers: getHeaders() })
            .then((response) => {
                const { data } = response;
                setLocalizacoes(data);
            })
            .catch(err => console.log(err));

        // Carrega lista de classificação de risco
        axios
            .get(`${DERMATO_API_BASE_URL}/classificacao-risco`, { headers: getHeaders() })
            .then((response) => {
                const { data } = response;
                setClassificacoesRisco(data);
            })
            .catch(err => console.log(err));
    }, []);

    const callbackCancelar = () => {
        setModalAberto(false);
    }

    const callbackConfirmar = () => {
        setModalAberto(false);
        if (contextoFabricalaudo) {
            callbackProximoExame();
        } else {
            setLaudar(false);
            setAtualizarBusca(true);
        }
    }

    const removerLesao = (indice, val) => {
        const i = indice[0];
        let abaAtiva = i - 1;
        if (abaAtiva < 0) {
            abaAtiva = 0;
        }
        setInitialValues(val);

        const novasLesoes = [
            ...lesoes.slice(0, i),
            ...lesoes.slice(i + 1)
        ];
        setLesoes(novasLesoes);
        setAbaTextualAtiva(abaAtiva.toString());
    }

    const obterIndiceAbaErro = (lesao) => {
        return abasLaudoTextual.findIndex(aba => aba.numeroLesao === lesao);
    }

    return (
        initialValues && 
        <>
            <Formik
                enableReinitialize
                initialValues={ initialValues }
                validationSchema={ validationSchema }
                onSubmit={(data, { setSubmitting }) => {
                    axios
                        .post(`${DERMATO_API_BASE_URL}/resumo-laudo`, data, { headers: getHeaders() })
                        .then((response) => {
                            setLaudo(response.data.data);
                            setModalAberto(true);
                        })
                        .catch(err => {
                            console.log(err);
                            const { response } = err;
                            let msg = strings.erroDesconhecido;
                            if (response) {
                                if (response.status === HttpStatus.BAD_REQUEST) {
                                    let arrMensagem = [];
                                    response.data.errors.forEach(error => {
                                        arrMensagem.push(`- ${error.message}`);
                                    });
                                    msg = arrMensagem.join('\n');
                                    setTituloAlerta(response.data.message);
                                } else {
                                    setTituloAlerta(strings.erro);
                                }
                            } else {
                                setTituloAlerta(strings.erro);
                            }
                            setMensagemErro(msg);
                            setErro(true);
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                }}
            >
                {
                    ({
                        values: val,
                        errors,
                        isSubmitting,
                        submitCount,
                        setFieldValue,
                        handleSubmit
                    }) => {

                        return (
                            <div>
                                <form onSubmit={handleSubmit} noValidate>
                                    <SttExpansionPanel
                                        title={strings.laudoTextual}
                                        compact
                                        children={
                                            <SttTabs 
                                                abas={abasLaudoTextual} 
                                                abaAtiva={abaTextualAtiva} 
                                                handleChangeAbaAtiva={setAbaTextualAtiva}
                                                canClose={lesoes.length > 1} 
                                                handleCloseTab={(indice) => {
                                                    removerLesao(indice, val);
                                                }}
                                                className={classes.tabs}
                                                errors={abasComErro}
                                            />
                                        }
                                    />

                                    <SttExpansionPanel
                                        title={strings.descritores}
                                        compact
                                        opened={false}
                                        children={
                                            <>
                                                <SttGrid container spacing={3}>
                                                    <SttGrid item xs={12}>
                                                        <div className={classes.conteinerDecs}>
                                                        <SttCid10 
                                                            strings={strings}
                                                            headers={getHeaders()}
                                                            multiplos
                                                            formExterno={{
                                                                cid10: val.cid10,
                                                                isSubmitting,
                                                                errors,
                                                                submitCount,
                                                                setFieldValue
                                                            }}
                                                        />
                                                        </div>
                                                    </SttGrid>
                                                </SttGrid>
                                                <SttGrid container spacing={3}>
                                                    <SttGrid item xs={12}>
                                                        <div className={classes.conteinerDecs}>
                                                            <SttDecs 
                                                                strings={strings}
                                                                multiplos
                                                                formExterno={{
                                                                    decs: val.decs,
                                                                    isSubmitting,
                                                                    errors,
                                                                    submitCount,
                                                                    setFieldValue
                                                                }}
                                                            />
                                                        </div>
                                                    </SttGrid>
                                                </SttGrid>
                                            </>
                                        }
                                    />
                                    <SttGrid container spacing={3}>
                                        <SttGrid item xs={12}>        
                                            <SttButton
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={isSubmitting}
                                                nomarginleft="true"
                                                onClick={() => {
                                                    setAbasComErro(null);
                                                    
                                                    const erros = Object.keys(errors).sort();

                                                    let abasComErros = {};
                                                    erros.forEach(e => {
                                                        const abaErro = e.replace(/\D+/g, '');
                                                        const indiceAba = obterIndiceAbaErro(parseInt(abaErro));
                                                        abasComErros[indiceAba.toString()] = true;
                                                    });

                                                    setAbasComErro(abasComErros);

                                                    if (erros.length) {
                                                        const errs = erros.shift();
                                                        const abaErro = errs.replace(/\D+/g, '');
                                                        const indiceAba = obterIndiceAbaErro(parseInt(abaErro));
                                                        setAbaTextualAtiva(indiceAba.toString());
                                                        const objErro = errors[errs];

                                                        let node;
                                                        if (objErro[LESAO_ELEMENTAR]) {
                                                            node = secaoLesaoElementar.current;
                                                        } else if (objErro[COLORACAO]) {
                                                            node = secaoColoracao.current;
                                                        } else if (objErro[MORFOLOGIA]) {
                                                            node = secaoMorfologia.current;
                                                        } else if (objErro[DISTRIBUICAO]) {
                                                            node = secaoDistribuicao.current;
                                                        } else if (objErro[TAMANHO]) {
                                                            node = secaoTamanho.current;
                                                        } else if (objErro[LOCALIZACAO]) {
                                                            node = secaoLocalizacao.current;
                                                        } else if (objErro[LESAO_COMPATIVEL_COM]) {
                                                            node = secaoCompatibilidade.current;
                                                        } else if (objErro[CLASSIFICACAO_RISCO]) {
                                                            node = secaoClassRisco.current;
                                                        } else if (objErro[GRUPO_DERMATOSE]) {
                                                            node = secaoGrupoDermatose.current;
                                                        } else if (objErro[DERMATOSE]) {
                                                            node = secaoDermatose.current;
                                                        }

                                                        if (node) {
                                                            setTimeout(() => {
                                                                node.scrollIntoView({
                                                                    behavior: 'smooth',
                                                                    block: 'center',
                                                                    inline: 'start'
                                                                });
                                                            }, 500);
                                                        }
                                                    }
                                                }}
                                            >
                                                {strings.publicar}
                                            </SttButton>
                                        </SttGrid>
                                    </SttGrid>

                                </form>
                                <SttLoading
                                    open={isSubmitting}
                                    text={strings.gerandoResumo}
                                />
                                <SttAlerta
                                    open={erro}
                                    title={tituloAlerta}
                                    message={mensagemErro}
                                    type="error"
                                    options={opcoesAlerta}
                                    onClose={handleCloseAlerta}
                                />
                                <ModalConfirmacao 
                                    html={laudo} 
                                    open={modalAberto}
                                    idExame={idExame} 
                                    callbackConfirmar={callbackConfirmar} 
                                    callbackCancelar={callbackCancelar} 
                                />
                            </div>
                        )
                    }
                }
            </Formik>
        </>
    );
};

FormLaudo.propTypes = {
    strings: PropTypes.object.isRequired,
    idExame: PropTypes.number.isRequired,
    idLaudoEmissao: PropTypes.number.isRequired,
    idPaciente: PropTypes.number.isRequired,
    idSolicitante: PropTypes.number.isRequired,
    nomeSolicitante: PropTypes.string.isRequired,
    listaLesoes: PropTypes.array.isRequired,
    tipoDermatose: PropTypes.string.isRequired,
    callbackProximoExame: PropTypes.func
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setAtualizarBusca: atualizar => dispatch(setAtualizarBuscaAction(atualizar)),
        setLaudar: laudar => dispatch(setLaudarAction(laudar))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SttTranslate('Laudo')(FormLaudo));