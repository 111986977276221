import { TIPO_EXAME } from '../../common/Constants';
import { CID_10 } from '@stt-componentes/cid10/dist/lib/fieldNames';

export const initialValues = {
    tipoExame: TIPO_EXAME.TELEDERMATOSCOPIA,
    paciente: {
        id: '',
        cpf: '',
        cns: '',
        nome: '',
        genero: null,
        dataNascimento: null,
        municipio: null,
        uf: null,
        pais: null,
        peso: '',
        altura: '',
        profissao: '',
        cep: '',
        logradouro: '',
        bairro: '',
        tipoContato1: null,
        contato1: ''
    },
    motivoEncaminhamento: '',
    hmpCancerPele: '',
    hmpCancerOutro: '',
    tabagismo: undefined,
    tabagismoCigarrosDia: '',
    tabagismoAnosFumo: '',
    etilismo: undefined,
    fototipo: undefined,
    comorbidades: [],
    comorbidadesOutras: '',
    infeccoes: [],
    infeccoesOutras: '',
    medicoSolicitante: null,
    instituicaoSolicitante: null,
    exposicaoSolHorasDia: null,
    exposicaoSolMesesAno: null,
    fotoprotetorFrequencia: null,
    fotoprotetorPeriodo: null,
    resultadoBiopsia: '',
    observacoes: '',
    lesoes: [],
    [CID_10]: []
};