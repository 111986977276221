import * as yup from 'yup'

import { PACIENTE } from '@stt-componentes/paciente/dist/lib/form/fieldNames';

import solicitanteSchema from '../../componentes/solicitacao/solicitante/validationSchema';
import motivoEncaminhamentoSchema from '../../componentes/solicitacao/protocolo-encaminhamento/validationSchema';
import informacoesClinicasSchema from '../../componentes/solicitacao/informacoes-clinicas/form/validationSchema';
import pacienteSchema from '@stt-componentes/paciente/dist/lib/form/validationSchema';
import lesoesSchema from '../../componentes/solicitacao/lesoes/validationSchema';

export default (strings, campos) => {

    const paciente = yup.object().shape({ [PACIENTE]: pacienteSchema(strings, campos) });
    const solicitante = solicitanteSchema(strings);
    const motivoEncaminhamento = motivoEncaminhamentoSchema(strings);
    const informacoesClinicas = informacoesClinicasSchema(strings);
    const lesoes = lesoesSchema(strings);

    const schema =  yup.object().shape({});
    
    return schema
        .concat(lesoes)
        .concat(informacoesClinicas)
        .concat(motivoEncaminhamento)
        .concat(solicitante)
        .concat(paciente);
}