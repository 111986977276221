export const DESCRICAO_ESTUDO = 'descricaoEstudo';
export const LESAO_ELEMENTAR = 'lesaoElementar';
export const LESAO_SECUNDARIA = 'lesaoSecundaria';
export const COLORACAO = 'coloracao';
export const MORFOLOGIA = 'morfologia';
export const DISTRIBUICAO = 'distribuicao';
export const TAMANHO = 'tamanho';
export const LOCALIZACAO = 'localizacao';
export const OUTRAS_INFORMACOES = 'outrasInformacoes';
export const CONCLUSAO = 'conclusao';
export const LESAO_COMPATIVEL_COM = 'lesaoCompativelCom';
export const CLASSIFICACAO_RISCO = 'classificacaoRisco';
export const GRUPO_DERMATOSE = 'grupoDermatose';
export const DERMATOSE = 'dermatose';
export const GERA_TELECONSULTORIA = 'geraTeleconsultoria';
export const LESAO_ = 'lesao_';
export const CID10 = 'cid10';
export const DECS = 'decs';
export const ID_EXAME = 'idExame';
export const ID_LAUDO_EMISSAO = 'idLaudoEmissao';
export const TIMESTAMP_INICIO = 'timestampInicio';
export const ID_PACIENTE = 'idPaciente';
export const ID_MEDICO_SOLICITANTE = 'idMedicoSolicitante';
export const NOME_MEDICO_SOLICITANTE = 'nomeMedicoSolicitante';

export const NUMERO_LESAO = 'numeroLesao';

