export const UF = 'uf';
export const MUNICIPIO = 'municipio';
export const INSTITUICAO = 'instituicao';
export const REDE = 'rede';
export const FUNCIONARIO_ENVIO = 'funcionario-envio';
export const REQUISICAO = 'requisicao';
export const NOME_PACIENTE = 'paciente';
export const DATA_EXAME_DE = 'data-exame-de';
export const DATA_EXAME_ATE = 'data-exame-ate';
export const DATA_LAUDO_DE = 'data-laudo-de';
export const DATA_LAUDO_ATE = 'data-laudo-ate';
export const DATA_INVALIDACAO_DE = 'data-invalidacao-de';
export const DATA_INVALIDACAO_ATE = 'data-invalidacao-ate';
export const SITUACAO_EXAME_LAUDO = 'situacao-laudo';
export const SEM_LAUDO_72H = 'atrasado';
export const ULTRAPASSOU_TEMPO_PREVISTO = 'ultrapassou-tempo-previsto';
export const SITUACAO_EXAME = 'situacao';
export const PROTOCOLO = 'protocolo';
export const SITUACAO = 'situacao';
export const NUMERO_EXAME = 'numero-exame';
