import React, { useState, useEffect } from 'react';
import DadosGerais from '../informacoes-gerais/dadosGerais';
import Panoramicas from '../panoramicas';
import Regua from '../regua';
import Contato from '../contato';
import { EQUIPAMENTO } from './fieldNames';
import { connect } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import { temPerfilRBAC } from '../../../secutity/acl';
import {
    SttModal,
    SttButton,
    SttStepper,
    SttMobileStepper,
    SttAlerta,
    SttTranslate
} from '@stt-componentes/core';
import SttHidden from '@stt-componentes/core/dist/lib/SttLayout/SttHidden';
import { PERFIL } from '../../../common/Constants';

const useStyles = makeStyles(theme => ({
    mobileStepper: {
        marginBottom: theme.spacing(1)
    }
}));

const InformacoesGerais = (props) => {
    const { 
        confirmarFecharModal, 
        strings, 
        user, 
        steps, 
        stepsPanoramicas, 
        stepsRegua,
        stepsContato,
        open, 
        callbackFinalizado, 
        setFieldValue, 
        equipamentos,
        validateForm, 
        setTouched, 
        touched, 
        values,
        lesoes,
        tipoDermatose
    } = props;
    const classes = useStyles();

    //Várias variáveis de estado para realizar verificações de dados enviados, fotos gerais enviadas, etc.
    const [stepAtual, setStepAtual] = useState(0);
    const [panoramicas, setPanoramicas] = useState(false);
    const [regua, setRegua] = useState(false);
    const [contato, setContato] = useState(false);
    const [lesoesBoneco, setLesoesBoneco] = useState([]);
    const [passoIntermediarioFinalizado, setPassoIntermediarioFinalizado] = useState(false);
    const [tipoDermatoseExtenso, setTipoDermatoseExtenso] = useState('');
    const [alertaDermatoscopia, setAlertaDermatoscopia] = useState(false);

    const verificarStepAtual = () => {
        if (steps[stepAtual] && steps[stepAtual].chave === 'informacoes_gerais') {
            return <DadosGerais strings={strings} user={user} equipamentos={equipamentos} />
        } else if (steps[stepAtual] && steps[stepAtual].chave === 'panoramicas' && !passoIntermediarioFinalizado) {
            setPanoramicas(true);
        } else if (steps[stepAtual] && steps[stepAtual].chave === 'regua' && !passoIntermediarioFinalizado) {
            setRegua(true);
        } else if (steps[stepAtual] && steps[stepAtual].chave === 'contato' && !passoIntermediarioFinalizado) {
            setContato(true);
        }
    }

    const handleCloseAlertaDermatoscopia = () => {
        setAlertaDermatoscopia(false);
    }

    useEffect(() => {
        setTipoDermatoseExtenso(tipoDermatose === 'O' ? 'Outras dermatoses' : 'Câncer da pele');
        if (!temPerfilRBAC(user, PERFIL.MEDICO_SOLICITANTE) || (temPerfilRBAC(user, PERFIL.MEDICO_SOLICITANTE) && equipamentos.length !== 1)) {
            setStepAtual(0);
        } else {
            setStepAtual(1);
        }
        setPanoramicas(false);
        setRegua(false);
        setContato(false);
        setPassoIntermediarioFinalizado(false);
    }, [lesoes]);

    useEffect(() => {
        if (equipamentos?.length === 1) {
            setFieldValue(EQUIPAMENTO, equipamentos[0]);
        }
    }, [equipamentos]);

    useEffect(() => {
        // Organiza a lista de lesões
        let arrLesoes = [];
        lesoes.forEach(lesao => {
            arrLesoes = [
                ...arrLesoes,
                {
                    ...lesao,
                    x: lesao.coordenadaX,
                    y: lesao.coordenadaY,
                    tipo: tipoDermatose
                }
            ];
        });
        setLesoesBoneco(arrLesoes);
    }, [lesoes]);

    const proximoStep = () => {
        setStepAtual(stepAtual + 1);
        setPassoIntermediarioFinalizado(false);
    }

    const stepAnterior = () => {
        setStepAtual(stepAtual - 1);
        setPassoIntermediarioFinalizado(false);
    }

    const finalizarConjuntoSteps = (val) => {
        callbackFinalizado(val);
    }
    
    const finalizarPanoramicas = () => {
        setPassoIntermediarioFinalizado(true);
        setPanoramicas(false);
    }
    
    const cancelarPanoramicas = () => {
        finalizarPanoramicas();
        setStepAtual(stepAtual - 1);
    }
    
    const finalizarRegua = () => {
        setPassoIntermediarioFinalizado(true);
        setRegua(false);
    }
    
    const cancelarRegua = () => {
        finalizarRegua();
        setStepAtual(stepAtual - 1);
    }
    
    const finalizarContato = () => {
        setPassoIntermediarioFinalizado(true);
        setContato(false);
    }
    
    const cancelarContato = () => {
        finalizarContato();
        setStepAtual(stepAtual - 1);
    }

    return (
        <SttModal
            title={`${strings.titulo} - ${tipoDermatoseExtenso}`}
            open={open}
            outModalClose={confirmarFecharModal}
            iconClose={confirmarFecharModal}
            maxWidth="lg"
            fullWidth={true}
            children={
                <>
                    {
                        !panoramicas && !regua && !contato &&
                        <>
                            <SttHidden only={['xs', 'sm']}>
                                <SttStepper steps={steps.map((step) => step.titulo)} activeStep={stepAtual} />
                                {verificarStepAtual()}
                            </SttHidden>
                            <SttHidden mdUp>
                                {verificarStepAtual()}
                                <SttMobileStepper 
                                    className={classes.mobileStepper}
                                    steps={steps.length} 
                                    activeStep={stepAtual}
                                />
                            </SttHidden>
                        </>
                    }
                    {
                        panoramicas &&
                        <Panoramicas 
                            steps={stepsPanoramicas}
                            open={panoramicas}
                            callbackFinalizado={finalizarPanoramicas}
                            cancelar={cancelarPanoramicas}
                            tipoDermatoseExtenso={tipoDermatoseExtenso}

                            validateForm={validateForm}
                            touched={touched}
                            setTouched={setTouched}
                            values={values}
                            setFieldValue={setFieldValue}
                        />
                    }
                    {
                        regua && 
                        <Regua 
                            steps={stepsRegua}
                            open={regua}
                            callbackFinalizado={finalizarRegua}
                            cancelar={cancelarRegua}
                            tipoDermatoseExtenso={tipoDermatoseExtenso}

                            validateForm={validateForm}
                            touched={touched}
                            setTouched={setTouched}
                            values={values}
                            setFieldValue={setFieldValue}
                            lesoes={lesoesBoneco}
                        />
                    }
                    {
                        contato &&
                        <Contato
                            steps={stepsContato}
                            open={contato}
                            callbackFinalizado={finalizarContato}
                            cancelar={cancelarContato}
                            tipoDermatoseExtenso={tipoDermatoseExtenso}

                            validateForm={validateForm}
                            touched={touched}
                            setTouched={setTouched}
                            values={values}
                            setFieldValue={setFieldValue}
                            lesoes={lesoesBoneco}
                        />
                    }
                    
                    <SttAlerta
                        open={alertaDermatoscopia}
                        title={strings.atencao}
                        message={strings.mensagemDermatoscopia}
                        type={'alert'}
                        onClose={handleCloseAlertaDermatoscopia}
                        options={
                            [
                                {
                                    title: strings.ok,
                                    onClick: () => {
                                        handleCloseAlertaDermatoscopia();
                                        finalizarConjuntoSteps();
                                    }
                                },
                                {
                                    title: strings.cancelar,
                                    onClick: handleCloseAlertaDermatoscopia
                                }
                            ]
                        }
                    />
                </>
            }
            footer={
                <>
                    {
                        stepAtual > 0 &&
                        <SttButton
                            variant="outlined"
                            color="primary"
                            onClick={stepAnterior}>
                            {strings.anterior}
                        </SttButton>
                    }
                    {
                        stepAtual < steps.length - 1 &&
                        <SttButton
                            variant="contained"
                            color="primary"
                            onClick={() => validateForm().then((erros) => {
                                if (Object.keys(erros).length) {
                                    setTouched({ ...touched, ...erros });
                                    return;
                                }
                                proximoStep();
                            })}>
                            {strings.proximo}
                        </SttButton>
                    }
                    {
                        stepAtual === steps.length - 1 &&
                        <SttButton
                            variant="contained"
                            color="primary"
                            onClick={finalizarConjuntoSteps}>
                            {strings.enviarImagens}
                        </SttButton>
                    }
                    {
                        (stepAtual === steps.length - 2 && temPerfilRBAC(user, PERFIL.MEDICO_SOLICITANTE)) &&
                        <SttButton
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setAlertaDermatoscopia(true);
                            }}>
                            {strings.enviarImagens}
                        </SttButton>
                    }

                    <SttButton
                        variant="outlined"
                        color="secondary"
                        onClick={confirmarFecharModal}
                    >
                        {strings.cancelar}
                    </SttButton>
                </>
            }
        />
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps, null)(SttTranslate('EnvioImagens')(InformacoesGerais));