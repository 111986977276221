import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import ModalDadosGerais from './informacoes-gerais/index';
import axios from 'axios';
import HttpStatus from 'http-status-codes';
import { temPerfilRBAC } from '../../secutity/acl';
import { getHeaders } from '../../request';
import { ValidationDadosGerais } from './informacoes-gerais/validationSchema';
import { SttAlerta, SttLoading, SttTranslate } from '@stt-componentes/core';
import { PERFIL, MODALIDADE } from '../../common/Constants';
import Utils from '../../utils';

const initialValues = {
    "equipamento": null,
    "observacao": "",
    "validarImagem": false
};

/**
 * Função que calcula os passos gerais para envio de imagens de exame
 * @param {*} solicitacao solicitacao salva no banco de dados
 * @param {*} strings constantes
 */
const calcularStepsGerais = (solicitacao, strings) => {
    const stepsGeral = [];
    const stepsPanoramicas = [];
    const stepsRegua = [];
    const stepsContato = [];
    const panoramicas = [];

    addArray(stepsGeral, 'informacoes_gerais', strings.Steps.informacoesGerais);
    addArray(stepsGeral, 'panoramicas', strings.Steps.panoramicas);
    addArray(stepsGeral, 'regua', strings.Steps.regua);
    addArray(stepsGeral, 'contato', strings.Steps.contato);

    if (solicitacao.tipo == strings.TIPO.OUTRAS_DERMATOSES) {
        panoramicas.push('corpo_anterior');
        panoramicas.push('corpo_posterior');
        addArray(stepsPanoramicas, 'corpo_anterior', strings.Steps.corpoAnterior, 53);
        addArray(stepsPanoramicas, 'corpo_posterior', strings.Steps.corpoPosterior, 24);
        initialValues['imagem_lesao_corpo_anterior'] = [{}];
        initialValues['imagem_lesao_corpo_posterior'] = [{}];
    }

    // Iterando por cada lesão de uma solicitação
    solicitacao.lesoes.forEach((lesao, indice) => {
        initialValues[`imagem_lesao_aproximacao_${lesao.idParteCorpo}_${indice + 1}`] = [{}];
        addArray(stepsRegua, `aproximacao_${lesao.idParteCorpo}_${indice + 1}`, lesao.descricaoParteCorpo, lesao.idParteCorpo);

        initialValues[`imagem_lesao_contato_${lesao.idParteCorpo}_${indice + 1}`] = [{}];
        addArray(stepsContato, `contato_${lesao.idParteCorpo}_${indice + 1}`, lesao.descricaoParteCorpo, lesao.idParteCorpo);

        if (lesao.specificGroup == strings.CABECA) {
            panoramicas.push('cabeca');
            addArray(stepsPanoramicas, 'cabeca', strings.Steps.cabeca, lesao.idParteCorpo);
            initialValues['imagem_lesao_cabeca'] = [{}];
        }

        if (lesao.specificGroup == strings.TRONCO_ANTERIOR) {
            panoramicas.push('tronco_anterior');
            addArray(stepsPanoramicas, 'tronco_anterior', strings.Steps.troncoAnterior, lesao.idParteCorpo);
            initialValues['imagem_lesao_tronco_anterior'] = [{}];
        }

        if (lesao.specificGroup == strings.TRONCO_POSTERIOR) {
            panoramicas.push('tronco_posterior');
            addArray(stepsPanoramicas, 'tronco_posterior', strings.Steps.troncoPosterior, lesao.idParteCorpo);
            initialValues['imagem_lesao_tronco_posterior'] = [{}];
        }

        if (lesao.specificGroup == strings.BRACO_ESQUERDO_ANTERIOR
            || lesao.specificGroup == strings.BRACO_DIREITO_ANTERIOR) {
            panoramicas.push('membros_superiores_anterior');
            addArray(stepsPanoramicas, 'membros_superiores_anterior', strings.Steps.membrosSuperioresAnterior, lesao.idParteCorpo);
            initialValues['imagem_lesao_membros_superiores_anterior'] = [{}];
        }

        if (lesao.specificGroup == strings.BRACO_ESQUERDO_POSTERIOR || lesao.specificGroup == strings.BRACO_DIREITO_POSTERIOR) {
            panoramicas.push('membros_superiores_posterior');
            addArray(stepsPanoramicas, 'membros_superiores_posterior', strings.Steps.membrosSuperioresPosterior, lesao.idParteCorpo);
            initialValues['imagem_lesao_membros_superiores_posterior'] = [{}];
        }

        if (lesao.specificGroup == strings.PERNA_ESQUERDA_ANTERIOR || lesao.specificGroup == strings.PERNA_DIREITA_ANTERIOR) {
            panoramicas.push('membros_inferiores_anterior');
            addArray(stepsPanoramicas, 'membros_inferiores_anterior', strings.Steps.membrosInferioresAnterior, lesao.idParteCorpo);
            initialValues['imagem_lesao_membros_inferiores_anterior'] = [{}];
        }

        if (lesao.specificGroup == strings.PERNA_ESQUERDA_POSTERIOR || lesao.specificGroup == strings.PERNA_DIREITA_POSTERIOR) {
            panoramicas.push('membros_inferiores_posterior');
            addArray(stepsPanoramicas, 'membros_inferiores_posterior', strings.Steps.membrosInferioresPosterior, lesao.idParteCorpo);
            initialValues['imagem_lesao_membros_inferiores_posterior'] = [{}];
        }
    });

    return { stepsGeral, stepsPanoramicas, stepsRegua, stepsContato, panoramicas };
};

/**
 * Adiciona o elemento ao array caso o mesmo já não tenha sido adicionado
 * @param {*} array array de adição do elemento
 * @param {*} chave chave do elemento no array
 * @param {*} titulo titulo do elemento
 */
const addArray = (array, chave, titulo, idParteCorpo) => {
    const index = array.findIndex(el => el.chave == chave);
    return index === -1 ? array.push({ chave: chave, titulo: titulo, idParteCorpo: idParteCorpo }) : null;
}

const EnvioImagens = ({ open, resetFormulario, solicitacoes = [], user, strings }) => {
    const DERMATO_API_BASE_URL = global.gConfig.url_base_dermato;
    // Controle dos exames já registrados
    const [examesEnviados, setExamesEnviados] = useState([]);

    //Esquemas de validação
    const schemaInformacoesGerais = ValidationDadosGerais(strings, user);

    //Panorâmicas por lesão
    const [panoramicas, setPanoramicas] = useState([]);

    //Steps gerais e lesões formatadas
    const [stepsGeral, setStepsGeral] = useState([]);
    const [stepsPanoramicas, setStepsPanoramicas] = useState([]);
    const [stepsRegua, setStepsRegua] = useState([]);
    const [stepsContato, setStepsContato] = useState([]);
    const [indexSolicitacaoAtual, setIndexSolicitacaoAtual] = useState(0);

    //Alerta 
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [mostrarAlertaCancelamento, setMostrarAlertaCancelamento] = useState(false);
    const [tituloAlerta, setTituloAlerta] = useState(strings.tituloModalCancelamento);
    const [mensagemAlerta, setMensagemAlerta] = useState(strings.mensagemAlertaCancelamento);
    const [tipoAlerta, setTipoAlerta] = useState('alert');

    // Notificação
    const [progresso, setProgresso] = useState(false);
    const [mensagemProgresso, setMensagemProgresso] = useState(strings.mensagemEnviandoImagens);

    // Impressão do protocolo
    const [gerarProtocolo, setGerarProtocolo] = useState(false);

    // Impressão do TCLE
    const [gerarTermo, setGerarTermo] = useState(false);
    const [paciente, setPaciente] = useState(null);

    // Controle de equipamento
    const [equipamentos, setEquipamentos] = useState([]);
    const [erroEquipamento, setErroEquipamento] = useState(false);

    /**
     * Utilizado para calcular os steps gerais do envio de imagens e quais as panorâmicas necessárias
     */
    useEffect(() => {
        if (solicitacoes.length) {
            const stepsGerais = calcularStepsGerais(solicitacoes[indexSolicitacaoAtual], strings);
            const stpGeral = stepsGerais.stepsGeral;
            const stpPan = stepsGerais.stepsPanoramicas;
            const stpRegua = stepsGerais.stepsRegua;
            const stpCont = stepsGerais.stepsContato;
            const pan = stepsGerais.panoramicas;
            setStepsGeral(stpGeral);
            setStepsPanoramicas(stpPan);
            setStepsRegua(stpRegua);
            setStepsContato(stpCont);
            setPanoramicas(pan);
        }
    }, [indexSolicitacaoAtual]);

    useEffect(() => {

        let instituicoes;
        if (!temPerfilRBAC(user, PERFIL.MEDICO_SOLICITANTE)) {
            instituicoes = user.habilitacao.solicitacaoServico.filter(inst => inst.modalidades?.some(modalidade => modalidade.sigla === MODALIDADE.SIGLA)).map(inst => inst.id);
        } else {
            instituicoes = user.habilitacao.vinculo.map(inst => inst.id);
        }

        if (instituicoes.length) {
            const stringInsts = instituicoes.join();
            const UTILITARIOS_API_BASE_URL = global.gConfig.url_base_utilitarios;
            axios
                .get(`${UTILITARIOS_API_BASE_URL}/equipamento?modalidade=${MODALIDADE.SIGLA}&instituicao=${stringInsts}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        setEquipamentos(response.data);
                    } else {
                        setEquipamentos([]);
                        setErroEquipamento(true);
                    }
                })
                .catch(err => console.log(err));
        }

    }, [user]);

    useEffect(() => {
        if (erroEquipamento) {
            setTituloAlerta(strings.erro);
            setMensagemAlerta(strings.noaExisteEquipamento);
            setTipoAlerta('error');
            setOpcoesAlerta([
                {
                    title: strings.ok,
                    onClick: () => {
                        setMostrarAlerta(false);
                        cancelarEnvio();
                    }
                }
            ]);
            setMostrarAlerta(true);
        }

    }, [erroEquipamento]);

    const cancelarEnvio = () => {
        resetFormulario();
    }

    /**
     * Mostra ao usuário alerta para encerramento do envio de imagens
     */
    const confirmarFecharModal = () => {
        setMostrarAlertaCancelamento(true);
    }

    const [opcoesAlerta, setOpcoesAlerta] = useState([]);

    /**
     * Reseta o formulário de envio de imagens e da solicitação
     */
    const finalizarEnvioImagens = () => {
        resetFormulario();
    }

    /**
     * Gera o protocolo do exame
     * 
     * @param {array of number} idExame 
     */
    const imprimirProtocolo = (exames) => {
        Utils.imprimirMultiplosProtocolosFn(exames, () => {});
    }

    /**
     * Gera o termo de autorização
     * 
     * @param {number} paciente
     */
    const imprimirTermo = (pac) => {
        Utils.imprimirTcleFn({ id: pac }, () => {
            setProgresso(false);
            finalizarEnvioImagens();
        });
    }

    useEffect(() => {
        if (examesEnviados.length) {
            setMensagemProgresso(strings.gerandoNumeroProtocolo);
            imprimirProtocolo(examesEnviados);
        }
    }, [gerarProtocolo]);

    useEffect(() => {
        if (paciente) {
            imprimirTermo(paciente);
        }
    }, [gerarTermo]);

    if (!solicitacoes[indexSolicitacaoAtual]) {
        return ('');
    }

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={schemaInformacoesGerais}
            onSubmit={(data, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                setProgresso(true);
                let dados = { ...data };
                const formData = new FormData();

                formData.append('id_funcionario', user.idFuncionario);
                formData.append('id_solicitacao', solicitacoes[indexSolicitacaoAtual].id);
                formData.append('id_equipamento', dados['equipamento'].id);
                if (dados['observacao']) {
                    formData.append('observacao', dados['observacao']);
                }
                formData.append('tipo', solicitacoes[indexSolicitacaoAtual].tipo);

                panoramicas.forEach(panoramica => {
                    let arrayImagensPanoramicas = dados[`imagem_lesao_${panoramica}`];
                    arrayImagensPanoramicas.forEach((imagem, indiceImagem) => {
                        if (!formData.get(`imagem_lesao_${panoramica}_${indiceImagem}`)) {
                            formData.append(`imagem_lesao_${panoramica}_${indiceImagem}`, imagem);
                        }
                    });
                });
                //Verificar as imagens da lesão
                solicitacoes[indexSolicitacaoAtual].lesoes.forEach((lesao, indiceLesao) => {
                    let arrayImagensAproximacao = dados[`imagem_lesao_aproximacao_${lesao.idParteCorpo}_${(indiceLesao + 1)}`];
                    arrayImagensAproximacao.forEach((imagem, indiceImagem) => {
                        formData.append(`imagem_lesao_aproximacao_${lesao.idParteCorpo}_${(indiceLesao + 1)}_${(indiceImagem + 1)}`, imagem);
                    });

                    let arrayImagensContato = dados[`imagem_lesao_contato_${lesao.idParteCorpo}_${(indiceLesao + 1)}`];
                    arrayImagensContato.forEach((imagem, indiceImagem) => {
                        if (imagem instanceof File) {
                            formData.append(`imagem_lesao_contato_${lesao.idParteCorpo}_${(indiceLesao + 1)}_${(indiceImagem + 1)}`, imagem);
                        }
                    });
                });

                axios
                    .post(`${DERMATO_API_BASE_URL}/exame`, formData, { headers: { ...getHeaders(), 'Content-Type': 'multipart/form-data' } })
                    .then((response) => {

                        if (response.status == 201) {
                            // Armazena o identificador do exame gerado
                            setExamesEnviados(() => [...examesEnviados, response.data.data.exame]);
                            setPaciente(response.data.data.paciente);
                            // Post message
                            const msg = {
                                "codigo": "201",
                                "identificadores_exames": response.data.data.exame
                            }
                            window.parent.postMessage(msg, '*');

                            resetForm();
                            if (solicitacoes[indexSolicitacaoAtual + 1]) {
                                setProgresso(false);
                                setIndexSolicitacaoAtual(indexSolicitacaoAtual + 1);
                            } else {
                                // Post message
                                const mensagem = {
                                    "codigo": "999"
                                }
                                window.parent.postMessage(mensagem, '*');

                                // Envio concluído
                                // Gera o protocolo
                                setGerarProtocolo(true);

                                // Gera o TCLE
                                setGerarTermo(true);
                            }
                        } else {
                            setProgresso(false);
                            setTituloAlerta(strings.titulo);
                            setMensagemAlerta(strings.mensagemErro);
                            setTipoAlerta('error');
                            setOpcoesAlerta([
                                {
                                    title: strings.ok,
                                    onClick: () => { setMostrarAlerta(false) }
                                }
                            ]);
                            setMostrarAlerta(true);

                            // Post message
                            const mensagem = {
                                "codigo": "500",
                                "mensagem": strings.mensagemErro
                            }
                            window.parent.postMessage(mensagem, '*');
                        }
                    })
                    .catch(err => {
                        console.error(err);
                        setProgresso(false);
                        const { response } = err;
                        let msg = strings.erroDesconhecido;
                        let titulo = '';
                        let msgAlerta = '';

                        if (response) {
                            if (response.status === HttpStatus.BAD_REQUEST) {
                                const dadosResp = response.data;
                                let arrMensagem = [];
                                dadosResp.errors.forEach(error => {
                                    arrMensagem.push(`- ${error.message}`);
                                });
                                msg = arrMensagem.join('\n');
                                titulo = dadosResp.message;
                                msgAlerta = msg;
                            } else {
                                titulo = strings.erro;
                                msgAlerta = msg;
                            }
                        } else {
                            titulo = strings.erro;
                            msgAlerta = msg;
                        }

                        // Post message
                        const mensagem = {
                            "codigo": "500",
                            "mensagem": err.message
                        }
                        window.parent.postMessage(mensagem, '*');

                        // Notificação
                        setOpcoesAlerta([
                            {
                                title: strings.ok,
                                onClick: () => { setMostrarAlerta(false) }
                            }
                        ]);
                        setTipoAlerta('error');
                        setTituloAlerta(titulo);
                        setMensagemAlerta(msgAlerta);
                        setMostrarAlerta(true);
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            }}
        >
            {
                ({
                    touched,
                    validateForm,
                    setTouched,
                    values,
                    setFieldValue,
                    handleSubmit
                }) => {
                    return (
                        <>
                            <form noValidate onSubmit={handleSubmit}>
                                {
                                    <ModalDadosGerais
                                        steps={stepsGeral}
                                        stepsPanoramicas={stepsPanoramicas}
                                        stepsRegua={stepsRegua}
                                        stepsContato={stepsContato}
                                        open={open}
                                        callbackFinalizado={handleSubmit}
                                        titulo={strings.titulo}
                                        confirmarFecharModal={confirmarFecharModal}
                                        validateForm={validateForm}
                                        touched={touched}
                                        setTouched={setTouched}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        equipamentos={equipamentos}
                                        lesoes={solicitacoes[indexSolicitacaoAtual].lesoes}
                                        tipoDermatose={solicitacoes[indexSolicitacaoAtual].tipo}
                                    />
                                }

                                <SttLoading
                                    open={progresso}
                                    text={mensagemProgresso}
                                />
                                <SttAlerta
                                    open={mostrarAlerta}
                                    title={tituloAlerta}
                                    message={mensagemAlerta}
                                    type={tipoAlerta}
                                    options={opcoesAlerta}
                                    onClose={() => {
                                        setMostrarAlerta(false);
                                    }}
                                />

                                <SttAlerta
                                    open={mostrarAlertaCancelamento}
                                    title={strings.tituloModalCancelamento}
                                    message={strings.mensagemAlertaCancelamento}
                                    type={'alert'}
                                    onClose={() => {
                                        setMostrarAlertaCancelamento(false);
                                    }}
                                    options={
                                        [
                                            {
                                                title: strings.sim,
                                                onClick: () => {
                                                    setMostrarAlertaCancelamento(false);
                                                    cancelarEnvio();
                                                }
                                            },

                                            {
                                                title: strings.nao,
                                                onClick: () => {
                                                    setMostrarAlertaCancelamento(false);
                                                }
                                            }
                                        ]
                                    }
                                />
                            </form>
                        </>
                    )
                }
            }
        </Formik>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps, null)(SttTranslate('EnvioImagens')(EnvioImagens));