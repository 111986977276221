import React, { Fragment, lazy, Suspense, useState, useEffect } from 'react';
import {
    SttGrid,
    SttFileSelect,
    SttCircularProgress,
    SttButton
} from '@stt-componentes/core';
import { Field, FieldArray } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
const Boneco = lazy(() => import('./../solicitacao/lesoes/boneco'));

const useStyles = makeStyles(theme => ({
    divImagem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        minHeight: '250px'
    },
    imgAtual: {
        maxHeight: '320px',
        maxWidth: '320px',
        margin: 'auto'
    },
    botaoMais: {
        minWidth: 0,
        marginLeft: 0
    },

    botaoMenos: {
        minWidth: 0
    },

    divExternaImagens: {
        maxHeight: '250px',
        overflow: 'auto'
    },
    container: {
        marginBottom: 0,
        marginTop: theme.spacing(1)
    }
}));

const ImagemLesao = (props) => {
    const classes = useStyles();
    const { setFieldValue, steps, stepAtual, strings, lesoes = [], values } = props;
    const [imagens, setImagens] = useState([]);

    const uploadFile = (event, indice, removerImagem) => {
        //Caso a imagem tenha sido excluída, remove a mesma do array de imagens no formulário e retira do array de imagens
        if (!event) {
            if (imagens.length > 1) {
                removerImagem(indice);
            } else {
                setFieldValue(`imagem_lesao_${steps[stepAtual].chave}.${(indice)}`, {});
            }

            setImagens(imgs => imgs.filter((imagem, i) => i !== indice));
        } else {
            if (event.target.files[0]) {
                //Trata nome e blob da imagem para exibição no carousel
                lerImagemBase64(event.target.files[0]);
                //Seta o valor da imagem no campo correspondente do formulário
                setFieldValue(`imagem_lesao_${steps[stepAtual].chave}.${(indice)}`, event.target.files[0]);
            }
        }
    };

    useEffect(() => {
        if (imagens.length === 0) {
            //Trata nome e blob da imagem para exibição no carousel
            values[`imagem_lesao_${steps[stepAtual].chave}`].forEach(file => {
                if (file && file.name) {
                    lerImagemBase64(file);
                }
            });
        }
    }, []);

    const lerImagemBase64 = (file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setImagens(imgs => [
                ...imgs,
                {
                    nome: file.name,
                    imagem: reader.result
                }
            ]);
        };
    }

    const validarImagem = (value) => {
        let error;

        if (!value || !(value instanceof File)) {
            error = strings.imagemLesaoObrigatoria;
        } else if (value.type != ('image/jpeg' || 'image/jpg')) {
            error = strings.imagemInvalida;
        }
        return error;
    }

    return (
        steps.length > 0
        ?    
        <Fragment>
            <SttGrid container spacing={3} justifyContent="center" className={classes.container}>
                <SttGrid item xs={12} sm={6}>
                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                        <Boneco
                            lesoes={lesoes}
                            isGeneralGroup={lesoes.length == 0}
                            idCorpo={lesoes.length == 0 && steps[stepAtual] && steps[stepAtual].idParteCorpo ? steps[stepAtual].idParteCorpo : lesoes[0].idParteCorpo}
                            readOnly={true}
                            disableInteraction={true}
                        />
                    </Suspense>
                </SttGrid>
                <SttGrid item xs={12} sm={6}>
                    <FieldArray
                        name={`imagem_lesao_${steps[stepAtual].chave}`}
                        render={({ insert, remove, push, swap }) => (
                            <div>
                                <div className={classes.divExternaImagens}>
                                    {values[`imagem_lesao_${steps[stepAtual].chave}`]
                                        && values[`imagem_lesao_${steps[stepAtual].chave}`].length > 0 &&
                                        values[`imagem_lesao_${steps[stepAtual].chave}`].map((imagem_lesao, indice) => (
                                            <div key={indice}>
                                                <Field name={`imagem_lesao_${steps[stepAtual].chave}.${(indice)}`} validate={validarImagem}>
                                                    {({
                                                        field: { name, value, onBlur },
                                                        meta
                                                    }) => (
                                                        <>
                                                            <SttFileSelect
                                                                labelInput={strings.imagemLesao}
                                                                onFileChange={(event) => uploadFile(event, indice, remove)}
                                                                file={value}
                                                                inputprops={{
                                                                    name: name,
                                                                    label: strings.imagemLesao,
                                                                    required: true,
                                                                    value: value && value.name,
                                                                    error: meta.touched && meta.error ? meta.error : undefined
                                                                }}
                                                                accept={"image/jpeg"}
                                                            />
                                                        </>
                                                    )}
                                                </Field>
                                            </div>
                                        ))}
                                </div>
                                <SttButton
                                    className={classes.botaoMais}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        push({});
                                    }}
                                >
                                    +
                                </SttButton>
                                <SttButton
                                    className={classes.botaoMenos}
                                    variant="contained"
                                    disabled={values[`imagem_lesao_${steps[stepAtual].chave}`].length == 1}
                                    onClick={() => {
                                        if (values[`imagem_lesao_${steps[stepAtual].chave}`].length > 1) {
                                            let indice = values[`imagem_lesao_${steps[stepAtual].chave}`].length - 1;
                                            remove(indice);
                                            setImagens(imgs => imgs.filter((imagem, i) => i !== indice));
                                        }
                                    }}
                                    color="primary">
                                    -
                                </SttButton>
                                <Carousel
                                    statusFormatter={(current, total) => `Imagem ${current} de ${total}`}
                                >
                                    {
                                        imagens.map((imagem, indice) => {
                                            return (
                                                <div className={classes.divImagem} key={indice}>
                                                    <img src={imagem.imagem} className={classes.imgAtual} />
                                                    <p className="legend">{imagem.nome}</p>
                                                </div>
                                            );
                                        })
                                    }
                                </Carousel>
                            </div>
                        )}
                    />

                </SttGrid>
            </SttGrid>
        </Fragment>
        :
        ''
    );
}

export default ImagemLesao;