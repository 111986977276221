import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import { Field, FastField, useFormikContext } from 'formik';
import { TIPO_DERMATOSE, LESAO_COMPATIVEL } from '../../common/Constants';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';
import { getHeaders } from '../../request';
import { 
    LESAO_ELEMENTAR,
    LESAO_SECUNDARIA,
    COLORACAO,
    MORFOLOGIA,
    DISTRIBUICAO,
    TAMANHO,
    LOCALIZACAO,
    OUTRAS_INFORMACOES,
    CONCLUSAO,
    LESAO_COMPATIVEL_COM,
    LESAO_,
    CLASSIFICACAO_RISCO,
    GRUPO_DERMATOSE,
    DERMATOSE,
    GERA_TELECONSULTORIA
} from './fieldNames';
import {
    SttFormControl,
    SttFormGroup,
    SttFormControlLabel,
    SttCheckbox,
    SttRadioButton,
    SttRadioGroup,
    SttGrid,
    SttHeading,
    SttAutocomplete,
    SttInput,
    SttFormHelperText,
    SttCircularProgress,
    SttButton,
    SttModal,
    SttText,
    SttDivider,
    SttTranslate
} from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    conteinerCheckboxes: {
        [theme.breakpoints.only('xs')]: {
            columnCount: 1
        },
        "@media (min-width: 600px) and (max-width: 959px)": {
            columnCount: 3
        },
        "@media (min-width: 960px) and (max-width: 1079px)": {
            columnCount: 1
        },
        "@media (min-width: 1080px) and (max-width: 1549px)": {
            columnCount: 2
        },
        "@media (min-width: 1550px)": {
            columnCount: 3
        }
    },
    modalBody: {
        overflow: 'hidden'
    },
    label: {
        lineHeight: '0.9'
    }
}));

const LaudoTextualLesao = (props) => {
    const { 
        strings, 
        lesoes,
        numeroLesao,
        lesoesElementares,
        lesoesSecundarias,
        coloracoes,
        morfologias,
        distribuicoes,
        tamanhos,
        localizacoes,
        classificacoesRisco,
        tipoDermatose,
        refs
    } = props;

    const classes = useStyles();

    const { values, setFieldValue } = useFormikContext();

    const [gruposDermatose, setGruposDermatose] = useState([]);
    const [dermatoses, setDermatoses] = useState([]);
    const [compatibilidade, setCompatibilidade] = useState(LESAO_COMPATIVEL(tipoDermatose));

    // Regras de preenchimento
    const [lesaoElementarAusente, setLesaoElementarAusente] = useState(false);
    const [lesaoSecundariaAusente, setLesaoSecundariaAusente] = useState(false);

    // Cópia do laudo entre lesões
    const [copiarLaudo, setCopiarLaudo] = useState(false);
    const [outrasLesoes, setOutrasLesoes] = useState([]);
    const [outrasLesoesSelecionadas, setOutrasLesoesSelecionadas] = useState([]);
    
    // Carrega lista de grupos dermatose
    useEffect(() => {
        setGruposDermatose([]);
        const classificacaoRisco = classificacoesRisco.filter(cr => cr.sigla === values[`${LESAO_}${numeroLesao}`][CLASSIFICACAO_RISCO]);

        if (classificacaoRisco.length > 0) {
            setFieldValue(`${LESAO_}${numeroLesao}.${GERA_TELECONSULTORIA}`, classificacaoRisco[0].gera_teleconsultoria);
        }

        const DERMATO_API_BASE_URL = global.gConfig.url_base_dermato;
        axios
            .get(
                `${DERMATO_API_BASE_URL}/grupo-dermatose`, 
                { 
                    params: { 
                        'classificacao-risco': values[`${LESAO_}${numeroLesao}`][CLASSIFICACAO_RISCO]
                },
                headers: getHeaders() 
            })
            .then((response) => {
                const { data } = response;
                setGruposDermatose(data);
            })
            .catch(err => console.log(err));

    }, [values[`${LESAO_}${numeroLesao}`][CLASSIFICACAO_RISCO]]);

    // Carrega lista de dermatose
    useEffect(() => {
        const DERMATO_API_BASE_URL = global.gConfig.url_base_dermato;
        if (values[`${LESAO_}${numeroLesao}`][GRUPO_DERMATOSE]) {
            axios
                .get(
                    `${DERMATO_API_BASE_URL}/dermatose`, 
                    { 
                        params: { 
                            'grupo-dermatose': values[`${LESAO_}${numeroLesao}`][GRUPO_DERMATOSE]
                    },
                    headers: getHeaders() 
                })
                .then((response) => {
                    const { data } = response;
                    setDermatoses(data);
                })
                .catch(err => console.log(err));
        }
    }, [values[`${LESAO_}${numeroLesao}`][GRUPO_DERMATOSE]]);

    // Seleciona a compatibilidade adequada em caso de câncer de pele
    useEffect(() => {
        const idDermatose = values[`${LESAO_}${numeroLesao}`][DERMATOSE];
        
        if (idDermatose && dermatoses.length) {
            // Reinicializa as opções de compatibilidade
            const compatibilidades = LESAO_COMPATIVEL();
            setCompatibilidade(compatibilidades);

            // Verifica a opção marcada em "Dermatose"
            const dermatose = dermatoses.filter(d => d.id === parseInt(idDermatose));
            if (dermatose.length && dermatose[0].descricao.includes('Câncer de pele')) {
                const cancerPele = compatibilidades.filter(l => l.id === TIPO_DERMATOSE.CANCER_PELE);
                if (cancerPele.length) {
                    setFieldValue(`${LESAO_}${numeroLesao}.${LESAO_COMPATIVEL_COM}`, cancerPele[0]);
                }
            } else {
                const comp = values[`${LESAO_}${numeroLesao}`][LESAO_COMPATIVEL_COM];
                // Se a opção "Câncer da pele" estava selecionada, limpa a seleção
                if (comp && comp.id === TIPO_DERMATOSE.CANCER_PELE) {
                    let padrao = null;
                    const compatibilidadePadrao = compatibilidade.filter(c => c.padrao);
                    if (compatibilidadePadrao.length) {
                        padrao = compatibilidadePadrao[0];
                    }
                    setFieldValue(`${LESAO_}${numeroLesao}.${LESAO_COMPATIVEL_COM}`, padrao);
                }
                // Se a dermatose indicada for diferente de "Outras Especialidades", remove a opção "Câncer da pele" das opções
                if (dermatose.length && dermatose[0].descricao !== 'Outras Especialidades') {
                    const naoCancerPele = compatibilidade.filter(l => l.id !== TIPO_DERMATOSE.CANCER_PELE);
                    setCompatibilidade(naoCancerPele);
                }
            }
        }
    }, [values[`${LESAO_}${numeroLesao}`][DERMATOSE]]);

    useEffect(() => {
        // Separa as outras lesões
        const outras = lesoes.filter(lesao => lesao.numero !== numeroLesao);
        setOutrasLesoes(outras);
    }, [lesoes]);

    const cancelarCopiaLaudo = () => {
        setCopiarLaudo(false);
        setOutrasLesoesSelecionadas([]);
    }

    const handleCheckOutraLesao = (event, value) => {
        let selecionadas;
        let selecionada = parseInt(event.target.value);
        if (value) {
            selecionadas = [
                ...outrasLesoesSelecionadas,
                selecionada
            ];
        } else {
            selecionadas = outrasLesoesSelecionadas.filter(s => s !== selecionada);
        }
        setOutrasLesoesSelecionadas(selecionadas);
    }

    const confirmarCopiarLaudo = () => {
        outrasLesoesSelecionadas.forEach(ols => {
            const laudoLesaoAtual = {
                [LESAO_ELEMENTAR]: values[`${LESAO_}${numeroLesao}`][LESAO_ELEMENTAR],
                [LESAO_SECUNDARIA]: values[`${LESAO_}${numeroLesao}`][LESAO_SECUNDARIA],
                [COLORACAO]: values[`${LESAO_}${numeroLesao}`][COLORACAO],
                [MORFOLOGIA]: values[`${LESAO_}${numeroLesao}`][MORFOLOGIA],
                [DISTRIBUICAO]: values[`${LESAO_}${numeroLesao}`][DISTRIBUICAO],
                [LESAO_COMPATIVEL_COM]: values[`${LESAO_}${numeroLesao}`][LESAO_COMPATIVEL_COM],
                [TAMANHO]: values[`${LESAO_}${numeroLesao}`][TAMANHO],
                [CLASSIFICACAO_RISCO]: values[`${LESAO_}${numeroLesao}`][CLASSIFICACAO_RISCO],
                [GRUPO_DERMATOSE]: values[`${LESAO_}${numeroLesao}`][GRUPO_DERMATOSE],
                [DERMATOSE]: values[`${LESAO_}${numeroLesao}`][DERMATOSE]
            };
            const laudoLesaoDestino = {
                ...values[`${LESAO_}${ols}`],
                ...laudoLesaoAtual
            }
            setFieldValue(`${LESAO_}${ols}`, laudoLesaoDestino);
        });
        NotificationManager.success(strings.dadosCopiados);
        cancelarCopiaLaudo();
    }

    // Controle da regra de preenchimento da lesão elementar
    useEffect(() => {
        const lesoesElementaresSelecionadas = values[`${LESAO_}${numeroLesao}`][LESAO_ELEMENTAR];
        if (lesoesElementaresSelecionadas.length) {
            // Verifica se a opção "Ausente" foi selecionada
            let ausente;
            lesoesElementaresSelecionadas.forEach(selecao => {
                ausente = lesoesElementares.filter(l => parseInt(selecao) === l.id && l.descricao === 'Ausente');
            });

            // A opção "Ausente" foi selecionada. Remove e bloqueia as outras opções
            if (ausente && ausente.length) {
                setLesaoElementarAusente(true);
                if (lesoesElementaresSelecionadas.length > 1) {
                    setFieldValue(`${LESAO_}${numeroLesao}.${LESAO_ELEMENTAR}`, [ ausente[0].id.toString() ]);
                }
            } else {
                setLesaoElementarAusente(false);
            }
        } else {
            setLesaoElementarAusente(false);
        }
        
    }, [values[`${LESAO_}${numeroLesao}`][LESAO_ELEMENTAR]]);


    // Controle da regra de preenchimento da lesão secundária
    useEffect(() => {
        const lesoesSecundariasSelecionadas = values[`${LESAO_}${numeroLesao}`][LESAO_SECUNDARIA];
        if (lesoesSecundariasSelecionadas.length) {
            // Verifica se a opção "Ausente" foi selecionada
            let ausente;
            lesoesSecundariasSelecionadas.forEach(selecao => {
                ausente = lesoesSecundarias.filter(l => parseInt(selecao) === l.id && l.descricao === 'Ausente');
            });

            // A opção "Ausente" foi selecionada. Remove e bloqueia as outras opções
            if (ausente && ausente.length) {
                setLesaoSecundariaAusente(true);
                if (lesoesSecundariasSelecionadas.length > 1) {
                    setFieldValue(`${LESAO_}${numeroLesao}.${LESAO_SECUNDARIA}`, [ ausente[0].id.toString() ]);
                }
            } else {
                setLesaoSecundariaAusente(false);
            }
        } else {
            setLesaoSecundariaAusente(false);
        }
        
    }, [values[`${LESAO_}${numeroLesao}`][LESAO_SECUNDARIA]]);

    return (
        <>
            <SttGrid container spacing={3}>
                <SttGrid item xs={12}>
                    <SttHeading required variant="h4" color="primary">{strings.lesaoElementar}</SttHeading>
                    <div ref={refs.lesaoElementar}></div>
                    <Field name={`${LESAO_}${numeroLesao}.${LESAO_ELEMENTAR}`}>
                        {({
                            field,
                            form,
                            meta
                        }) => (
                            <SttFormControl required error={meta.touched && meta.error ? true : false} fullWidth variant="outlined">
                                <SttFormGroup>
                                    <div className={classes.conteinerCheckboxes}>
                                        {
                                            lesoesElementares.map((lesao) => (
                                                    <SttFormControlLabel
                                                        style={{ width: '100%', marginBottom: '-12px' }}
                                                        classes={{ label: classes.label }}
                                                        control={
                                                            <SttCheckbox
                                                                {...field}
                                                                value={lesao.id}
                                                                color="primary"
                                                                disabled={lesao.descricao !== 'Ausente' && lesaoElementarAusente}
                                                                checked={form.values[`${LESAO_}${numeroLesao}`][LESAO_ELEMENTAR].includes(lesao.id.toString())}
                                                            />
                                                        }
                                                        key={lesao.id}
                                                        label={lesao.descricao}
                                                    />
                                                )
                                            )
                                        }
                                    </div>
                                </SttFormGroup>
                                {
                                    meta.touched && meta.error && 
                                    <SttFormHelperText error>
                                        {meta.error}
                                    </SttFormHelperText>
                                }
                            </SttFormControl>
                        )}
                    </Field>
                </SttGrid>
            </SttGrid>
            <SttGrid container spacing={3}>
                <SttGrid item xs={12}>
                    <SttHeading variant="h4" color="primary">{strings.lesaoSecundaria}</SttHeading>
                    <Field name={`${LESAO_}${numeroLesao}.${LESAO_SECUNDARIA}`}>
                        {({
                            field,
                            form
                        }) => (
                            <SttFormControl variant="outlined">
                                <SttFormGroup>
                                    <div className={classes.conteinerCheckboxes}>
                                        {
                                            lesoesSecundarias.map(lesao => (
                                                <SttFormControlLabel
                                                    style={{ width: '100%', marginBottom: '-12px' }}
                                                    classes={{ label: classes.label }}
                                                    control={
                                                        <SttCheckbox
                                                            {...field}
                                                            value={lesao.id}
                                                            color="primary"
                                                            disabled={lesao.descricao !== 'Ausente' && lesaoSecundariaAusente}
                                                            checked={form.values[`${LESAO_}${numeroLesao}`][LESAO_SECUNDARIA].includes(lesao.id.toString())}
                                                        />
                                                    }
                                                    key={lesao.id}
                                                    label={lesao.descricao}
                                                />
                                            ))
                                        }
                                    </div>
                                </SttFormGroup>
                            </SttFormControl>
                        )}
                    </Field>
                </SttGrid>
            </SttGrid>

            <SttGrid container spacing={3}>
                <SttGrid item xs={12}>
                    <SttHeading required variant="h4" color="primary">{strings.coloracao}</SttHeading>
                    <div ref={refs.coloracao}></div>
                    <Field name={`${LESAO_}${numeroLesao}.${COLORACAO}`}>
                        {({
                            field,
                            form,
                            meta
                        }) => (
                            <SttFormControl required error={meta.touched && meta.error ? true : false} fullWidth variant="outlined">
                                <SttFormGroup>
                                    <div className={classes.conteinerCheckboxes}>
                                        {
                                            coloracoes.map(cor => (
                                                <SttFormControlLabel
                                                    style={{ width: '100%', marginBottom: '-12px' }}
                                                    classes={{ label: classes.label }}
                                                    control={
                                                        <SttCheckbox
                                                            {...field}
                                                            value={cor.id}
                                                            color="primary"
                                                            checked={form.values[`${LESAO_}${numeroLesao}`][COLORACAO].includes(cor.id.toString())}
                                                        />
                                                    }
                                                    key={cor.id}
                                                    label={cor.descricao}
                                                />
                                            ))
                                        }
                                    </div>
                                </SttFormGroup>
                                {
                                    meta.touched && meta.error && 
                                    <SttFormHelperText error>
                                        {meta.error}
                                    </SttFormHelperText>
                                }
                            </SttFormControl>
                        )}
                    </Field>
                </SttGrid>
            </SttGrid>

            <SttGrid container spacing={3}>
                <SttGrid item xs={12}>
                    <SttHeading required variant="h4" color="primary">{strings.morfologia}</SttHeading>
                    <div ref={refs.morfologia}></div>
                    <Field name={`${LESAO_}${numeroLesao}.${MORFOLOGIA}`}>
                        {({
                            field,
                            form,
                            meta
                        }) => (
                            <SttFormControl required error={meta.touched && meta.error ? true : false} fullWidth variant="outlined">
                                <SttFormGroup>
                                    <div className={classes.conteinerCheckboxes}>
                                        {
                                            morfologias.map(mofologia => (
                                                <SttFormControlLabel
                                                    style={{ width: '100%', marginBottom: '-12px' }}
                                                    classes={{ label: classes.label }}
                                                    control={
                                                        <SttCheckbox
                                                            {...field}
                                                            value={mofologia.id}
                                                            color="primary"
                                                            checked={form.values[`${LESAO_}${numeroLesao}`][MORFOLOGIA].includes(mofologia.id.toString())}
                                                        />
                                                    }
                                                    key={mofologia.id}
                                                    label={mofologia.descricao}
                                                />
                                            ))
                                        }
                                    </div>
                                </SttFormGroup>
                                {
                                    meta.touched && meta.error && 
                                    <SttFormHelperText error>
                                        {meta.error}
                                    </SttFormHelperText>
                                }
                            </SttFormControl>
                        )}
                    </Field>
                </SttGrid>
            </SttGrid>
            
            <SttGrid container spacing={3}>
                <SttGrid item xs={12}>
                    <SttHeading required variant="h4" color="primary">{strings.distribuicao}</SttHeading>
                    <div ref={refs.distribuicao}></div>
                    <Field name={`${LESAO_}${numeroLesao}.${DISTRIBUICAO}`}>
                        {({
                            field,
                            form,
                            meta
                        }) => (
                            <SttFormControl required error={meta.touched && meta.error ? true : false} fullWidth variant="outlined">
                                <SttFormGroup>
                                    <div className={classes.conteinerCheckboxes}>
                                        {
                                            distribuicoes.map(distribuicao => (
                                                <SttFormControlLabel
                                                    style={{ width: '100%', marginBottom: '-12px' }}
                                                    classes={{ label: classes.label }}
                                                    control={
                                                        <SttCheckbox
                                                            {...field}
                                                            value={distribuicao.id}
                                                            color="primary"
                                                            checked={form.values[`${LESAO_}${numeroLesao}`][DISTRIBUICAO].includes(distribuicao.id.toString())}
                                                        />
                                                    }
                                                    key={distribuicao.id}
                                                    label={distribuicao.descricao}
                                                />
                                            ))
                                        }
                                    </div>
                                </SttFormGroup>
                                {
                                    meta.touched && meta.error && 
                                    <SttFormHelperText error>
                                        {meta.error}
                                    </SttFormHelperText>
                                }
                            </SttFormControl>
                        )}
                    </Field>
                </SttGrid>
            </SttGrid>

            <SttGrid container spacing={3}>
                <SttGrid item xs={12}>
                    <div ref={refs.tamanho}></div>
                    <Field name={`${LESAO_}${numeroLesao}.${TAMANHO}`}>
                        {({
                            field: { name, value, onBlur },
                            form,
                            meta,
                        }) => (
                            <SttAutocomplete 
                                inputprops={{
                                    name: name,
                                    label: strings.tamanhoCm,
                                    required: true,
                                    error: meta.touched && meta.error ? meta.error : undefined
                                }}
                                getOptionLabel={option => option && option.descricao || ''}
                                getOptionSelected={(option, val) => option && val && option.id === val.id}
                                options={tamanhos}
                                value={value}
                                onBlur={onBlur}
                                onChange={(e, item) => {
                                    form.setFieldValue(`${LESAO_}${numeroLesao}.${TAMANHO}`, item || null);
                                }}
                            />
                        )}
                    </Field>
                </SttGrid>
            </SttGrid>

            <SttGrid container spacing={3}>
                <SttGrid item xs={12}>
                    <div ref={refs.localizacao}></div>
                    <Field name={`${LESAO_}${numeroLesao}.${LOCALIZACAO}`}>
                        {({
                            field: { name, value, onBlur },
                            form,
                            meta,
                        }) => (

                            <SttAutocomplete 
                                inputprops={{
                                    name: name,
                                    label: strings.localizacao,
                                    required: true,
                                    error: meta.touched && meta.error ? meta.error : undefined
                                }}
                                getOptionLabel={option => option && option.descricao || ''}
                                getOptionSelected={(option, val) => option && val && option.id === val.id}
                                options={localizacoes}
                                value={value}
                                onBlur={onBlur}
                                onChange={(e, item) => {
                                    form.setFieldValue(`${LESAO_}${numeroLesao}.${LOCALIZACAO}`, item || null);
                                }}
                            />
                        )}
                    </Field>
                </SttGrid>
            </SttGrid>

            <SttGrid container spacing={3}>
                <SttGrid item xs={12}>
                    <div ref={refs.compatibilidade}></div>
                    <Field name={`${LESAO_}${numeroLesao}.${LESAO_COMPATIVEL_COM}`}>
                        {({
                            field: { name, value, onBlur },
                            form,
                            meta,
                        }) => (
                            <SttAutocomplete 
                                inputprops={{
                                    name: name,
                                    label: strings.lesaoCompativelCom,
                                    required: true,
                                    error: meta.touched && meta.error ? meta.error : undefined
                                }}
                                getOptionLabel={option => option && option.descricao || ''}
                                getOptionSelected={(option, val) => option && val && option.id === val.id}
                                options={compatibilidade}
                                value={value}
                                onBlur={onBlur}
                                onChange={(e, item) => {
                                    form.setFieldValue(`${LESAO_}${numeroLesao}.${LESAO_COMPATIVEL_COM}`, item || null);
                                }}
                            />
                        )}
                    </Field>
                </SttGrid>
            </SttGrid>

            <SttGrid container spacing={3}>
                <SttGrid item xs={12}>
                    <SttHeading variant="h4" color="primary">{strings.outrasInfosPert}</SttHeading>
                    <FastField name={`${LESAO_}${numeroLesao}.${OUTRAS_INFORMACOES}`}>
                        {({
                            field
                        }) => (
                            <SttInput 
                                {...field}
                                multiline
                                rows={4}
                            />
                        )}
                    </FastField>
                </SttGrid>
            </SttGrid>

            <SttGrid container spacing={3}>
                <SttGrid item xs={12}>
                    <SttHeading variant="h4" color="primary">{strings.conclusao}</SttHeading>
                    <FastField name={`${LESAO_}${numeroLesao}.${CONCLUSAO}`}>
                        {({
                            field
                        }) => (
                            <SttInput 
                                {...field}
                                multiline
                                rows={4}
                            />
                        )}
                    </FastField>
                </SttGrid>
            </SttGrid>

            <SttGrid container spacing={3}>
                <SttGrid item xs={12}>
                    <SttHeading required variant="h4" color="primary">{strings.classificacaoRisco}</SttHeading>
                    <div ref={refs.classRisco}></div>
                    <Field name={`${LESAO_}${numeroLesao}.${CLASSIFICACAO_RISCO}`}>
                        {({
                            field,
                            form,
                            meta,
                        }) => (
                            <SttFormControl required error={meta.touched && meta.error ? true : false} variant="outlined">
                                <SttRadioGroup 
                                    row 
                                    onChange={event => {
                                        form.setFieldValue(`${LESAO_}${numeroLesao}.${GRUPO_DERMATOSE}`, null);
                                        form.setFieldValue(`${LESAO_}${numeroLesao}.${DERMATOSE}`, null);
                                        setDermatoses([]);
                                    }}
                                >
                                    {
                                        classificacoesRisco.map(classificacao => {
                                            return (
                                            <SttFormControlLabel 
                                                {...field} 
                                                control={
                                                    <SttRadioButton  
                                                        type="radio"
                                                        value={classificacao.sigla}
                                                        color="primary"
                                                        checked={field.value === classificacao.sigla}    
                                                    />
                                                }
                                                key={classificacao.sigla}
                                                label={classificacao.descricao}
                                            />
                                        )})
                                    }
                                    {
                                        meta.touched && meta.error && 
                                        <SttFormHelperText error>
                                            {meta.error}
                                        </SttFormHelperText>
                                    }
                                </SttRadioGroup>
                            </SttFormControl>
                        )}
                        </Field>
                </SttGrid>
            </SttGrid>

            {

                gruposDermatose.length === 0 
                ?
                <SttCircularProgress color="primary" /> 
                :
                <SttGrid container spacing={3}>
                    <SttGrid item xs={12}>
                        <SttHeading required variant="h4" color="primary">{strings.grupoDermatose}</SttHeading>
                        <div ref={refs.grupoDermatose}></div>
                        <Field name={`${LESAO_}${numeroLesao}.${GRUPO_DERMATOSE}`}>
                            {({
                                field,
                                form,
                                meta,
                            }) => (
                                <SttFormControl required error={meta.touched && meta.error ? true : false} variant="outlined">
                                    <SttRadioGroup
                                        onChange={(event, newValue) => {
                                            form.setFieldValue(`${LESAO_}${numeroLesao}.${DERMATOSE}`, null);
                                            field.onChange(event, newValue);
                                        }}
                                    >
                                        {
                                            gruposDermatose.map(grupo => (
                                                <SttFormControlLabel 
                                                    {...field} 
                                                    control={
                                                        <SttRadioButton  
                                                            type="radio"
                                                            value={grupo.id}
                                                            color="primary"
                                                            checked={parseInt(field.value) === grupo.id}    
                                                        />
                                                    }
                                                    key={grupo.id}
                                                    label={grupo.descricao}
                                                />
                                            ))
                                        }
                                        {
                                            meta.touched && meta.error && 
                                            <SttFormHelperText error>
                                                {meta.error}
                                            </SttFormHelperText>
                                        }
                                    </SttRadioGroup>
                                </SttFormControl>
                            )}
                            </Field>
                    </SttGrid>
                </SttGrid>
            }

            {
                (dermatoses.length === 0 && values[`${LESAO_}${numeroLesao}`][GRUPO_DERMATOSE])
                ? 
                <SttCircularProgress color="primary" /> 
                :
                dermatoses.length > 0 &&
                <SttGrid container spacing={3}>
                    <SttGrid item xs={12}>
                        <SttHeading required variant="h4" color="primary">{strings.dermatose}</SttHeading>
                        <div ref={refs.dermatose}></div>
                        <Field name={`${LESAO_}${numeroLesao}.${DERMATOSE}`}>
                            {({
                                field,
                                meta,
                            }) => (
                                <SttFormControl required error={meta.touched && meta.error ? true : false} variant="outlined">
                                    <SttRadioGroup>
                                        {
                                            dermatoses.map(dermatose => (
                                                <SttFormControlLabel 
                                                    {...field} 
                                                    control={
                                                        <SttRadioButton  
                                                            type="radio"
                                                            value={dermatose.id}
                                                            color="primary"
                                                            checked={parseInt(field.value) === dermatose.id}    
                                                        />
                                                    }
                                                    key={dermatose.id}
                                                    label={dermatose.descricao}
                                                />
                                            ))
                                        }
                                        {
                                            meta.touched && meta.error && 
                                            <SttFormHelperText error>
                                                {meta.error}
                                            </SttFormHelperText>
                                        }
                                    </SttRadioGroup>
                                </SttFormControl>
                            )}
                            </Field>
                    </SttGrid>
                </SttGrid>
            }

            {
                outrasLesoes.length > 0 && 
                <SttGrid container spacing={3}>
                    <SttGrid item xs={12}>
                        <SttButton
                            variant="contained"
                            color="primary"
                            nomarginleft="true"
                            onClick={() => setCopiarLaudo(true)}
                        >
                            {strings.copiarLaudo}
                        </SttButton>
                    </SttGrid>
                </SttGrid>
            }

            <SttModal
                title={strings.copiarLaudo}
                open={copiarLaudo}
                outModalClose={cancelarCopiaLaudo}
                iconClose={cancelarCopiaLaudo}
                maxWidth="sm"
                fullWidth={true}
                children={
                    <div className={classes.modalBody}>
                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12}>
                                <SttText>{strings.copiarLaudoInfo}</SttText>
                                <SttDivider />
                            </SttGrid>
                        </SttGrid>


                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12}>
                                <SttText>{strings.copiarPara}</SttText>
                                <SttFormControl fullWidth variant="outlined">
                                    <SttFormGroup>
                                        {
                                            outrasLesoes.map((lesao) => (
                                                <SttFormControlLabel
                                                    control={
                                                        <SttCheckbox
                                                            value={lesao.numero}
                                                            color="primary"
                                                            checked={outrasLesoesSelecionadas.includes(lesao.numero)}
                                                            onChange={handleCheckOutraLesao}
                                                        />
                                                    }
                                                    key={lesao.numero}
                                                    label={`${strings.lesao} ${lesao.numero} - ${lesao.descricao_local_lesao}`}
                                                />
                                            ))
                                        }
                                    </SttFormGroup>
                                </SttFormControl>
                            </SttGrid>
                        </SttGrid>
                    </div>

                }
                footer={
                    <div>
                        <SttButton variant="contained" color="primary" onClick={confirmarCopiarLaudo} disabled={!outrasLesoesSelecionadas.length}>
                            {strings.confirmar}
                        </SttButton>
                        <SttButton variant="outlined" color="primary" onClick={cancelarCopiaLaudo}>
                            {strings.cancelar}
                        </SttButton>
                    </div>
                }
            />
       </>
    );
}

LaudoTextualLesao.propTypes = {
    strings: PropTypes.object.isRequired,
    numeroLesao: PropTypes.number.isRequired,
    lesoesElementares: PropTypes.array.isRequired,
    lesoesSecundarias: PropTypes.array.isRequired,
    coloracoes: PropTypes.array.isRequired,
    morfologias: PropTypes.array.isRequired,
    distribuicoes: PropTypes.array.isRequired,
    tamanhos: PropTypes.array.isRequired,
    localizacoes: PropTypes.array.isRequired
};

export default SttTranslate('Laudo')(LaudoTextualLesao);