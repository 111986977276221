import TYPES from '../types'

export const setAuthenticated = (authenticated) => {
    return {
        type: TYPES.SET_AUTHENTICATED,
        payload: { authenticated }
    };
}
export const setUser = (user) => {
    return {
        type: TYPES.SET_USER,
        payload: { user }
    };
}
export const logoutDispatch = () => {
    return {
        type: TYPES.LOGOUT
    };
}
export const setMessage = (message) => {
    return {
        type: TYPES.SET_MESSAGE,
        payload: { message }
    };
}

export const setCpfSolicitante = (cpf) => {
    return {
        type: TYPES.SET_CPF_SOLICITANTE,
        payload: { cpf }
    };
}

export const setCpfPaciente = (cpf) => {
    return {
        type: TYPES.SET_CPF_PACIENTE,
        payload: { cpf }
    };
}

export const setIdSolicitacaoOrigem = (id) => {
    return {
        type: TYPES.SET_ID_SOLICITACAO_ORIGEM,
        payload: { id }
    };
}

export const setIdSistemaOrigem = (id) => {
    return {
        type: TYPES.SET_ID_SISTEMA_ORIGEM,
        payload: { id }
    };
}

export const setExamesSemLaudo = (dados) => {
    return {
        type: TYPES.SET_EXAMES_SEM_LAUDO,
        payload: { dados }
    };
}
