import TYPES from './types/envioImagens';
import { estadoInicialForm } from '../componentes/envio-imagens/grid-solicitacoes/estadoInicialForm';
const INITIAL_STATE = {
    parametrosPesquisaSolicitacao: estadoInicialForm
};

const envioImagens = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case TYPES.SET_PARAMETROS_PESQUISA_SOLICITACAO:
            return {
                ...state,
                parametrosPesquisaSolicitacao: payload.parametros
            }

        case TYPES.LIMPAR_PARAMETROS_PESQUISA_SOLICITACAO:
            return INITIAL_STATE;

        default:
            return state;
    }
}

export default envioImagens;
