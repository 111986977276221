import React, { useState } from 'react';
import ImagemLesao from '../imagemLesao';
import { connect } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttModal,
    SttButton,
    SttStepper,
    SttMobileStepper,
    SttTranslate
} from '@stt-componentes/core';
import SttHidden from '@stt-componentes/core/dist/lib/SttLayout/SttHidden';

const useStyles = makeStyles(theme => ({
    mobileStepper: {
        marginBottom: theme.spacing(1)
    }
}));

const Panoramicas = (props) => {
    const { 
        cancelar, 
        strings, 
        user, 
        steps, 
        open, 
        callbackFinalizado, 
        tipoDermatoseExtenso,
        setFieldValue, 
        validateForm, 
        setTouched, 
        touched, 
        values
    } = props;
    const classes = useStyles();

    const [stepAtual, setStepAtual] = useState(0);

    const verificarStepAtual = (setFieldValueFunc) => {
        return (
            <div key={stepAtual}>
                <ImagemLesao
                    strings={strings}
                    user={user}
                    stepAtual={stepAtual}
                    steps={steps}
                    setFieldValue={setFieldValueFunc}
                    values={values}
                />
            </div>
        );
    }

    const proximoStep = () => {
        setStepAtual(stepAtual + 1);
    }

    const stepAnterior = () => {
        setStepAtual(stepAtual - 1);
    }

    const finalizarConjuntoSteps = (val) => {
        callbackFinalizado(val);
    }

    return (
        <SttModal
            title={`${strings.Steps.panoramicas} - ${tipoDermatoseExtenso}`}
            open={open}
            outModalClose={cancelar}
            iconClose={cancelar}
            maxWidth="lg"
            fullWidth={true}
            children={
                <>
                    <SttHidden only={['xs', 'sm']}>
                        <SttStepper steps={steps.map((step) => step.titulo)} activeStep={stepAtual} />
                        {verificarStepAtual(setFieldValue)}
                    </SttHidden>
                    <SttHidden mdUp>
                        {verificarStepAtual(setFieldValue)}
                        <SttMobileStepper 
                            className={classes.mobileStepper}
                            steps={steps.length} 
                            activeStep={stepAtual}
                        />
                    </SttHidden>
                </>
            }
            footer={
                <>
                    {
                        stepAtual > 0 &&
                        <SttButton
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                stepAnterior();
                            }}>
                            {strings.anterior}
                        </SttButton>
                    }
                    {
                        stepAtual < steps.length - 1 &&
                        <SttButton
                            variant="contained"
                            color="primary"
                            onClick={() => validateForm().then((erros) => {
                                if (Object.keys(erros).length) {
                                    setTouched({ ...touched, ...erros });
                                    return;
                                }
                                proximoStep();
                            })}>
                            {strings.proximo}
                        </SttButton>
                    }
                    {
                        stepAtual == steps.length - 1 &&
                        <SttButton
                            variant="contained"
                            color="primary"
                            onClick={() => validateForm().then((erros) => {
                                if (Object.keys(erros).length) {
                                    setTouched({ ...touched, ...erros });
                                    return;
                                }
                                finalizarConjuntoSteps();
                            })}>
                            {strings.finalizar}
                        </SttButton>
                    }
                    <SttButton
                        variant="outlined"
                        color="secondary"
                        onClick={cancelar}
                    >
                        {strings.cancelar}
                    </SttButton>
                </>
            }
        />
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps, null)(SttTranslate('EnvioImagens')(Panoramicas));