export const PERFIL = {
    MEDICO_SOLICITANTE: 'medicoSolicitante',
    MEDICO_LAUDADOR: 'medicoLaudador',
    ADMINISTRADOR: 'administrador',
    TECNICO: 'tecnico',
    REGULADOR_EXAME: 'reguladorExame',
    VISUALIZADOR: 'visualizador',
    VISUALIZADOR_REDE: 'visualizadorRede',
    ADMINISTRADOR_ESTADUAL: 'adminEstadual',
};

export const PERMISSOES = {
    TELEDERMATO: 'TELEDERMATO_TELEDERMATO',
    SOLICITAR_EXAME: 'TELEDERMATO_SOLICITAR_EXAME',
    VISUALIZAR_SOLICITACAO: 'TELEDERMATO_VISUALIZAR_SOLIC_EX',
    CRIAR_EXAME: 'TELEDERMATO_CRIAR_EXAME',
    VISUALIZAR_EXAME: 'TELEDERMATO_VISUALIZAR_EXAME',
    VISUALIZAR_LAUDO: 'TELEDERMATO_VISUALIZAR_LAUDO',
    INVALIDAR_EXAME: 'TELEDERMATO_INVALIDAR_EXAME',
    INVALIDAR_EXAME_LAUDADO: 'TELEDERMATO_INVALIDAR_EXAME_LAUD',
    PRIORIZAR_EXAME: 'TELEDERMATO_PRIORIZAR_EXAME',
    LAUDAR_EXAME: 'TELEDERMATO_LAUDAR_EXAME',
    LAUDAR_EXAME_FABRICA: 'TELEDERMATO_LAUDAR_EXAME_FABRICA',
    VISUALIZAR_IMAGEM: 'TELEDERMATO_VISUALIZAR_IMAGEM',
    TROCAR_REDE: 'TELEDERMATO_TROCAR_REDE'
};

export const MODALIDADE = {
    SIGLA: 'VLP',
    DESCRICAO: 'VL Photographic',
    CODIGO: '1.2.840.10008.5.1.4.1.1.77.1.4',
    DESCRICAO_PT: 'Dermatologia'
};

export const ORIGEM_MANUAL = 'M';

export const TIPO_DERMATOSE = {
    CANCER_PELE: 'C',
    OUTRAS_DERMATOSES: 'O'
};

export const TABAGISMO = {
    FUMANTE: 1,
    EX_FUMANTE: 2,
    NUNCA_FUMOU: 3
};

export const ETILISMO = {
    NUNCA: 0, 
    MENSALMENTE_MENOS: 1, 
    DUAS_QUATRO_VEZES_MES: 2, 
    DUAS_TRES_VEZES_SEMANA: 3, 
    QUATRO_OU_MAIS_VEZES_SEMANA: 4
};

export const FOTOTIPO = {
    PESSOAS_PELE_CLARA_SARDENTAS: 'I', 
    PESSOAS_PELE_CLARA_RUIVAS: 'II', 
    PESSOAS_PELE_BRANCA: 'III', 
    PESSOAS_PELE_CLARA: 'IV', 
    PESSOAS_MORENAS: 'V', 
    PESSOAS_NEGRAS: 'VI'
};

export const DESCRICAO_FOTOTIPO = {
    [FOTOTIPO.PESSOAS_PELE_CLARA_SARDENTAS]: 'I - Pele clara, olhos azuis, sardentos: sempre se queimam e nunca se bronzeiam', 
    [FOTOTIPO.PESSOAS_PELE_CLARA_RUIVAS]: 'II - Pele clara, olhos azuis, verdes ou castanho-claros, cabelos louros ou ruivos: sempre se queimam e, às vezes, se bronzeiam', 
    [FOTOTIPO.PESSOAS_PELE_BRANCA]: 'III - A média das pessoas brancas normais: queimam-se moderadamente, bronzeiam-se gradual e uniformemente', 
    [FOTOTIPO.PESSOAS_PELE_CLARA]: 'IV - Pele clara ou morena clara, cabelos castanho-escuros e olhos escuros: queimam-se muito pouco, bronzeiam-se bastante', 
    [FOTOTIPO.PESSOAS_MORENAS]: 'V - Pessoas morenas: raramente se queimam, bronzeiam-se muito', 
    [FOTOTIPO.PESSOAS_NEGRAS]: 'VI - Negros: nunca se queimam, profundamente pigmentados'
};

export const MOTIVO_ENCAMINHAMENTO = {
    CBC: 1,
    CEC: 2,
    MELANOMA: 3
};

export const FREQUENCIA_USO_FOTOPROTETOR = {
    NENHUMA_VEZ: { value: 0, label: 'Nenhuma vez', sigla: 'NV'},
    UMA_VEZ: { value: 1, label: 'Uma vez', sigla: 'UV' },
    DUAS_VEZES: { value: 2, label: 'Duas vezes', sigla: 'DV' },
    TRES_VEZES_OU_MAIS: { value: 3, label: 'Três vezes ou mais', sigla: 'TV' }
};

export const SENSIBILIDADE_HANSENIASE = {
    PRESENTE: 'P',
    DIMINUIDA: 'D',
    AUSENTE: 'A',
    SEM_INFORMACAO: 'S'
}

export const TIPO_EXAME = {
    TELEDERMATOLOGIA: 'TELEDERMATOLOGIA',
    TELEDERMATOSCOPIA: 'TELEDERMATOSCOPIA'
}

export const TIPO_USUARIO = {
    INTERNO: 'INTERNO',
    EXTERNO: 'EXTERNO'
}

export const LESAO_COMPATIVEL = () => {
    let lista = [
        { id: 'N', descricao: 'Não compatível com câncer da pele', ordem: 1, padrao: false },
        { id: 'A', descricao: 'Necessita análise presencial', ordem: 2, padrao: false },
        { id: 'C', descricao: 'Câncer da pele', ordem: 3, padrao: false },
        { id: 'O', descricao: 'Vide orientações/conclusão', ordem: 4, padrao: true },
    ];

    lista = lista.sort((a, b) => a.ordem > b.ordem);

    return lista;
}

export const IT = {
    MEDICAMENTO_USO_TOPICO: 'T',
    MEDICAMENTO_USO_SISTEMICO: 'S',
    DOCUMENTOS: {
        RECEITA: 'R',
        TERMO: 'T',
        LAUDO: 'L',
        FORMULARIO: 'F'
    }
}
