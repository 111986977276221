import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import Form from './form';
import { DESCRICAO_FOTOTIPO, TIPO_DERMATOSE } from '../../common/Constants';
import {
    SttExpansionPanel,
    SttTextItem,
    SttHeading,
    SttTable,
    SttTableHead,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttGrid,
    SttTranslate
} from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    titulo: {
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'column'
    },
    conteinerLesao: {
        marginBottom: theme.spacing(1)
    },
    textItemWrapper: {
        padding: 0
    }
}));

const Laudo = (props) => {
    const { strings, dados, callbackProximoExame } = props;
    const classes = useStyles();

    return (
        <SttGrid container spacing={3}>
            <SttGrid item xs={12}>
                <SttHeading variant="h3" color="primary" align="center" className={classes.titulo}>
                    <span>{`${dados.exame.descricao_exame} - ${dados.exame.data_exame}`}</span>
                    <span>{`${dados.exame.nome_instituicao} - ${dados.exame.nome_cidade}/${dados.exame.sigla_uf}`}</span>
                </SttHeading>

                <SttExpansionPanel
                    title={strings.paciente}
                    compact
                    children={
                        <div>
                            <SttTextItem key="1" title={strings.nome} content={dados.exame.nome_paciente} wrapperClass={classes.textItemWrapper} />
                            <SttTextItem key="2" title={strings.dataNascimento} content={dados.exame.data_nascimento_paciente_formatada} wrapperClass={classes.textItemWrapper} />
                            <SttTextItem key="3" title={strings.sexo} content={dados.exame.sexo_paciente} wrapperClass={classes.textItemWrapper} />
                            <SttTextItem key="4" title={strings.peso} content={`${dados.indicacao.peso} kg`} wrapperClass={classes.textItemWrapper} />
                            <SttTextItem key="5" title={strings.altura} content={`${dados.indicacao.altura} cm`} wrapperClass={classes.textItemWrapper} />
                            <SttTextItem key="6" title={strings.profissao} content={dados.encaminhamento.profissao_paciente} wrapperClass={classes.textItemWrapper} />
                        </div>
                    }
                />

                {
                    dados.exame.observacao_tecnica && 
                    <SttExpansionPanel
                        title={strings.observacaoTecnica}
                        compact
                        children={
                            <div>
                                <SttTextItem key="1" title={strings.observacao} content={dados.exame.observacao_tecnica} wrapperClass={classes.textItemWrapper} />
                            </div>
                        }
                    />
                }

                <SttExpansionPanel
                    title={strings.infoClinicas}
                    compact
                    children={
                        <div>
                            <SttTextItem key="1" title={strings.tabagismo} content={dados.encaminhamento.tabagismo} wrapperClass={classes.textItemWrapper} />
                            { dados.encaminhamento.cigarros_dia && <SttTextItem key="2" title={strings.cigarrosDia} content={dados.encaminhamento.cigarros_dia} wrapperClass={classes.textItemWrapper} /> }
                            { dados.encaminhamento.anos_tabagismo && <SttTextItem key="3" title={strings.anosFuma} content={dados.encaminhamento.anos_tabagismo} wrapperClass={classes.textItemWrapper} /> }
                            <SttTextItem key="4" title={strings.etilismo} content={dados.encaminhamento.etilismo} wrapperClass={classes.textItemWrapper} />
                            <SttTextItem key="5" title={strings.fototipo} content={DESCRICAO_FOTOTIPO[dados.encaminhamento.fototipo]} wrapperClass={classes.textItemWrapper} />
                            { 
                                dados.encaminhamento.comorbidades.length > 0 && 
                                <SttTextItem key="6" title={strings.comorbidades} content={dados.encaminhamento.comorbidades.join(', ')} wrapperClass={classes.textItemWrapper} /> 
                            }
                            { 
                                dados.encaminhamento.infeccoes.length > 0 && 
                                <SttTextItem key="7" title={strings.infeccoes} content={dados.encaminhamento.infeccoes.join(', ')} wrapperClass={classes.textItemWrapper} /> 
                            }
                            { dados.encaminhamento.resultado_biopsia && <SttTextItem key="8" title={strings.resultadoBiopsia} content={dados.encaminhamento.resultado_biopsia} wrapperClass={classes.textItemWrapper} /> }
                            { dados.indicacao.observacao && <SttTextItem key="9" title={strings.observacoes} content={dados.indicacao.observacao} wrapperClass={classes.textItemWrapper} /> }
                        </div>
                    }
                />
                
                {
                    dados.cid10.length > 0 && 
                    <SttExpansionPanel
                        title={strings.cid10}
                        compact
                        children={
                            <SttTable>  
                                <SttTableHead>
                                    <SttTableRow>
                                        <SttTableCell width="40%">
                                            {strings.categoria}
                                        </SttTableCell>
                                        <SttTableCell width="60%">
                                            {strings.subcategoria}
                                        </SttTableCell>
                                    </SttTableRow>
                                </SttTableHead>
                                <SttTableBody>
                                    {  
                                        dados.cid10.map((row, index) => (
                                            <SttTableRow key={index}>
                                                <SttTableCell>{row.descricao_categoria}</SttTableCell>
                                                <SttTableCell>{row.descricao_subcategoria}</SttTableCell>
                                            </SttTableRow>
                                        ))
                                    }
                                </SttTableBody>
                            </SttTable>
                        }
                    />
                }

                {
                    dados.lesoes[0].tipo ===  TIPO_DERMATOSE.CANCER_PELE &&
                    <SttExpansionPanel
                        title={strings.C}
                        compact
                        children={
                            <div>
                                <SttTextItem key="1" title={strings.motivoEncaminhamento} content={dados.cancerPele.motivo} wrapperClass={classes.textItemWrapper} />
                                <SttTextItem key="2" title={strings.hmpc} content={dados.cancerPele.hmp_pele ? strings.sim : strings.nao} wrapperClass={classes.textItemWrapper} />
                                <SttTextItem key="3" title={strings.hmpo} content={dados.cancerPele.hmp_outros ? strings.sim : strings.nao} wrapperClass={classes.textItemWrapper} />
                            </div>
                        }
                    />
                }

                <SttExpansionPanel
                    title={strings.lesoes}
                    compact
                    children={
                        dados.lesoes.map((lesao, index) => (
                            <div key={index} className={classes.conteinerLesao}>
                                <SttHeading variant="h4">{`${strings.lesao} ${index+1} - ${lesao.descricao_local_lesao}`}</SttHeading>
                                <SttTextItem key={`1_${index}`} title={strings.tamanhoCm} content={lesao.descricao_tamanho} wrapperClass={classes.textItemWrapper} />
                                <SttTextItem key={`2_${index}`} title={strings.tempoDoenca} content={`${lesao.tempo_doenca} ${lesao.descricao_tempo_doenca}`} wrapperClass={classes.textItemWrapper} />
                                <SttTextItem key={`3_${index}`} title={strings.prurido} content={lesao.prurido ? strings.sim : strings.nao} wrapperClass={classes.textItemWrapper} />
                                <SttTextItem key={`4_${index}`} title={strings.hmf} content={lesao.hmf ? strings.sim : strings.nao} wrapperClass={classes.textItemWrapper} />
                                <SttTextItem key={`5_${index}`} title={strings.hmp} content={lesao.hmp ? strings.sim : strings.nao} wrapperClass={classes.textItemWrapper} />
                                {
                                    lesao.tipo === TIPO_DERMATOSE.OUTRAS_DERMATOSES &&
                                    <SttTextItem key={`6_${index}`} title={strings.sensibilidadeHanseniase} content={lesao.sensibilidade_hanseniase} wrapperClass={classes.textItemWrapper} />
                                }
                            </div>
                        ))
                    }
                />

                <Form 
                    idExame={dados.exame.id_exame} 
                    idLaudoEmissao={dados.idLaudoEmissao}
                    idPaciente={dados.exame.id_paciente}
                    idSolicitante={dados.exame.id_medico_solicitante}
                    nomeSolicitante={dados.exame.nome_medico_solicitante}
                    listaLesoes={dados.lesoes} 
                    tipoDermatose={dados.lesoes[0].tipo}
                    callbackProximoExame={callbackProximoExame}
                />
            </SttGrid>
        </SttGrid>
    )

}

Laudo.propTypes = {
    strings: PropTypes.object.isRequired,
    dados: PropTypes.object.isRequired,
    callbackProximoExame: PropTypes.func
};

export default SttTranslate('Laudo')(Laudo);