import { combineReducers } from 'redux';

import TYPES from './types';
import envioImagens from './envioImagens';
import exame from './exame';
import laudo from './laudo';

const INITIAL_STATE = {
    isAuthenticated: false,
    message: '',
    cpfSolicitante: null,
    cpfPaciente: null,
    idSolicitacaoSistemaOrigem: null,
    idSistemaOrigem: null,
    user: {
        nome: '',
        email: '',
        foto: ''
    },
    exameMaisAntigoSemLaudo: null
};

const index = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case TYPES.SET_AUTHENTICATED:
            return {
                ...state,
                isAuthenticated: payload.authenticated
            }
        case TYPES.SET_USER:
            return {
                ...state,
                user: payload.user
            }
        case TYPES.LOGOUT:
            return INITIAL_STATE;

        case TYPES.SET_MESSAGE:
            return {
                ...state,
                message: payload.message
            }

        case TYPES.SET_CPF_SOLICITANTE:
            return {
                ...state,
                cpfSolicitante: ('00000000000' + payload.cpf).slice(-11)
            }

        case TYPES.SET_CPF_PACIENTE:
            return {
                ...state,
                cpfPaciente: ('00000000000' + payload.cpf).slice(-11)
            }

        case TYPES.SET_ID_SOLICITACAO_ORIGEM:
            return {
                ...state,
                idSolicitacaoSistemaOrigem: payload.id
            }

        case TYPES.SET_ID_SISTEMA_ORIGEM:
            return {
                ...state,
                idSistemaOrigem: payload.id
            }

        case TYPES.SET_EXAMES_SEM_LAUDO:

            return {
                ...state,
                examesSemLaudo: payload.dados
            }
            
        default:
            return state;
    }
}

export const Reducers = combineReducers({
    index,
    // solicitacao,
    envioImagens,
    exame,
    laudo
});
