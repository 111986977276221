import React from 'react';
import PropTypes from 'prop-types';

import { FastField } from 'formik';
import { HMP_CANCER_PELE, HMP_CANCER_OUTRO } from './fieldNames';

import { SttRadioGroup, SttHeading, SttFormControl, SttFormHelperText, SttFormControlLabel, SttRadioButton } from '@stt-componentes/core';

const ProtocoloEncaminhamento = (props) => {
    const { strings } = props;

    return (
        <div>
            <br />
            <SttHeading required variant="h4" color="primary">{strings.hmp}</SttHeading>
            <br />
            <FastField name={HMP_CANCER_PELE}>
                {({
                    field,
                    meta,
                }) => (
                    <div>
                        <SttHeading required color="primary">{strings.cancerPele}</SttHeading>
                        <SttFormControl required error={meta.touched && meta.error ? true : false} variant="outlined">
                            <SttRadioGroup row>
                                <SttFormControlLabel 
                                    {...field} 
                                    control={
                                        <SttRadioButton  
                                            type="radio"
                                            value={true}
                                            color="primary"
                                            checked={field.value === 'true'}    
                                        />
                                    }
                                    label={strings.sim}
                                />
                                <SttFormControlLabel 
                                    {...field} 
                                    control={
                                        <SttRadioButton  
                                            type="radio"
                                            value={false}
                                            color="primary"
                                            checked={field.value === 'false'}    
                                        />
                                    }
                                    label={strings.nao}
                                />
                            </SttRadioGroup>
                            {
                                meta.touched && meta.error && 
                                <SttFormHelperText error>
                                    {meta.error}
                                </SttFormHelperText>
                            }
                        </SttFormControl>
                    </div>
                )}
            </FastField>
            <FastField name={HMP_CANCER_OUTRO}>
                {({
                    field,
                    meta,
                }) => (
                    <div>
                        <SttHeading required color="primary">{strings.outrosCancer}</SttHeading>
                        <SttFormControl required error={meta.touched && meta.error ? true : false} variant="outlined">
                            <SttRadioGroup row>
                                <SttFormControlLabel 
                                    {...field} 
                                    control={
                                        <SttRadioButton  
                                            type="radio"
                                            value={true}
                                            color="primary"
                                            checked={field.value === 'true'}    
                                        />
                                    }
                                    label={strings.sim}
                                />
                                <SttFormControlLabel 
                                    {...field} 
                                    control={
                                        <SttRadioButton  
                                            type="radio"
                                            value={false}
                                            color="primary"
                                            checked={field.value === 'false'}    
                                        />
                                    }
                                    label={strings.nao}
                                />
                            </SttRadioGroup>
                            {
                                meta.touched && meta.error && 
                                <SttFormHelperText error>
                                    {meta.error}
                                </SttFormHelperText>
                            }
                        </SttFormControl>
                    </div>
                )}
            </FastField>
        </div>
    )
}

ProtocoloEncaminhamento.propTypes = {
    strings: PropTypes.object.isRequired
};

export default ProtocoloEncaminhamento;






