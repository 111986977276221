import * as yup from 'yup';
import { 
    DESCRICAO_ESTUDO,
    LESAO_ELEMENTAR,
    COLORACAO,
    MORFOLOGIA,
    DISTRIBUICAO,
    TAMANHO,
    LOCALIZACAO,
    LESAO_COMPATIVEL_COM,
    CLASSIFICACAO_RISCO,
    GRUPO_DERMATOSE,
    DERMATOSE,
    LESAO_
} from './fieldNames';

export default (strings, lesoes) => {
    let schema =  yup.object().shape({
        [DESCRICAO_ESTUDO]: yup.string().required(strings.descricaoEstudoObrigatorio)
    });

    for (const lesao of lesoes) {
        schema = schema.concat(
            yup.object().shape({
                [`${LESAO_}${lesao.numero}`]: yup.object().shape({
                    [LESAO_ELEMENTAR]: yup.array().of(
                        yup.string()
                    )
                    .min(1, strings.lesaoElementarObrigatoria)
                    .required(strings.lesaoElementarObrigatoria),
                    [COLORACAO]: yup.array().of(
                        yup.string()
                    )
                    .min(1, strings.coloracaoObrigatoria)
                    .required(strings.coloracaoObrigatoria),
                    [MORFOLOGIA]: yup.array().of(
                        yup.string()
                    )
                    .min(1, strings.morfologiaObrigatoria)
                    .required(strings.morfologiaObrigatoria),
                    [DISTRIBUICAO]: yup.array().of(
                        yup.string()
                    )
                    .min(1, strings.distribuicaoObrigatoria)
                    .required(strings.distribuicaoObrigatoria),
                    [TAMANHO]: yup.object().shape({
                        id: yup.number(),
                        descricao: yup.string()
                    })
                    .nullable()
                    .required(strings.tamanhoObrigatorio),
                    [LOCALIZACAO]: yup.object().shape({
                        id: yup.number(),
                        descricao: yup.string()
                    })
                    .nullable()
                    .required(strings.localizacaoObrigatoria),
                    [LESAO_COMPATIVEL_COM]: yup.object().shape({
                        id: yup.string(),
                        descricao: yup.string(),
                        ordem: yup.number()
                    })
                    .nullable()
                    .required(strings.lesaoCompativelComObrigatoria),
                    [CLASSIFICACAO_RISCO]: yup.string().nullable().required(strings.classificacaoRiscoObrigatoria),
                    [GRUPO_DERMATOSE]: yup.number().nullable().required(strings.grupoDermatoseObrigatorio),
                    [DERMATOSE]: yup.number().nullable().required(strings.dermatoseObrigatoria),
                })
            })
        );
    }

    return schema;
}