import React from 'react';
import PropTypes from 'prop-types';
import { getHeaders } from '../../request';
import SttGaleriaImagens from '@stt-componentes/galeria-telediagnostico';
import { SttTranslate } from '@stt-componentes/core';

const Imagens = (props) => {
    const { strings, idExame } = props;

    return (
        <SttGaleriaImagens
            idExame={idExame}
            strings={strings}
            config={global.gConfig}
            tokenTelessaude={getHeaders()}
        />
    )

}

Imagens.propTypes = {
    strings: PropTypes.object.isRequired,
    idExame: PropTypes.number.isRequired,
    imagens: PropTypes.array.isRequired
};

export default SttTranslate('Laudo')(Imagens);