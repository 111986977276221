import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { temPerfilRBAC, temPermissaoRBAC } from '../../secutity/acl';
import { PERFIL, PERMISSOES } from '../../common/Constants';
import useStyles from './style';
import { 
    SttContainer, 
    SttCircularProgress, 
    SttGrid, 
    SttTranslate
} from '@stt-componentes/core';


const Produtividade = (props) => {
    const { user, strings } = props;
    const classes = useStyles();

    const LaudosEmitidos = lazy(() => import('./laudos-emitidos'));
    const SemLaudo72h = lazy(() => import('./sem-laudo-72h'));
    const SemLaudo = lazy(() => import('./sem-laudo'));
    const ExameMaisAntigoSemLaudo = lazy(() => import('./exame-mais-antigo-sem-laudo'));
    const ExamesComLaudo = lazy(() => import('./exames-com-laudo'));
    const ExamesInvalidos = lazy(() => import('./exames-invalidos'));
    
    
    return (
        <SttContainer className={classes.container}>
            <SttGrid container spacing={3}>                    
                {/* Coluna esquerda */} 
                {
                    (temPermissaoRBAC(user, PERMISSOES.LAUDAR_EXAME) || temPerfilRBAC(user, PERFIL.ADMINISTRADOR) || temPerfilRBAC(user, PERFIL.ADMINISTRADOR_ESTADUAL) || temPerfilRBAC(user, PERFIL.ADMINISTRADOR)) && 
                    <SttGrid item xs={12} md={6}>
                        <Suspense className={classes.card} variant="outlined" fallback={<SttCircularProgress color="primary" />}>
                            <LaudosEmitidos strings={strings}/>
                        </Suspense>
                    </SttGrid>
                }
                {
                    !temPerfilRBAC(user, PERFIL.ADMINISTRADOR) && !temPerfilRBAC(user, PERFIL.ADMINISTRADOR_ESTADUAL) && !temPermissaoRBAC(user, PERMISSOES.LAUDAR_EXAME) && 
                    <SttGrid item xs={12} md={6}>
                        <Suspense className={classes.card} variant="outlined" fallback={<SttCircularProgress color="primary" />}>
                            <ExamesComLaudo strings={strings}/>
                        </Suspense>
                    </SttGrid>
                }

                {/* Coluna direita */}
                {
                    !temPerfilRBAC(user, PERFIL.ADMINISTRADOR) && !temPerfilRBAC(user, PERFIL.ADMINISTRADOR_ESTADUAL) && !temPermissaoRBAC(user, PERMISSOES.LAUDAR_EXAME) && 
                    <SttGrid item xs={12} md={6}>
                        <Suspense className={classes.card} variant="outlined" fallback={<SttCircularProgress color="primary" />}>
                            <ExamesInvalidos strings={strings}/>
                        </Suspense>
                    </SttGrid>
                }
                {
                    (temPermissaoRBAC(user, PERMISSOES.LAUDAR_EXAME) || temPerfilRBAC(user, PERFIL.ADMINISTRADOR) || temPerfilRBAC(user, PERFIL.ADMINISTRADOR_ESTADUAL) || temPerfilRBAC(user, PERFIL.ADMINISTRADOR)) && 
                    <SttGrid item xs={12} md={6}>                                
                        <Suspense className={classes.card} variant="outlined" fallback={<SttCircularProgress color="primary" />}>
                            <SemLaudo72h strings={strings}/>
                        </Suspense>
                        <Suspense className={classes.card} variant="outlined" fallback={<SttCircularProgress color="primary" />}>
                            <SemLaudo strings={strings}/>
                        </Suspense>
                        <Suspense className={classes.card} variant="outlined" fallback={<SttCircularProgress color="primary" />}>
                            <ExameMaisAntigoSemLaudo strings={strings}/>
                        </Suspense>
                    </SttGrid>
                }
            </SttGrid>
        </SttContainer>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps, null)(SttTranslate('Pendencias')(Produtividade));