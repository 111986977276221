import React from 'react';
import { EQUIPAMENTO } from './fieldNames';
import { SttAutocomplete } from '@stt-componentes/core';

const Equipamento = (props) => {
    const { strings, form, field, equipamentos, meta } = props;
    const { name, value, onBlur } = field;
    const { setFieldValue } = form;

    return (
        <SttAutocomplete 
            inputprops={{
                name: name,
                label: strings.equipamento,
                required: true,
                error: meta.touched && meta.error ? meta.error : undefined
            }}
            {...field}
            getOptionLabel={option => option && option.id_humano || ''}
            options={equipamentos}
            value={value}
            onBlur={onBlur}
            onChange={(e, item) => setFieldValue(EQUIPAMENTO, item || null)}
        />
    )
}

export default Equipamento;
